import { Cascader, Form, Skeleton, Space, Typography } from "antd";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { styled } from "styled-components";

const StyledThemeDropDown = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Title: styled(Typography.Title)`
    &.ant-typography {
      color: ${(props) => props.theme.token.colorTextLabel};
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      margin-bottom: 0.5rem;
      line-height: 1.125rem;
      text-align: left;
      & .styled-drop-down-star {
        color: ${(props) => props.theme.token.red4};
        font-size: 1rem;
      }
    }
  `,
  Cascader: styled(Cascader)`
    width: ${(props) => props.width};
    /* max-height: 250px !important; */

    & .ant-select-selector {
      height: 3rem !important;
      border-radius: ${(props) => props.theme.token.borderRadiusLG + "px"};
      @media (max-width: 1079px) {
        height: 2.5rem !important;
      }
    }
    & .ant-select-arrow {
      font-size: 0.9375rem;
    }
    & .ant-cascader-menu {
      width: 165px;
      max-height: 480px;
    }
    & .ant-select-selection-placeholder,
    .ant-select-selection-item {
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.3125rem; /* 150% */
      display: flex;
      align-items: center;
    }
  `,
  FromWrap: styled.div`
    & .ant-form-item-explain-error {
      width: ${(props) => props?.width};
      font-size: 0.8rem;
      font-style: normal;
      font-weight: 400;
      margin-top: 2px;
      @media (min-width: 1600px) {
        margin-top: 7px;
      }
    }
    &.ant-form-item {
      margin-bottom: ${(props) =>
        props?.$marginBottom ? props?.$marginBottom : "1.5rem"};
    }
  `,
};

const ThemeDropDown = forwardRef((props, _ref) => {
  const [value, setValue] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const cascaderRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting && dropdownVisible) {
          setDropdownVisible(false);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0,
      }
    );

    if (cascaderRef.current) {
      observer.observe(cascaderRef.current);
    }

    return () => {
      if (cascaderRef.current) {
        observer.unobserve(cascaderRef.current);
      }
    };
  }, [dropdownVisible]);

  //functions
  const handleMenuClick = (_value, selectedOptions) => {
    if (props?.$onChange) {
      props?.$onChange(props?.name, _value || null, selectedOptions, props);
    } else {
      setValue(props?.name, _value, selectedOptions);
    }
  };

  useImperativeHandle(
    _ref,
    () => ({
      value: props?.$value || value,
    }),
    [value, props?.$value]
  );

  // const dropdownRender = (menus) => (
  //   <span style={{ maxHeight: "1rem !important" }}>{menus}</span>
  // );

  const dropdownRender = (menus) => (
    <div>
      {props.loadings == "true" && dropdownVisible ? (
        <Skeleton active />
      ) : (
        <span style={{ maxHeight: "1rem !important" }}>{menus}</span>
      )}
    </div>
  );

  return (
    <StyledThemeDropDown.Wrapper ref={_ref}>
      {props?.title || props?.subTitle ? (
        <Space
          style={{
            justifyContent: "space-between",
          }}
        >
          <StyledThemeDropDown.Title>
            {props?.title}
            {props?.required ? (
              <span className="styled-drop-down-star">*</span>
            ) : null}
          </StyledThemeDropDown.Title>
        </Space>
      ) : null}
      <StyledThemeDropDown.FromWrap ref={cascaderRef}>
        <Form.Item {...props?.form_props}>
          <StyledThemeDropDown.Cascader
            name={props?.name}
            value={
              props?.$value === 0 ? "0" : props?.$value ? props?.$value : value
            }
            onChange={handleMenuClick}
            $width={props?.width}
            popupClassName="theme-drop-down-root"
            dropdownRender={props?.dropdownRender || dropdownRender}
            dropdownMenuColumnStyle={{
              maxHeight: "250px",
              overflow: "auto",
            }}
            open={dropdownVisible}
            showSearch={props.showSearch}
            onDropdownVisibleChange={(visible) => setDropdownVisible(visible)}
            rules={props?.rules}
            {...props}
          />
        </Form.Item>
      </StyledThemeDropDown.FromWrap>
      {/* {isCascaderEmpty && (
        <div style={{ color: "red", fontSize: "1rem" }}>
          Please select a value.
        </div>
      )} */}
    </StyledThemeDropDown.Wrapper>
  );
});

export default ThemeDropDown;
