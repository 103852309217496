import { Col } from "antd";
import styled from "styled-components";
import dayjs from "dayjs";
import { valanBillDate } from "../../components/CommanFuction/CommanFunction";
import { TextLeftDiv } from "../../styles/commonStyle";

export const buttonTitles = [
  { label: "ALL", value: "all", key: "title" },
  { label: "MARKET", value: "market", key: "title" },
  { label: "LIMIT", value: "limit", key: "title" },
  { label: "INTRADAY", value: "intraday", key: "title" },
  { label: "INTRADAY LIMIT", value: "intradaylimit", key: "title" },
  { label: "PENDING", value: "pending", key: "tradeStatus" },
  // { label: "CANCEL", value: "cancel", key: "tradeStatus" },
];
export const ActiveDot = styled(Col)`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  background-color: ${(props) =>
    props?.status === "online" ? "green" : "red"};
`;
export const ActionColumn = styled.div`
  display: flex;
  justify-content: space-around;
`;
export const TradeColumns = [
  {
    title: "DEVICE",
    dataIndex: "deviceType",
    key: "D",
    // width: "7rem",
    align: "center",
  },
  {
    title: "TRADE BY",
    dataIndex: "tradeBy",
    // width: "5rem",
    key: "tradeBy",
    align: "center",
    render: (text, record) => {
      return (
        <span>
          -{" "}
          {/* {`${record?.customer[0]?.userId} (${record?.customer[0]?.accountName})`} */}
        </span>
      );
    },
  },
  {
    title: "CLIENT INFO",
    dataIndex: "customer",
    key: "customer",
    align: "center",
    // width: "100px",
    render: (text, record) => {
      return `${record?.customer[0]?.userId} (${record?.customer[0]?.accountName})`;
    },
  },
  {
    title: "MARKET",
    dataIndex: "marketName",
    key: "market",
    // width: "7rem",
    align: "center",
  },
  {
    title: "SCRIPT",
    dataIndex: "symbolName",
    key: "script",
    align: "center",
    // width: "9rem",
    render: (text, record) => {
      const date = valanBillDate(record?.expiryDate, false);
      return (
        <TextLeftDiv>
          {`${record?.symbolName}`} {date}
        </TextLeftDiv>
      );
    },
  },
  {
    title: "TYPE",
    dataIndex: "title",
    key: "type",
    align: "center",
    width: "9rem",
  },
  {
    title: "B/S",
    dataIndex: "tradePattern",
    key: "buy/sell",
    // width: "6rem",
    align: "center",
  },
  {
    title: "QTY (LOT)",
    dataIndex: "lot",
    key: "lot",
    align: "center",
    // width: "7rem",
    render: (text, record) => {
      const lot = parseFloat(record?.lot);
      const intValue = isNaN(lot) ? "" : Math.floor(lot * 100) / 100;
      const quantity = parseFloat(record?.quantity);
      const intValuequantity = isNaN(quantity) ? "" : parseInt(quantity, 10);
      const data =
        record?.marketName === "NSE EQU"
          ? intValuequantity
          : `${intValuequantity} (${intValue})`;
      return <TextLeftDiv>{data}</TextLeftDiv>;
    },
  },
  {
    title: "ORDER RATE",
    dataIndex: "tradePrice",
    key: "orderPrice",
    align: "center",
    // width: "100px",
    render: (text, record) => {
      return <div>{parseFloat(record?.tradePrice).toFixed(2)}</div>;
    },
  },
  {
    title: "NET RATE",
    dataIndex: "tradePrice",
    key: "netPrice",
    // width: "100px",
    align: "center",
    render: (text, record) => {
      return <div>{parseFloat(record?.tradePrice).toFixed(2)}</div>;
    },
  },
  {
    title: "STATUS",
    dataIndex: "tradeStatus",
    key: "tradeStatus",
    // width: "8rem",
    align: "center",
  },
  {
    title: "ORDER-TIME",
    dataIndex: "oTime",
    key: "oTime",
    // width: "5rem",
    align: "center",
  },
  {
    title: "TRADE-TIME",
    dataIndex: "tradeTime",
    key: "tradeTime",
    // width: "100px",
    align: "center",
    render: (text, record) => {
      return valanBillDate(record?.tradeTime, true);
    },
  },
  {
    title: "IP",
    dataIndex: "tradeIpAddress",
    key: "ip",
    // width: "120px",
    align: "center",
    render: (text, record) => {
      return <TextLeftDiv>{record?.tradeIpAddress}</TextLeftDiv>;
    },
  },
  {
    title: "MODIFY",
    dataIndex: "oTime",
    key: "oTime",
    // width: "5rem",
    align: "center",
  },
];
export const CSVColumns = [
  {
    title: "SR NO.",
    dataIndex: "srNo",
    key: "srNo",
    align: "right",
    width: "4rem",
    render: (text, record, index) => index + 1,
  },
  {
    title: "User Id",
    dataIndex: "userId",
    key: "userId",
    align: "center",
    render: (text, record) => {
      return `${record?.customer?.[0]?.userId} `;
    },
  },
  {
    title: "User Name",
    dataIndex: "accountName",
    key: "accountName",
    align: "center",
    render: (text, record) => {
      return `${record?.customer?.[0]?.accountName}`;
    },
  },
  {
    title: "Market",
    dataIndex: "marketName",
    key: "marketName",
    align: "center",
    render: (text, record) => {
      return `${record?.marketName}`;
    },
  },
  {
    title: "Symbol",
    dataIndex: "symbolName",
    key: "symbolName",
    align: "center",
    render: (text, record) => {
      return `${record?.symbolName}`;
    },
  },
  {
    title: "Exp. Date",
    dataIndex: "expiryDate",
    key: "expiryDate",
    align: "right",
    width: "8rem",
    render: (text, record) => {
      return `${record?.expiryDate}`;
    },
  },
  {
    title: "Strike P.",
    dataIndex: "strikePrice",
    key: "strikePrice",
    align: "center",
    render: (text, record) => {
      return `${record?.strikePrice ? record?.strikePrice : ""}`;
    },
  },
  {
    title: "Opt. TYP.",
    dataIndex: "series",
    key: "series",
    align: "center",
    render: (text, record) => {
      return `${record?.series ? record?.series : ""}`;
    },
  },
  {
    title: "B/S.",
    dataIndex: "tradePattern",
    key: "tradePattern",
    align: "center",
    render: (text, record) => {
      return `${record?.tradePattern === "buy" ? "B" : "S"}`;
    },
  },
  {
    title: "QTY.",
    dataIndex: "quantity",
    key: "quantity",
    align: "right",
    render: (text, record) => {
      return `${record?.quantity}`;
    },
  },
  {
    title: "LOT",
    dataIndex: "lot",
    key: "lot",
    align: "right",
    render: (text, record) => {
      return `${record?.lot}`;
    },
  },
  {
    title: "Price",
    dataIndex: "tradePrice",
    key: "tradePrice",
    align: "right",
    render: (text, record) => {
      return `${record?.tradePrice}`;
    },
  },
  {
    title: "Trade Date",
    dataIndex: "tradeTime",
    key: "tradeTime",
    align: "right",
    width: "300px",
    render: (text, record) => {
      const date = dayjs(record.tradeTime);
      return date.isValid() ? date.format("YYYY-MM-DD") : "";
    },
  },
];
