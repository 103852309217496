import { theme } from "antd";
import { useEffect, useRef, useState } from "react";

const useColorChange = ({
  currentValue,
  defaultBgColor = "transparent",
  defaultColor = "inherit",
}) => {
  const prevValue = useRef(null);
  const token = theme.useToken().token;

  const [backgroundColor, setBackgroundColor] = useState(defaultBgColor);

  useEffect(() => {
    if (
      currentValue &&
      prevValue.current !== null &&
      prevValue.current !== currentValue
    ) {
      if (currentValue > prevValue.current) {
        setBackgroundColor(token.marketWatchUp);
      } else if (currentValue < prevValue.current) {
        setBackgroundColor(token.marketWatchDown);
      }
      setTimeout(() => {
        setBackgroundColor(defaultBgColor);
      }, 500);
    }
    prevValue.current = currentValue;
  }, [
    currentValue,
    defaultBgColor,
    token.marketWatchDown,
    token.marketWatchUp,
  ]);

  return {
    color:
      backgroundColor === defaultBgColor
        ? defaultColor
        : token.colorTextLightSolid,
    backgroundColor,
  };
};

export default useColorChange;
