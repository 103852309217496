import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import { Col, Modal, Row, notification } from "antd";
import ThemeCard from "../ThemeCard";
import ThemeInput from "../ThemeInput";
import ThemeInputNumber from "../ThemeInputNumber/ThemeInputNumber";
import ActionButton from "../ActionButton";
import useGetQueryParams from "../../hooks/useGetQueryParams";
import { isValidTrade } from "../../pages/MarketWatch/TradeModal";
import store from "../../store/store";
import { valanBillDate } from "../CommanFuction/CommanFunction";

const istrue = ["COMEX", "FOREX", "GLOBEX"];
const EditTrade = ({ handleCancel, record }) => {
  const queryCliant = useQueryClient();

  // state
  const [data, setData] = useState({
    lot: record?.lot,
    quantity: record?.quantity,
    tradePrice: record?.tradePrice,
  });
  const [loading, setLoading] = useState(false);
  const { params } = useGetQueryParams();

  // api call
  const handleSubmit = async () => {
    const ltp =
      store.getState()?.webSocketSlice?.webSocket?.data?.[record?.symbol]?.[
        "LTP"
      ] || 0;
    const [allValid, name] = isValidTrade(data, record);
    if (!allValid) {
      return notification.error({
        message: `Please enter positive numbers greater than 0 for ${name}`,
      });
    }

    let marketCheck =
      record?.marketName === "NSE EQU" || record?.marketName === "NSE FUT";
    if (marketCheck) {
      let modifiedPrice = data?.tradePrice?.toString()?.split(".")?.[1];
      const isPriceAllow =
        modifiedPrice?.[1] == 0 ||
        modifiedPrice?.[1] == 5 ||
        modifiedPrice?.[1] == undefined;
      if (data?.tradePrice > 250 && !isPriceAllow) {
        return notification.error({ message: "Price is not in Format" });
      }
    }

    let id = record?._id;
    let obj = {
      // id: record?.customerId?._id,
      lot: Number(data.lot) || 0,
      quantity: data.quantity,
      tradePrice: Number(data.tradePrice),
      stoploss: parseFloat(ltp),
    };

    setLoading(true);
    try {
      let response = await APICall("patch", `${EndPoints.editTrade}${id}`, obj);
      // console.log("response", response);
      if (response.status === 200) {
        notification.success({ message: response?.data?.message });
        if (record?.key === "tradingTrade") {
          queryCliant.refetchQueries([record?.key, params]);
        } else {
          queryCliant.refetchQueries([record?.key]);
        }
      } else {
        notification.error({ message: response?.data?.message });
      }

      handleCancel();
    } catch (error) {
      notification.error({ message: error?.message });
    } finally {
      setLoading(false);
    }
  };

  // function
  const onChangeValue = ({ target: { name, value } }) => {
    if (name === "tradePrice") {
      const isMCXOver1500 =
        record?.marketName === "MCX" && parseFloat(value) > 1500;
      const regex = /^\d*\.?\d{0,2}$/;
      const valid = istrue.includes(record?.marketName);
      if ((regex.test(value) || value === "") && !valid) {
        const decimal = value.split(".")[1]?.toString();
        if (isMCXOver1500) {
          setData({ ...data, [name]: parseFloat(value) });
        } else {
          if (decimal?.length == 2) {
            const secondDecimalDigit = decimal[1];
            if (
              secondDecimalDigit === "0" ||
              secondDecimalDigit === "5" ||
              record?.marketName === "NSE EQU" ||
              record?.marketName === "NSE FUT"
            ) {
              setData({ ...data, [name]: value });
            }
          } else if (!decimal || decimal?.length === 1) {
            setData({ ...data, [name]: value });
          }
        }
      }
      if (valid) {
        setData({ ...data, [name]: value });
      }
    } else if (name === "quantity") {
      let lotValue = (value || 1) / record?.lotSize;
      setData({ ...data, [name]: value, lot: lotValue });
    } else if (name === "lot") {
      let qtyValue = record?.lotSize * (value || 1);
      setData({ ...data, [name]: value, quantity: qtyValue });
    } else {
      setData({ ...data, [name]: value });
    }
  };

  return (
    <Modal
      title={
        <Row justify={"left"}>
          <Col span={12}>
            {`${record?.symbolName} ${record?.series || ""} ${
              record?.strikePrice || ""
            } ${record?.option_type || ""}  ${
              record?.expiryDate ? valanBillDate(record?.expiryDate) : ""
            }`}
          </Col>

          <Col span={10}>
            {`${record?.customer[0]?.accountName} (${record?.customer[0]?.userId})`}
          </Col>
        </Row>
      }
      open={true}
      onOk={handleCancel}
      onCancel={handleCancel}
      footer={false}
    >
      <ThemeCard bordered={false} style={{ margin: "1rem 0rem" }}>
        <Row justify={"space-around"} gutter={[30, 0]}>
          <Col span={8}>
            <ThemeInput
              marginBottom={"0rem"}
              width={"100%"}
              title="Price"
              className="ThemeInput"
              extraProps={{
                input_type: "number",
              }}
              inputProps={{
                placeholder: "Price",
                name: "tradePrice",
                value: data?.tradePrice,
                onChange: onChangeValue,
                // onBlur: onChangeValue,
                // disabled: record?.marketName !== "NSE FUT" ? true : false,
              }}
            />
          </Col>
          {record?.marketName !== "NSE EQU" && (
            <Col span={8}>
              <ThemeInputNumber
                marginBottom={"0rem"}
                width={"100%"}
                title="LOT"
                extraProps={{
                  input_type: "number",
                }}
                inputProps={{
                  placeholder: "LOT",
                  name: "lot",
                  value: data?.lot,
                  onChange: onChangeValue,
                  // onBlur: onChangeValue,
                  disabled: record?.marketName === "NSE EQU" ? true : false,
                }}
              />
            </Col>
          )}
          <Col span={8}>
            <ThemeInputNumber
              marginBottom={"0rem"}
              width={"100%"}
              title="QTY"
              className="ThemeInput"
              extraProps={{
                input_type: "number",
              }}
              inputProps={{
                placeholder: "QTY",
                name: "quantity",
                value: data?.quantity,
                onChange: onChangeValue,
                // onBlur: onChangeValue,
                // disabled: record?.marketName !== "NSE FUT" ? true : false,
              }}
            />
          </Col>
        </Row>
        <Row justify={"center"} style={{ marginTop: "2rem" }}>
          <ActionButton
            title={"Submit"}
            onClick={handleSubmit}
            loading={loading}
          />

          <ActionButton
            title={"Cancel"}
            buttonType="cancel"
            onClick={handleCancel}
          />
        </Row>
      </ThemeCard>
    </Modal>
  );
};

export default EditTrade;
