import {
  DeleteOutlined,
  EditOutlined,
  RetweetOutlined,
} from "@ant-design/icons";
import { Button, Typography } from "antd";
import styled from "styled-components";

export const onlyTime = (data) => {
  if (!data) {
    return;
  }
  const date = new Date(data);
  const formattedTime = date.toLocaleTimeString("en-IN", {
    timeZone: "Asia/Kolkata",
    hour12: false,
  });
  return `${formattedTime}`;
};
export const styleTheme = {
  AllowButton: styled(Button)`
    border-radius: ${({ theme }) => theme.token.borderRadiusLG};
    font-size: 1rem;
    height: 3rem;
    color: white !important;
    /* width: unset !important; */
    background-color: ${({ theme }) => theme.token.colorPrimary};
    /* margin-right: 20px; */
    @media (max-width: 1079px) {
      height: 2rem;
      font-size: 0.8rem;
    }
  `,
};
export const StyledOverFlowDiv = styled.div`
  text-align: left;
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const ModalText = styled(Typography.Text)`
  color: red;
`;
export const DeleteIcon = styled(DeleteOutlined)`
  font-size: 1.2rem;
  cursor: pointer;
  color: #ff474d;
`;
export const RetweetOutIcon = styled(RetweetOutlined)`
  font-size: 1.2rem;
  cursor: pointer;
  color: green;
`;

export const EditIcon = styled(EditOutlined)`
  font-size: 1.2rem;
  cursor: pointer;
  color: ${(token) => token.theme?.colorPrimary1};
`;
