import React from "react";

let markets = [
  "NSE FUT",
  "FOREX",
  "MCX OPT",
  "NSE EQU",
  "MCX",
  "NSE OPT",
  "COMEX",
  "GLOBEX",
];
const modifyInitialValuesObject = (brokarageValidationDetails) => {
  let resultObj = {};

  function modifyObject(obj, prefix) {
    let newObj = {};
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        newObj[`${prefix}$${key}`] = obj[key];
      }
    }
    return newObj;
  }

  const appendMarketName = (name, obj) => {
    let temp = {};
    for (const key in obj) {
      resultObj[`${key}$${name}`] = obj[key];
    }
    return temp;
  };
  let brokerageArray = [];
  for (let i = 0; i <= markets.length; i++) {
    // if (
    //   brokarageValidationDetails[markets[i]]?.additionalSharing &&
    //   brokarageValidationDetails.accountType === "customer"
    // ) {
    //   let additonal = brokarageValidationDetails[markets[i]]?.additionalSharing;
    //   additonal.forEach((el) => {
    //     let isPresent = brokerageArray?.findIndex(
    //       (e) => e?.brokerId === el?.brokerId
    //     );
    //     if (isPresent !== -1) {
    //       brokerageArray[isPresent] = {
    //         ...brokerageArray[isPresent],
    //         brokerId: el?.brokerId,
    //         [`delivery$${markets[i]}`]: el?.delivery,
    //         [`percentage$${markets[i]}`]: el?.percentage,
    //         [`intraday$${markets[i]}`]: el?.intraday,
    //       };
    //     } else {
    //       brokerageArray.push({
    //         brokerId: el?.brokerId,
    //         [`delivery$${markets[i]}`]: el?.delivery,
    //         [`percentage$${markets[i]}`]: el?.percentage,
    //         [`intraday$${markets[i]}`]: el?.intraday,
    //       });
    //     }
    //   });
    // }
    resultObj[`additionalSharing$${markets[i]}`] =
      brokarageValidationDetails[markets[i]]?.additionalSharing;
    resultObj[`isOnlyPositionSquareup$${markets[i]}`] =
      brokarageValidationDetails[markets[i]]?.isOnlyPositionSquareup;

    appendMarketName(
      markets[i],
      brokarageValidationDetails[markets[i]]?.brokerageDetails
    );
    appendMarketName(
      markets[i],
      brokarageValidationDetails[markets[i]]?.margin
    );
    appendMarketName(
      markets[i],
      brokarageValidationDetails[markets[i]]?.scriptWiseSettingInfo
    );
  }
  resultObj = {
    ...resultObj,
    [`additionalSharing`]: brokerageArray,
    // brokarageValidationDetails[markets[i]]?.additionalSharing,
  };

  let preparedObj = {
    ...resultObj,
    ...brokarageValidationDetails.basicInfo,
    ...brokarageValidationDetails.accountDetails,
    ...modifyObject(
      brokarageValidationDetails.accountDetails.M2MAlert,
      "M2MAlert"
    ),
    ...modifyObject(
      brokarageValidationDetails.accountDetails.applyAutoSquareup,
      "applyAutoSquareup"
    ),
    ...modifyObject(
      brokarageValidationDetails.accountDetails.autoStopNewPosition,
      "autoStopNewPosition"
    ),
    // newPositionSquareupTime:
    //   brokarageValidationDetails.accountDetails.newPositionSquareupTime.time,
    freshLimitAllow: brokarageValidationDetails.accountDetails.freshLimitAllow,
    ...brokarageValidationDetails.accountDetails.additionalInfo,
    // additionalSharing: brokarageValidationDetails["NSE FUT"]?.additionalSharing,
  };

  return {
    ...preparedObj,
  };
};

export default modifyInitialValuesObject;
