import useColorChange from "../../hooks/useColorChange";
import upAero from "../../assets/icons/upAero.svg";
import downAero from "../../assets/icons/downAero.svg";
import { useSocket } from "../Socket";
import { theme } from "antd";
import React, { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import store from "../../store/store";
import { findMarket, formatToINR } from "../FormateToINR/formatToINR";
import { setScriptCall } from "../../store/slice/globleSlice";
import { CurrentPrice } from "../CommanFuction/CommanFunction";
import styled from "styled-components";

const StyledSpan = styled.span`
  color: ${(props) =>
    props?.total === 0 ? "black" : props?.total > 0 ? "green" : "red"};
  font-weight: 500;
`;

const CRORE = 10000000;
const LAKH = 100000;

const FormatQuantity = React.memo(({ total }) => {
  const { formattedValue, unit } = useMemo(() => {
    const absoluteTotal = Math.abs(total);
    let value, unit;

    if (absoluteTotal >= CRORE) {
      value = absoluteTotal / CRORE;
      unit = "Cr";
    } else if (absoluteTotal >= LAKH) {
      value = absoluteTotal / LAKH;
      unit = "Lks";
    } else {
      value = absoluteTotal;
      unit = "";
    }

    const formattedValue = formatToINR(value);
    return { formattedValue, unit };
  }, [total]);

  const sign = total < 0 ? "-" : "";

  return (
    <StyledSpan total={total}>
      {sign}
      {formattedValue}
      {unit}
    </StyledSpan>
  );
});

const PreviousM2M = React.memo(
  ({ total }) => {
    return <FormatQuantity total={total} />;
  },
  (prevProps, nextProps) => prevProps.total === nextProps.total
);

const formatPrice = (value, marketName, priceType) => {
  if (
    (marketName === "FOREX" ||
      marketName === "GLOBEX" ||
      marketName === "COMEX") &&
    priceType !== "Spread"
  ) {
    return limitNumberLength(value);
  }
  return value;
};

export function limitNumberLength(num) {
  return num;
  // Convert the number to a string
  num = parseFloat(num);

  // Check if num is a valid number
  if (isNaN(num)) {
    return 0;
  }
  let numStr = num.toString();

  // Split the number into integer and fractional parts
  let parts = numStr.split(".");
  let integerPart = parseInt(parts[0]);
  let fractionalPart = parts[1] || "";

  // Check if the number before the decimal point is less than 50
  if (integerPart < 50) {
    // If so, make sure the total length of the number is 5 after the decimal point
    if (fractionalPart.length > 5) {
      return parseFloat(integerPart + "." + fractionalPart.slice(0, 5)).toFixed(
        5
      );
    } else {
      return parseFloat(
        integerPart + "." + fractionalPart.padEnd(5, "0")
      ).toFixed(5);
    }
  }

  // For numbers greater than or equal to 50, limit the length to 6 digits
  if (integerPart.toString().length + fractionalPart.length <= 6) {
    return num.toFixed(6 - integerPart.toString().length);
  }

  // Otherwise, truncate the fractional part to fit within the 6-digit limit
  let truncatedFractionalPart = fractionalPart.slice(
    0,
    6 - integerPart.toString().length
  );

  // Combine the integer and truncated fractional parts and return the result
  return parseFloat(integerPart + "." + truncatedFractionalPart).toFixed(
    truncatedFractionalPart.length
  );
}

export function trimZeros(number) {
  return number;
  // Convert number to string to make manipulation easier
  let numStr = number.toString();

  // Find the index of the last non-zero digit or decimal point
  let lastNonZeroIndex = numStr.length - 1;
  while (
    lastNonZeroIndex >= 0 &&
    (numStr[lastNonZeroIndex] === "0" || numStr[lastNonZeroIndex] === ".")
  ) {
    lastNonZeroIndex--;
  }

  // Return the number up to the last non-zero digit or decimal point
  return numStr.substring(0, lastNonZeroIndex + 1);
}

export const M2MCalculation = React.memo(({ item, flag }) => {
  let position = item?.positions;
  let key = CurrentPrice(item);

  const pnl =
    useSelector(
      (state) => state.webSocketSlice?.webSocket?.data?.[item.symbol]?.[key]
    ) || 0;
  const ltp =
    useSelector(
      (state) => state.webSocketSlice?.webSocket?.data?.[item.symbol]?.["LTP"]
    ) || 0;

  const sum = useMemo(() => {
    let total = 0;
    position?.forEach((el) => {
      if (el.type === flag && (ltp || pnl)) {
        const currentPNL = parseFloat(
          -el?.totalBrokerage -
            (el?.totalBuyPrice || 0) +
            (el?.totalSellPrice || 0) +
            (el?.totalQuantity || 0) * (pnl || ltp)
        );
        total += parseFloat(currentPNL || 0);
      }
    });
    return total;
  }, [position, pnl]);

  return <PreviousM2M total={sum} />;
});

export const MemoizedCardItemPrice = ({ item, onClickCell, priceType }) => {
  const buyValue = useSelector(
    (state) =>
      state.webSocketSlice?.webSocket?.data?.[item?.symbol]?.[priceType] ?? 0
  );

  const { color, backgroundColor } = useColorChange({ currentValue: buyValue });

  const formattedValue = useMemo(
    () => formatPrice(buyValue, item?.marketName, priceType),
    [buyValue, item?.marketName, priceType]
  );

  const style = useMemo(
    () => ({
      backgroundColor,
      color,
      borderRadius: "5px",
      padding: "0.5rem 0rem",
      cursor:
        priceType === "Bid" || priceType === "Ask" ? "pointer" : "default",
    }),
    [backgroundColor, color, priceType]
  );

  return (
    <div style={style} onClick={onClickCell}>
      {formattedValue}
    </div>
  );
};

export const MemoizedCardCurrentPrice = ({ item, priceType }) => {
  let price =
    useSelector(
      (state) =>
        state.webSocketSlice?.webSocket?.data?.[item?.symbol]?.[priceType]
    ) || 0;
  let ltp =
    useSelector(
      (state) => state.webSocketSlice?.webSocket?.data?.[item?.symbol]?.["LTP"]
    ) || 0;

  const finalPrice = useMemo(() => (price === 0 ? ltp : price), [price, ltp]);

  const { color, backgroundColor } = useColorChange({
    currentValue: finalPrice,
  });

  const formattedPrice = useMemo(
    () => formatPrice(finalPrice, item?.marketName, priceType),
    [finalPrice, item?.marketName, priceType]
  );

  const style = useMemo(
    () => ({
      backgroundColor,
      color,
      borderRadius: "5px",
      padding: "0.5rem 0rem",
    }),
    [backgroundColor, color, priceType]
  );

  return <div style={style}>{formattedPrice}</div>;
};

export const MemorizedPercentageAero = ({ item, number, aero, flage }) => {
  // const Price_Chan =
  //   useSelector((state) => state?.webSocketSlice?.webSocket?.data) || 0;
  const Price_Change =
    useSelector(
      (state) =>
        state?.webSocketSlice?.webSocket?.data?.[item?.symbol]?.["Price_Change"]
    ) || 0;

  // let Change = LTP - Close || 0;
  // let Percentage = flage ? Price_Change : (Change * 100) / Close || 0;

  const percentage = useMemo(() => {
    return (
      <div
        style={{ display: "flex", alignItems: "center", justifyContent: "end" }}
      >
        {number == true ? (
          <div style={{ marginRight: "1rem" }}>{Price_Change}</div>
        ) : null}
        {aero !== false && (
          <div>
            <img
              src={Price_Change > 0 ? upAero : downAero}
              alt="Product"
              width="12"
            />
          </div>
        )}
      </div>
    );
  }, [Price_Change]);

  return <>{percentage}</>;
};

export const MemorizedPriceChange = ({ item }) => {
  const PriceChange =
    useSelector(
      (state) =>
        state?.webSocketSlice?.webSocket?.data?.[item?.symbol]?.["Net_Change"]
    ) || 0;

  const finalPrice = useMemo(() => {
    return item?.marketName === "FOREX" || item?.marketName === "GLOBEX"
      ? // || item?.marketName === "COMEX"
        limitNumberLength(trimZeros(PriceChange))
      : PriceChange;
  }, [PriceChange]);

  return <>{finalPrice}</>;
};

export const MemorizedOpenClose = ({ item, priceType }) => {
  const price =
    useSelector(
      (state) =>
        state.webSocketSlice?.webSocket?.data?.[item?.symbol]?.[priceType]
    ) || 0;

  const finalPrice = useMemo(() => {
    return findMarket(item?.marketName) ? limitNumberLength(price) : price;
  }, [price, priceType]);

  return <>{finalPrice}</>;
};

export const MemorizedHighLowPrice = ({ item, priceType, index }) => {
  const token = theme.useToken().token;
  let LTP =
    useSelector(
      (state) => state.webSocketSlice?.webSocket?.data?.[item?.symbol]?.LTP
    ) || 0;

  let price =
    useSelector(
      (state) =>
        state.webSocketSlice?.webSocket?.data?.[item?.symbol]?.[priceType]
    ) || 0;

  const HighLow = useMemo(() => {
    let backGround = false;
    let setIndex = "";
    if (LTP === price && price !== 0) {
      backGround = true;
      setIndex = index;
    }
    const memoizedColor =
      priceType === "High" ? token.marketWatchUp : token.marketWatchDown;
    return (
      <div
        style={{
          backgroundColor: backGround ? memoizedColor : "",
          color: backGround ? "white" : "black",
          borderRadius: "5px",
          padding: "0.5rem 0rem",
          display: "flex",
          justifyContent: backGround && index == setIndex ? "center" : "end",
        }}
      >
        {findMarket(item?.marketName) ? limitNumberLength(price) : price}
      </div>
    );
  }, [LTP, price, priceType]);

  return <>{HighLow}</>;
};

export const MemorizedPositionData = ({ id, type }) => {
  const value =
    useSelector((state) => state?.positionSocketSlice?.data?.[id]?.[type]) || 0;
  const { color, backgroundColor } = useColorChange({
    currentValue: value,
  });

  return (
    <div
      style={{
        color,
        backgroundColor,
        borderRadius: "5px",
        padding: "0.5rem 0rem",
      }}
    >
      {value}
    </div>
  );
};

export const PositionSocketData = ({ id, type }) => {
  const value =
    useSelector((state) => state?.positionSocketSlice?.data?.[id]?.[type]) || 0;

  return (
    <span style={{ color: value > 0 ? "green" : "red" }}>
      {value > 0 ? `+${value}` : `${value}`}
    </span>
  );
};

export const PositionQuantity = ({ id, type, lot, market }) => {
  const quantity = useSelector(
    (state) => state?.positionSocketSlice?.data?.[id]?.[type]
  );
  const Lot = useSelector(
    (state) => state?.positionSocketSlice?.data?.[id]?.[lot]
  );

  return (
    <div>
      {quantity ? `${quantity}` : 0}
      {market !== "NSE EQU" && <>{Lot ? `(${Lot})` : "(0)"}</>}
    </div>
  );
};

export const PositionAverage = ({ id, type }) => {
  const value = useSelector(
    (state) => state?.positionSocketSlice?.data?.[id]?.[type]
  );
  return isNaN(value) ? "-" : value ?? "-";
};

export const MemoizedSocketScript = React.memo(({ script }) => {
  const socketContext = useSocket();
  const dispatch = useDispatch();
  const { doSend, socketData } = socketContext;
  const allScript = store.getState()?.webSocketSlice?.webSocket?.data;
  const scriptRef = useRef(null);

  useEffect(() => {
    dispatch(setScriptCall(script));
    setTimeout(() => {
      let filteredScript = script.filter((script) => !allScript[script]);
      if (filteredScript.length >= 1) {
        doSend({
          event: "subscribeToServerMarket",
          payload: filteredScript,
        });
        scriptRef.current = filteredScript;
      }
    }, 1);
  }, [script]);
});

export const UnsubscribeSymbol = React.memo(({ script }) => {
  const socketContext = useSocket();
  const { doSend, socketData } = socketContext;

  useEffect(() => {
    console.log("unsubscribeToServerMarket", script);
    if (script && script?.length > 0) {
      doSend({
        event: "unsubscribeToServerMarket",
        payload: script,
      });
    }
  }, [script]);
});
