import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import APICall from "../../../APICall";
import { Form, Modal, Row, notification } from "antd";
import {
  setSeletedMarket,
  setWatchListArray,
} from "../../../store/slice/marketWatchSlice";
import ThemeInput from "../../../components/ThemeInput";
import EndPoints from "../../../APICall/EndPoints";

const WatchListModal = ({ watchListModal, setWatchListModal }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  //redux
  const watchListData = useSelector(
    (state) => state?.marketWatchSlice?.watchListArray
  );

  const handleSubmit = async (name) => {
    let obj = {
      name: name,
      index:
        watchListData?.length > 0
          ? watchListData[watchListData.length - 1]?.index
          : 1,
    };
    setLoading(true);
    try {
      let response = await APICall("post", EndPoints.addWatchList, obj);
      if (response.status === 201) {
        console.log("response", response?.data);
        setWatchListModal(false);
        notification.success({ message: "WatchList created successfully" });
        dispatch(
          setWatchListArray({ type: "add", data: response?.data?.data })
        );
        dispatch(setSeletedMarket(watchListData?.length));
      } else {
        notification.error({ message: response?.data?.message });
      }
    } catch (error) {
      console.log("error", error);
      notification.error({ message: error?.message });
    } finally {
      setLoading(false);
    }
  };

  const handleFinish = (value) => {
    // console.log("finish", value);
    handleSubmit(value?.name);
  };
  const handleFinishFailed = (value) => {
    // console.log("failed", value);
  };

  return (
    <Form
      form={form}
      onFinish={handleFinish}
      onFinishFailed={handleFinishFailed}
    >
      <Modal
        title="Create WatchList"
        open={watchListModal}
        onCancel={() => setWatchListModal(false)}
        onOk={() => form.submit()}
        confirmLoading={loading}
      >
        <Row align={"middle"} justify={"center"} style={{ marginTop: "2rem" }}>
          <ThemeInput
            title="Name"
            name="name"
            formProps={{
              name: "name",
              initialValue: `Watchlist-${watchListData.length + 1}`,
              rules: [
                { required: true, message: "Please enter watchlist name" },
              ],
            }}
            wrapperwidth={"90%"}

            // inputProps={{
            //   name: "watchlistName",
            //   value: name,
            //   onChange: (e) => setName(e.target.value),
            //   style: { marginTop: "1rem" },
            // }}
          />
        </Row>
      </Modal>
    </Form>
  );
};

export default WatchListModal;
