import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

export const ProtectedRoute = ({ children }) => {
  const accessToken = useSelector((state) => state.authSlice.accessToken);
  const setPassward = useSelector(
    (state) => state.authSlice?.userDetail?.resetPassword
  );

  if (!accessToken) {
    return <Navigate to="/login" />;
  } else if (accessToken && setPassward) {
    return <Navigate to="/resetPassward" />;
  }
  return children;
};
