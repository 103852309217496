import React from "react";
import ThemeCard from "../../components/ThemeCard";
import { Button, Col, Radio, Row, Typography, theme } from "antd";
import ThemeDropDown from "../../components/ThemeDropDown";
import ThemeCardTable from "../../components/ThemeCardTable";

function TradeReport() {
  const token = theme.useToken().token;
  const style = {
    submitBtn: {
      borderRadius: token.borderRadiusLG,
      fontSize: "1rem",
      backgroundColor: token.colorBgActionButton,
      boxShadow: token.boxShadowNone,
      marginRight: "2rem",
      fontWeight: token.fontWeightStrong,
      height: "3rem",
    },
    resetBtn: {
      borderRadius: token.borderRadiusLG,
      fontSize: "1rem",
      backgroundColor: "transparent",
      boxShadow: token.boxShadowNone,
      color: "#727880",
      borderColor: "#EEEEEE",
      height: "3rem",
    },
  };

  const dataSource = [
    {
      key: 1,
      tradeTime: "1",
      client: "0.00",
      script: "0.00",
      tradeType: "0.00",
      tradeDate: "0.00",
      qty: "0.00 ",
      lot: "0.00",
      myBrokerage: "0.00",
      uplineBrokerage: "0.00",
      downlineBrokerage: "0.00",
      p1: "0.00",
      p2: "0.00",
      p3: "0.00",
      totalPartnership: "0.00",
    },
    {
      key: 1,
      tradeTime: "1",
      client: "0.00",
      script: "0.00",
      tradeType: "0.00",
      tradeDate: "0.00",
      qty: "0.00 ",
      lot: "0.00",
      myBrokerage: "0.00",
      uplineBrokerage: "0.00",
      downlineBrokerage: "0.00",
      p1: "0.00",
      p2: "0.00",
      p3: "0.00",
      totalPartnership: "0.00",
    },
    {
      key: 1,
      tradeTime: "1",
      client: "0.00",
      script: "0.00",
      tradeType: "0.00",
      tradeDate: "0.00",
      qty: "0.00 ",
      lot: "0.00",
      myBrokerage: "0.00",
      uplineBrokerage: "0.00",
      downlineBrokerage: "0.00",
      p1: "0.00",
      p2: "0.00",
      p3: "0.00",
      totalPartnership: "0.00",
    },
  ];

  const columns = [
    {
      title: "TRADE TIME",
      dataIndex: "tradeTime",
      key: "tradeTime",
      align: "center",
    },

    {
      title: "CLIENT",
      dataIndex: "client",
      key: "client",
      align: "center",
    },
    {
      title: "SCRIPT",
      dataIndex: "script",
      key: "script",
      align: "center",
    },
    {
      title: "TRADE TYPE",
      dataIndex: "tradeType",
      key: "tradeType",
      align: "center",
    },
    {
      title: "TRADE DATE",
      dataIndex: "tradeDate",
      key: "tradeDate",
      align: "center",
    },
    {
      title: "QTY",
      dataIndex: "qty",
      key: "qty",
      align: "center",
    },
    {
      title: "LOT",
      dataIndex: "lot",
      key: "lot",
      align: "center",
    },
    {
      title: "MY BROKERAGE",
      dataIndex: "myBrokerage",
      key: "myBrokerage",
      align: "center",
    },
    {
      title: "UPLINE BROKERAGE",
      dataIndex: "uplineBrokerage",
      key: "uplineBrokerage",
      align: "center",
    },
    {
      title: "DOWNLINE BROKERAGE",
      dataIndex: "downlineBrokerage",
      key: "downlineBrokerage",
      align: "center",
    },
    {
      title: "P1",
      dataIndex: "p1",
      key: "p1",
      align: "center",
    },
    {
      title: "P2",
      dataIndex: "p2",
      key: "p2",
      align: "center",
    },
    {
      title: "P3",
      dataIndex: "p3",
      key: "p3",
      align: "center",
    },
    {
      title: "TOTAL PARTNERSHIP",
      dataIndex: "totalPartnership",
      key: "totalPartnership",
      align: "center",
    },
  ];
  return (
    <>
      <ThemeCard>
        <Radio.Group
          style={{
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Radio
            value={1}
            style={{ fontWeight: 600, color: "#727880", fontSize: "1.2rem" }}
          >
            Brokerage Sharing
          </Radio>
          <Radio
            value={2}
            style={{
              fontWeight: 600,
              color: "#727880",
              marginLeft: "3rem",
              fontSize: "1.2rem",
            }}
          >
            Percentage Sharing
          </Radio>
        </Radio.Group>
      </ThemeCard>
      <ThemeCard style={{ marginTop: "2rem" }}>
        <Row style={{ marginTop: "2rem", justifyContent: "space-between" }}>
          <Col span={4}>
            <ThemeDropDown
              title={"Market"}
              placeholder="Select"
              name="market"
              width={"98%"}
            />
          </Col>
          <Col span={4}>
            <ThemeDropDown
              title={"Script Name"}
              placeholder="Script Name"
              name="market"
              width={"98%"}
            />
          </Col>
          <Col span={4}>
            <ThemeDropDown
              title={"Valan"}
              placeholder="Valan"
              name="market"
              width={"98%"}
            />
          </Col>
          <Col span={4}>
            <ThemeDropDown
              title={"Trade After"}
              placeholder="dd-mm-yy"
              name="market"
              width={"98%"}
            />
          </Col>
          <Col span={4}>
            <ThemeDropDown
              title={"Trade Before"}
              placeholder="dd-mm-yy"
              name="market"
              width={"98%"}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: "2rem" }} gutter={10}>
          <Col span={4}>
            <ThemeDropDown
              title={"Super Admin"}
              placeholder="Select Admin"
              name="market"
              width={"98%"}
            />
          </Col>
          <Col span={4}>
            <ThemeDropDown
              title={"Select Client"}
              placeholder="Select Client"
              name="market"
              width={"98%"}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: "2rem" }} gutter={10}>
          <Col span={8}>
            <Button type="primary" style={style.submitBtn}>
              Submit
            </Button>
            <Button type="primary" style={style.resetBtn}>
              Reset
            </Button>
          </Col>
        </Row>
      </ThemeCard>
      <ThemeCardTable
        buttonType={false}
        data={dataSource}
        column={columns}
        headingStyle={false}
        isFilterTrue={true}
      />
    </>
  );
}

export default TradeReport;
