import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { App, theme } from "antd";

import { resetUserAccountSlice } from "../store/slice/userAccountSlice";
import packageJson from "../../package.json";
import { Emitter } from "./../Helper/constant";
import { removeCache } from "../Helper/functions";
import WebSocketRoot from "./Socket";

const RootCommonFunction = () => {
  const dispatch = useDispatch();
  const { notification } = App.useApp();

  //life cycles
  useEffect(() => {
    window?.addEventListener("focus", checkVersionUpdate);
    const interval = setInterval(checkVersionUpdate, 30000);

    dispatch(resetUserAccountSlice());
    checkVersionUpdate();
    return () => {
      Emitter?.removeAllListeners();
      clearInterval(interval);
      window?.removeEventListener("focus", checkVersionUpdate);
    };
  }, []);

  //functions
  const checkVersionUpdate = () => {
    let APP_VERSION = localStorage.getItem("APP_VERSION");

    if (!APP_VERSION) {
      localStorage.setItem("APP_VERSION", packageJson.version);
      APP_VERSION = packageJson.version;
      removeCache();
      window.location.replace(window.location.href);
    }
    if (APP_VERSION && APP_VERSION !== packageJson.version) {
      localStorage.setItem("APP_VERSION", packageJson.version);
      APP_VERSION = packageJson.version;
      notification.info({
        message: "app has been updated to the latest version.",
      });

      // removeAllCookies(location);
      removeCache();
      setTimeout(() => {
        window.location.replace(window.location.href);
      }, 4000);
    }
  };
  // throw new Error("Dddd")

  return null;
};

export default RootCommonFunction;
