import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export const removeFieldsWithXXYY = (obj) => {
  // Helper function to recursively process the object or array
  function process(value) {
    if (Array.isArray(value)) {
      // Process array elements recursively
      return value.map(process).filter((item) => item !== undefined);
    } else if (value !== null && typeof value === "object") {
      // Process object properties recursively
      const newObj = {};
      for (const key in value) {
        if (value[key] === "XXYY") {
          // Skip fields with the value "XXYY"
          continue;
        } else {
          // Recursively process nested objects
          const processedValue = process(value[key]);
          if (processedValue !== undefined) {
            newObj[key] = processedValue;
          }
        }
      }
      return Object.keys(newObj).length > 0 ? newObj : undefined;
    } else {
      // Return value as is if it's not an object or array
      return value;
    }
  }

  // Start processing the input object
  return process(obj);
};

export const convertTimeToISO = (timeString, referenceISOString) => {
  // Split the input time string into minutes and seconds
  const [minutes, seconds] = timeString.split(":").map(Number);

  // Parse the reference ISO string
  const referenceDate = dayjs("2024-10-02T01:37:07.000Z")
    .minute(minutes)
    .second(seconds);

  // Create new date with same hours but new minutes and seconds
  const newDate = referenceDate.hour(0).minute(minutes).second(seconds);

  return {
    iso: newDate.toISOString(),
    formatted: `0 hr ${minutes} min ${seconds} sec`,
    dayjs: newDate,
  };
};
