import { SettingOutlined } from "@ant-design/icons";
import { Form, Space, TimePicker, Typography } from "antd";
// import dayjs from "dayjs";
import { styled, createGlobalStyle, ThemeProvider } from "styled-components";

const GlobalStyle = createGlobalStyle`
  .custom-header-time-picker .ant-picker-time-panel-column {
    position: relative;
    padding-top: 24px !important;
  }

  .custom-header-time-picker .ant-picker-time-panel-column::before {
    content: attr(data-label);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 24px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    background: #f5f5f5;
    border-bottom: 1px solid #f0f0f0;
  }

  .custom-header-time-picker .ant-picker-time-panel-column:nth-child(1)::before {
    content: "${(props) => props.theme.timeLabel1}";
  }

  .custom-header-time-picker .ant-picker-time-panel-column:nth-child(2)::before {
    content: "${(props) => props.theme.timeLabel2}";
  }

  .custom-header-time-picker .ant-picker-time-panel-column:nth-child(3)::before {
    content: "${(props) => props.theme.timeLabel3}";
  }
`;
const StyledThemeInput = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    & .ant-form-item-explain-error {
      width: ${(props) => props?.width};
      font-size: 0.8rem;
      font-style: normal;
      font-weight: 400;
    }
    .ant-input-group-addon {
      background-color: ${(props) => {
        return props?.$active
          ? props?.$activeColor || props.theme.token.colorPrimary
          : props?.$showSuffixBg
          ? props.theme.token.colorBgLayout
          : props.theme.token.colorBgBase;
      }};
    }
    .ant-input-affix-wrapper {
      padding-block: 0rem;
    }
    .ant-input-prefix {
      font-size: 1.3rem;
    }

    .ant-form-item {
      margin-bottom: ${(props) =>
        props?.$marginBottom ? props?.$marginBottom : "1.5rem"};
    }
  `,
  Title: styled(Typography.Title)`
    &.ant-typography {
      color: ${(props) => props.theme.token.colorTextLabel};
      font-size: 1rem;
      font-style: normal;
      font-weight: ${(props) =>
        props?.$titleFontWeight ? props?.$titleFontWeight : 600};
      margin-bottom: 0.5rem;
      line-height: 1.125rem;
      & .styled-drop-down-star {
        color: ${(props) => props.theme.token.red4};
        font-size: 1rem;
      }
    }
    &.sub-title {
      color: ${(props) => props.theme.token.colorTextLabel};
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      margin-bottom: 0.5rem;
      line-height: 1.125rem;
    }
    &.sub-title-2 {
      font-weight: 600;
    }
  `,
  TimePicker: styled(TimePicker)`
    border-radius: ${(props) => props.theme.token.borderRadiusLG + "px"};
    & .ant-picker-input input {
      font-size: 1rem !important;
      font-style: normal;
      font-weight: 400;
      height: 2.4rem;
    }
    &.ant-picker {
      width: 100%;
    }
  `,
};

const ThemeTimePicker = (props) => {
  const theme = {
    timeLabel1: props?.inputProps?.format === "HH:mm:ss" ? "HH" : "MM",
    timeLabel2: "MM",
    timeLabel3: "SS",
  };
  //functions
  const onChangeTime = (name, value, string) => {
    props.inputProps?.onChange &&
      props.inputProps?.onChange({
        target: {
          name,
          value: value?.$d,
        },
        string,
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        <StyledThemeInput.Wrapper
          width={props?.width}
          $marginBottom={props?.marginBottom}
          $active={props?.active}
        >
          <Space
            style={{
              justifyContent: "space-between",
              ...props?.titleWrapperStyle,
            }}
          >
            {props?.title ? (
              <StyledThemeInput.Title $titleFontWeight={props?.titleFontWeight}>
                {props?.title}
                {props?.required ? (
                  <span className="styled-drop-down-star">*</span>
                ) : null}
              </StyledThemeInput.Title>
            ) : null}
            {props?.subTitle ? (
              <StyledThemeInput.Title className="sub-title">
                {props?.subTitle}
              </StyledThemeInput.Title>
            ) : null}
          </Space>
          <div style={{ width: props?.width }}>
            <Form.Item {...props?.formProps}>
              <StyledThemeInput.TimePicker
                popupClassName="custom-header-time-picker"
                // defaultValue={dayjs("00:00", "mm:ss")}
                format={"HH:mm"}
                suffixIcon={<SettingOutlined />}
                {...props?.inputProps}
                onChange={(value, string) =>
                  onChangeTime(props?.inputProps?.name, value, string)
                }
              />
            </Form.Item>
          </div>
        </StyledThemeInput.Wrapper>
      </>
    </ThemeProvider>
  );
};

export default ThemeTimePicker;
