import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  entriesPerPage: null,
};

export const dataFilterSlice = createSlice({
  name: "dataFilterSlice",
  initialState,
  reducers: {
    setEntry: (state, action) => {
      state.entriesPerPage = action.payload;
    },
  },
});

export const { setEntry } = dataFilterSlice.actions;

export default dataFilterSlice.reducer;
