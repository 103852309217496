import { forwardRef, useImperativeHandle } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSocket } from "../../../components/Socket";
import { notification } from "antd";
import APICall from "../../../APICall";
import EndPoints from "../../../APICall/EndPoints";
import { setWatchListArray } from "../../../store/slice/marketWatchSlice";

const ScriptLogic = forwardRef(
  (
    {
      value,
      setValue,
      setExpiryDatesList,
      setStrikePriceOptions,
      setAddLoading,
      positionData,
    },
    ref
  ) => {
    const socketContext = useSocket();
    const { doSend, socketData } = socketContext;
    const dispatch = useDispatch();
    const scriptData = useSelector(
      (state) => state?.marketWatchSlice?.data[value?.marketName]
    );
    const watchList = useSelector(
      (state) => state?.marketWatchSlice?.watchListArray
    );
    const selectedIndex = useSelector(
      (state) => state?.marketWatchSlice?.seletctedMaketIndex
    );
    const watchListId = watchList?.[selectedIndex]?._id;

    let show_expiry_date_input = false;
    let show_option_input = false;
    if (
      value?.marketName !== "NSE EQU" &&
      value?.marketName !== "FOREX" &&
      value?.marketName !== "GLOBEX"
    ) {
      show_expiry_date_input = true;
    }
    if (value?.marketName === "MCX OPT" || value?.marketName === "NSE OPT") {
      show_option_input = true;
    }

    // function
    const resetAllStats = () => {
      let option = false;
      if (value?.marketName === "NSE OPT" || value?.marketName === "MCX OPT") {
        option = true;
      }

      if (!option) {
        setExpiryDatesList([]);
        setStrikePriceOptions([]);
        setValue({
          marketName: value.marketName,
          Exchange: null,
          instrument_type: null,
          script: null,
          expiryDate: null,
          option_type: null,
          strikePrice: null,
        });
      } else {
        setValue({
          ...value,
          marketName: value.marketName,
          Exchange: null,
          instrument_type: null,
          script: option ? value.script : null,
          expiryDate: option ? value.expiryDate : null,
          option_type: option ? value.option_type : null,
          strikePrice: null,
        });
      }
    };

    //api
    const addScript = async (value) => {
      setAddLoading(true);
      await APICall("post", EndPoints.addScript, [value])
        .then((response) => {
          if (
            (response.status === 201 || response.status === 200) &&
            response.data
          ) {
            // console.log("addScriptResponce", response?.data);

            let data = response?.data?.data[0];
            let newPosition = positionData?.filter(
              (item) => item?.symbol === data?.symbol
            );
            let dummyObj = {
              ...value,
              _id: data?._id,
              // marketName: data?.symbolName,
              expiryDate: data?.expiryDate,
              InstrumentIdentifier: value?.symbol,
              symbol: data?.symbol,
              series: data?.series,
              symbol: data?.symbol,
              symbolName: data?.symbolName,
              positions: newPosition,
            };
            console.log("dummyObj===>", dummyObj);
            // console.log("script", data);
            if (data?.symbol) {
              doSend({
                event: "subscribeToServerMarket",
                payload: [data?.symbol],
              });
            }

            dispatch(
              setWatchListArray({
                type: "scriptAdd",
                data: dummyObj,
                index: selectedIndex,
              })
            );

            resetAllStats();
            notification?.success({
              message: response.data?.message,
            });
          } else if (response.status === 409 || response.status === 500) {
            console.log("error", response);
            notification?.error({
              message: response.data?.message,
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
          notification?.error({
            message: error?.response?.data?.message,
          });
        })
        .finally(() => {
          setAddLoading(false);
        });
    };

    const checkScript = () => {
      if (!value?.marketName || !value?.script) {
        notification?.info({
          message: "Please fill out the form",
        });
        return;
      }
      if (show_expiry_date_input && !value?.expiryDate) {
        notification?.info({
          message: "Please fill out the form",
        });
        return;
      }
      if (show_option_input && (!value?.option_type || !value?.strikePrice)) {
        notification?.info({
          message: "Please fill out the form",
        });
        return;
      }

      // const isPresent = scriptData?.some(
      //   (el) => el?.symbolId === value?.symbolId
      // );

      // if (isPresent) {
      //   return notification.info({
      //     message: "Script allready available in watch list",
      //   });
      // }
      if (value?.script && value?.marketName) {
        addScript({ ...value, deviceType: "WEB", watchListId: watchListId });
      }
    };

    useImperativeHandle(
      ref,
      () => ({
        callCheckScript: () => checkScript(),
      }),
      [checkScript]
    );
  }
);

export default ScriptLogic;
