import { Button, Col, DatePicker, Modal, Row, notification } from "antd";
import React, { useEffect, useState } from "react";
import ThemeInput from "../../components/ThemeInput";
import { styled } from "styled-components";
import ThemeCard from "../../components/ThemeCard";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import ThemeCardTable from "../../components/ThemeCardTable";
import { valanBillDate } from "../../components/CommanFuction/CommanFunction";
import { DeleteIcon, EditIcon } from "../Dashboard/style";
import VerificationModal from "../../components/VerificationModal/VerificationModal";

const StyledHeading = styled.div`
  font-size: 2rem;
`;
const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledLightHeading = styled.p`
  font-size: 1rem;
  font-weight: 350;
  margin-left: 10rem;
`;
const ValanButton = styled(Button)`
  font-size: 1.2rem;
  margin: auto;
  background-color: #798478;
  color: white;
`;
const StyledLabel = styled.label`
  font-size: 1rem;
`;

const ValanSetting = () => {
  // state
  const [isModalOpen, setisModalOpen] = useState(false);
  const [showModal, setShowModal] = useState({
    name: null,
    flag: false,
    record: null,
  });
  const [value, setValue] = useState({
    name: "",
    formDate: "",
    toDate: "",
  });

  const queryClient = useQueryClient();

  // life cycle
  useEffect(() => {
    queryClient.resetQueries(["getValan"]);
  }, []);

  // api call
  const getValanList = async () => {
    let response = await APICall("get", EndPoints.getValanList);
    return response?.data?.data?.valanList;
  };
  const {
    isLoading: valanLoading,
    error: valanError,
    data: valanData,
  } = useQuery({ queryKey: ["getValan"], queryFn: getValanList });

  if (valanError) {
    notification.error({ message: valanError?.message });
  }

  const handleDeleteValan = async (record) => {
    try {
      let response = await APICall(
        "delete",
        `${EndPoints.deleteValan}${record?._id}`
      );
      if (response.status === 200) {
        notification.success({ message: response?.data?.message });
        setShowModal({ name: null, flag: false, record: null });
      } else {
        notification.error({ message: response?.data?.message });
      }
    } catch (error) {
      notification.error({ message: error?.message });
    }
  };

  // function
  const handleOk = () => {
    setisModalOpen(false);
  };
  const handleCancel = () => {
    setisModalOpen(false);
    setShowModal({ name: null, flag: false, record: null });
  };

  const handleEdit = (record) => {
    setValue({ ...value, name: record.name });
    setisModalOpen(true);
    // console.log("record", record);
  };

  const handleAdd = () => {
    setValue({ name: "", formDate: "", toDate: "" });
    setisModalOpen(true);
  };

  const onChange = (e) => {
    setValue({ ...value, name: e.target.value });
  };
  const onChangeDate = () => {};

  // column
  const columns = [
    {
      title: "VALAN NAME",
      dataIndex: "valanName",
      key: "name",
    },
    {
      title: "From Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (text, record) => {
        const startDate = valanBillDate(record?.startDate, true);
        return <div>{startDate}</div>;
      },
    },
    {
      title: "To Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (text, record) => {
        const endDate = valanBillDate(record?.endDate, true);
        return <div>{endDate}</div>;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: "10rem",
      render: (text, record) => {
        return (
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            {/* <EditIcon onClick={() => handleEdit(record)} /> */}
            <DeleteIcon
              onClick={() => {
                setShowModal({
                  name: "delete",
                  flag: true,
                  record: record,
                });
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <ThemeCard style={{ marginTop: "2rem" }}>
        <StyledRow>
          <Col>
            <StyledHeading>Valan Schedule</StyledHeading>
            <StyledLightHeading>List of Schedule</StyledLightHeading>
          </Col>
          {/* <Col>
            <ValanButton onClick={handleAdd}>Add</ValanButton>
          </Col> */}
        </StyledRow>
      </ThemeCard>

      <ThemeCardTable
        data={valanData}
        column={columns}
        isLoading={valanLoading}
      />
      {showModal?.flag && showModal?.name === "delete" && (
        <VerificationModal
          handleCancel={handleCancel}
          record={showModal.record}
          handleExecute={handleDeleteValan}
          query={"getValan"}
          type="execute"
          title={"Delete Valan"}
          customTitle={"Delete Valan Data"}
          customeDescription={`Are you sure you want to delete ${showModal?.record?.valanName} valan data?`}
        />
      )}

      <Modal
        title="Add/Edit Valan Schedule"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Row style={{ marginTop: "2rem" }}>
          <Col span={10}>
            <ThemeInput
              title="Valan Name"
              required
              formProps={{
                name: "Valan Name",
                rules: [{ required: true, message: "please add account name" }],
              }}
              inputProps={{
                name: "valanName",
                value: value.name,
                onChange: onChange,
              }}
            />
          </Col>
        </Row>
        <div style={{ marginBottom: "2rem", display: "flex" }}>
          <div>
            <StyledLabel>FromDate</StyledLabel>
            <DatePicker onChange={onChangeDate} style={{ width: "17rem" }} />
          </div>
          <div>
            <StyledLabel>ToDate</StyledLabel>
            <DatePicker style={{ width: "17rem" }} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ValanSetting;
