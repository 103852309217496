import { useSelector } from "react-redux";
import ThemeCard from "../../../../components/ThemeCard";
import { Col, Form, Row } from "antd";
import ThemeInput from "../../../../components/ThemeInput";
import ThemeInputNumber from "../../../../components/ThemeInputNumber/ThemeInputNumber";
import React from "react";
import { ScriptWiseSettingCheckbox } from "../style";

const ScriptWiseSettingsView = ({ tab, view }) => {
  const showStrikeRateView = ["NSE OPT", "MCX OPT"].includes(tab);
  const showCheckBoxView = ["NSE OPT", "MCX OPT", "NSE EQU"].includes(tab);
  const showLTPBoxView = ["FOREX", "GLOBEX"].includes(tab);

  //redux
  const form_data = useSelector(
    (state) => state.userAccountSlice?.[tab]?.[view]
  );
  const accountType = useSelector(
    (state) => state.userAccountSlice.accountType
  );
  const marketDetails = useSelector(
    (state) => state.authSlice.MarketAccessDetails
  );
  const isVisible = marketDetails?.additional;
  const isRent = marketDetails?.accountDetails?.partnershipWith === "rent";
  const isMaster = accountType === "masterAdmin";
  const isCustomer = accountType === "customer";
  const totalScript = marketDetails?.scriptWiseDetails?.[tab]?.totalNoOfScripts;
  const minScriptRate =
    marketDetails?.scriptWiseDetails?.[tab]?.minimumScriptRate;
  const orderLimits = marketDetails?.scriptWiseDetails?.[tab]?.orderLimits;
  const strikeRateMaxLot =
    marketDetails?.scriptWiseDetails?.[tab]?.strikeRateMaxLot;
  // const isLimitAllow = marketDetails?.additional?.;

  return (
    <ThemeCard
      title={`${tab} Script Wise Setting`}
      headerColor
      style={{ marginBottom: "2rem" }}
    >
      <Row justify={"space-between"} gutter={[40]}>
        <Col xs={24} sm={12} md={8} lg={5} xl={4}>
          <ThemeInputNumber
            title="Minimum Script Rate"
            extraProps={{
              input_type: "number",
            }}
            inputProps={{
              name: `minimumScriptRate$${tab}`,
              step: 1,
            }}
            formProps={{
              name: `minimumScriptRate$${tab}`,
              rules: [
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    value = getFieldValue(`minimumScriptRate$${tab}`);
                    if (
                      (value >= minScriptRate && value >= 0) ||
                      ((isMaster || isRent || !minScriptRate) &&
                        (value >= 0 || !form_data?.minimumScriptRate))
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        `You can not give miniMumScriptRate less then ${
                          isMaster || isRent || !minScriptRate
                            ? 0
                            : minScriptRate
                        }`
                      )
                    );
                  },
                }),
              ],
            }}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={5} xl={4}>
          <ThemeInputNumber
            title="Total No. of Script Allowed"
            extraProps={{
              input_type: "number",
            }}
            inputProps={{
              name: `totalNoOfScripts$${tab}`,
              step: 1,
            }}
            formProps={{
              name: `totalNoOfScripts$${tab}`,
              rules: [
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    value = getFieldValue(`totalNoOfScripts$${tab}`);
                    if (
                      // !value ||
                      (value <= totalScript && value >= 0) ||
                      ((isMaster || isRent || !totalScript) &&
                        (value >= 0 || !value))
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        `You can not give no of script less then 0 ${
                          isRent || isMaster || !totalScript
                            ? ""
                            : `and more then ${totalScript}`
                        }  `
                      )
                    );
                  },
                }),
              ],
            }}
          />
        </Col>

        {accountType === "customer" ? (
          <Col xs={24} sm={12} md={8} lg={5} xl={4}>
            <ThemeInputNumber
              title={
                tab === "NSE EQU" ? "Total No. of Quantity" : "Total No. of Lot"
              }
              extraProps={{
                input_type: "number",
              }}
              inputProps={{
                name:
                  tab === "NSE EQU"
                    ? `totalNoOfQuantity$${tab}`
                    : `totalNoOfLot$${tab}`,
                step: 1,
              }}
              formProps={{
                name:
                  tab === "NSE EQU"
                    ? `totalNoOfQuantity$${tab}`
                    : `totalNoOfLot$${tab}`,
                // rules: [
                //   () => ({
                //     validator(_, value) {
                //       if (
                //         !value ||
                //         (value <= totalScript && value >= 0) ||
                //         accountType === "masterAdmin"
                //       ) {
                //         return Promise.resolve();
                //       }
                //       return Promise.reject(
                //         new Error(
                //           `You can not give noOfScript more then ${totalScript} or less then 0`
                //         )
                //       );
                //     },
                //   }),
                // ],
              }}
            />
          </Col>
        ) : null}

        {!showLTPBoxView && (
          <Col xs={24} sm={12} md={8} lg={4} xl={4}>
            <ThemeInputNumber
              title="Order Limit LTP in Percentage"
              extraProps={{
                input_type: "number",
              }}
              inputProps={{
                name: `orderLimits$${tab}`,
                step: 1,
                suffix: "%",
              }}
              formProps={{
                name: `orderLimits$${tab}`,
                rules: [
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      value = getFieldValue(`orderLimits$${tab}`);
                      if (
                        (value && value >= 0 && value <= orderLimits) ||
                        ((isMaster || isRent || !orderLimits) &&
                          ((value >= 0 && value <= 100) || !value))
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          `You can not give orderLimits ${
                            isMaster || isRent || !orderLimits
                              ? `less then 0 and more then 100`
                              : `more then ${orderLimits} `
                          } `
                        )
                      );
                    },
                  }),
                ],
              }}
            />
          </Col>
        )}
        {showStrikeRateView ? (
          <Col xs={24} sm={12} md={8} lg={5} xl={4}>
            <ThemeInput
              title="Single Strike Rate Max Lot"
              extraProps={{
                input_type: "number",
              }}
              inputProps={{
                name: `strikeRateMaxLot$${tab}`,
              }}
              formProps={{
                name: `strikeRateMaxLot$${tab}`,
                rules: [
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      value = getFieldValue(`strikeRateMaxLot$${tab}`);
                      if (
                        (value <= strikeRateMaxLot && value >= 0) ||
                        ((isMaster || isRent || !strikeRateMaxLot) &&
                          (value >= 0 || !value))
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          `You can not give ${
                            isMaster || isRent || !strikeRateMaxLot
                              ? `less then 0 `
                              : `more then ${strikeRateMaxLot} and less then 0`
                          } `
                        )
                      );
                    },
                  }),
                ],
              }}
            />
          </Col>
        ) : null}
      </Row>

      <Row gutter={[8]} style={{ marginTop: "2rem" }}>
        {isCustomer && isVisible?.allowLimitOrder === "allow" && (
          <Col xs={24} sm={12} md={8} lg={5} xl={4}>
            <Form.Item
              name={`allowLimitOrder$${tab}`}
              valuePropName="checked"
              initialValue={
                isVisible?.allowLimitOrder === "allow" ? true : false
              }
            >
              <ScriptWiseSettingCheckbox
                name={`allowLimitOrder$${tab}`}
                // disabled={!allowScript?.allowLimitOrder && !isRent && !isMaster}
              >
                Allow Limit Order
              </ScriptWiseSettingCheckbox>
            </Form.Item>
          </Col>
        )}
        {isCustomer &&
          isVisible?.allowQuantityTradingInfo === "allow" &&
          tab !== "NSE EQU" && (
            <Col xs={24} sm={12} md={8} lg={5} xl={4}>
              <Form.Item
                name={`allowQuantityTrading$${tab}`}
                valuePropName="checked"
                initialValue={
                  isVisible?.allowQuantityTradingInfo === "allow" &&
                  tab === "NSE FUT"
                    ? true
                    : false
                }
              >
                <ScriptWiseSettingCheckbox
                  name={`allowQuantityTrading$${tab}`}
                  // disabled={
                  //   !allowScript?.allowQuantityTrading && !isRent && !isMaster
                  // }
                  // checked={form_data?.allowQuantityTrading}
                  // onChange={onChangeCheckbox}
                >
                  Allow Quantity Trading
                </ScriptWiseSettingCheckbox>
              </Form.Item>
            </Col>
          )}
        {showCheckBoxView &&
          isCustomer &&
          isVisible?.allowShortSellInfo === "allow" && (
            <Col xs={24} sm={12} md={8} lg={5} xl={4}>
              <Form.Item
                name={`allowShortSell$${tab}`}
                valuePropName="checked"
                initialValue={false}
              >
                <ScriptWiseSettingCheckbox
                  name={`allowShortSell$${tab}`}
                  // disabled={!allowScript?.allowShortSell && !isRent && !isMaster}
                  // checked={form_data?.allowShortSell}
                  // onChange={onChangeCheckbox}
                >
                  Allow Short Sell
                </ScriptWiseSettingCheckbox>
              </Form.Item>
            </Col>
          )}
      </Row>
    </ThemeCard>
  );
};

export default React.memo(ScriptWiseSettingsView);
