import styled from "styled-components";
import { Table } from "antd";

export const StyledTable = styled(Table)`
  transform: none !important;
  width: 100% !important;

  & .ant-table-thead tr .ant-table-cell {
    background-color: ${(props) => props.theme.token.colorPrimary};
    color: ${(props) => props.theme.token.colorTextLightSolid};
    font-size: 1.1rem;
    font-weight: 500;
    padding: 0.5rem;
  }

  .ant-table-body .ant-table-cell {
    border-bottom: 0px;
    /* font-size: 3.2rem !important;
    padding: 0.5rem !important; */
  }

  .ant-table-wrapper .ant-table-tbody > tr > td {
    padding: 0.5rem !important;
  }

  .ant-table-thead tr th {
    height: 2rem !important;
    padding: 0rem 0.5rem !important;
  }

  .ant-table-thead .ant-table-cell:hover {
    background: ${(props) => props.theme.token.colorPrimaryBgHover} !important;
  }

  .ant-table-tbody .ant-table-row:hover {
    background: #f5f5f5;
  }
`;
export const SpinDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
`;
export const ExpairyDiv = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  text-align: end;
`;
