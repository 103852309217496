import { createSlice } from "@reduxjs/toolkit";

const initalAPI = {
  m2mAlert: true,
  rejectionLog: true,
};

const initialState = {
  marketListData: [],
  constantValue: {},
  summaryDataFilter: {},
  positionFromSummay: {},
  scriptCallList: [],
  m2mAlert: [
    {
      userId: 1234,
      userName: "customer",
      message: `M2M threshold of ${10}% reached.`,
      amount: 100000,
      balance: 200000,
      percentage: 10,
    },
  ],
  userClearFilter: false,
  clearValan: true,
  broadCastExpiry: `This is a virtual trading platform, No real money is involved.`,
  marketValidationErrors: {
    "NSE FUT": false,
    "NSE OPT": false,
    "NSE EQU": false,
    MCX: false,
    "MCX OPT": false,
    FOREX: false,
    COMEX: false,
    GLOBEX: false,
  },
  filterUsers: [],
  apiLink: {
    android: null,
    apple: null,
  },
};

export const globleSlice = createSlice({
  name: "globleSlice",
  initialState,
  reducers: {
    marketDataList: (state, action) => {
      state.marketListData = [];
      action.payload.forEach((item) => {
        let obj = item;
        obj.value = item.scriptName;
        obj.label = item.scriptName;
        state.marketListData.push(obj);
      });
    },
    saveDetails: (state, action) => {
      state.constantValue = action.payload;
    },
    setM2mAlert: (state, action) => {
      state.m2mAlert.push(action.payload);
    },
    setUserClearFilter: (state, action) => {
      if (state.userClearFilter !== action.payload) {
        state.userClearFilter = action.payload;
      }
    },
    setSummaryDataFilter: (state, action) => {
      state.summaryDataFilter = action.payload;
    },
    setPositionFromSummay: (state, action) => {
      state.positionFromSummay = action.payload;
    },
    setValanValue: (state, action) => {
      if (state.clearValan !== action.payload) {
        state.clearValan = action.payload;
      }
    },
    setBroadCastExpiry: (state, action) => {
      state.broadCastExpiry = `${initialState?.broadCastExpiry} ${action.payload}`;
    },
    setScriptCall: (state, action) => {
      state.scriptCallList = action.payload;
    },
    setMarketValidationErrors: (state, action) => {
      let value = action.payload;
      let flage = false;

      for (let key in state.marketValidationErrors) {
        if (
          value[key].toString() !== state.marketValidationErrors[key].toString()
        ) {
          flage = true;
          break;
        }
      }
      if (flage) {
        state.marketValidationErrors = action.payload;
      }
    },
    resetMarketValidationErrors: (state, action) => {
      state.marketValidationErrors = initialState.marketValidationErrors;
    },
    setFilterUsers: (state, action) => {
      state.filterUsers = action.payload;
    },
    setApiLink: (state, action) => {
      state.apiLink = {
        android: action.payload.android_link,
        apple: action.payload.apple_link,
      };
    },
  },
});

export const {
  marketDataList,
  saveDetails,
  setScriptCall,
  setM2mAlert,
  setUserClearFilter,
  setSummaryDataFilter,
  setPositionFromSummay,
  setValanValue,
  setBroadCastExpiry,
  setMarketValidationErrors,
  resetMarketValidationErrors,
  setFilterUsers,
  setApiLink,
} = globleSlice.actions;

export default globleSlice.reducer;
