import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";
import { NAVBAR } from "../../Helper/constant";
// const Button = styled.button`
//   color: ${(props) => {
//     console.log("props.theme: ", props.theme);
//     return props.theme.antd.colorPrimary;
//   }};
// `;
const activeStyles = css`
  &::after {
    content: "";
    position: absolute;
    bottom: 1px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: white;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }
`;

const activeStylesMobile = css`
  border: 1px solid black;
  color: white;
  border-radius: 10px;
  background-color: #565656;
`;

const activeStylesSubMenu = css`
  /* background-color: #e6f4ff; */
  color: #1677ff;
  border-radius: 10px;
`;

const NavbarStyle = {
  Wrapper: styled.nav`
    width: 100%;
    overflow-x: auto;
    ${"" /*overflow-y: hidden; */}
    background-color: ${(props) => props.theme.token.colorPrimary};
    align-self: flex-start;
    padding-inline: 2rem;
    height: ${NAVBAR.HEIGHT};
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 500px) {
      padding-inline: 1rem;
    }
  `,
  Logo: styled.h1`
    color: white;
    font-weight: normal;
    font-size: 2rem;
  `,
  Items: styled.div`
    justify-content: space-between;
    width: 100%;
    height: 100%;
    display: flex;
    gap: 2rem;
    align-items: center;
  `,
  Item: styled(NavLink)`
    color: ${(props) => props.theme.token.colorTextLightSolid};

    font-size: 1.1rem;
    font-weight: 400;
    height: ${NAVBAR.HEIGHT};
    cursor: pointer;
    text-decoration: none;
    padding-inline: 1rem;
    display: flex;
    gap: 1rem;
    align-items: center;
    position: relative;
    font-family: ${(props) => props.theme.token.fontFamily};
    ${(props) => props.$active && !props.$isMobile && activeStyles}
    ${(props) =>
      props.$active && props.$isMobile && !props.$subMenu && activeStylesMobile}
    ${(props) => props.$active && props.$subMenu && activeStylesSubMenu}
    white-space: nowrap;
    @media (max-width: 900px) {
      /* Add your mobile-specific styles here */
      gap: 0.1rem;
    }
  `,
  //dropdown from antd

  IconButton: styled.button`
    color: white;
    font-size: 1.4rem;
    padding: 0 1rem;
    cursor: pointer;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;

    &:focus {
      outline: none;
    }

    &:hover {
      opacity: 0.8;
    }
  `,
};
export default NavbarStyle;
