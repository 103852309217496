import React, { useEffect, useState } from "react";
import { UpOutlined } from "@ant-design/icons";
import { Button } from "antd";

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    let mainDiv = document.getElementsByTagName("main")[0];
    mainDiv.addEventListener("scroll", (e) => {
      if (mainDiv.scrollTop > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    });
  }, []);

  const handleScoll = () => {
    let mainDiv = document.getElementsByTagName("main")[0];
    mainDiv.scrollTo({
      top: 0, // Scroll 100px from the top
      left: 0,
      behavior: "smooth", // This makes the scroll smooth
    });
  };
  if (isVisible)
    return (
      <Button
        type="primary"
        onClick={handleScoll}
        className="scroll-to-top-button"
      >
        <UpOutlined />
      </Button>
    );
  else return <></>;
};

export default ScrollToTopButton;
