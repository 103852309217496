import { Card } from "antd";
import { styled } from "styled-components";

const StyledThemeCard = {
  Card: styled(Card)`
    width: ${(props) => props.width};
    & .ant-card-head {
      padding-inline: 2rem;
      border-radius: ${(props) =>
        `${props.theme.token.borderRadiusLG}px ${props.theme.token.borderRadiusLG}px 0 0`};
      background-color: ${(props) =>
        props?.$headerColor
          ? props.theme.token.colorPrimary
          : props.theme.token.colorBgBase};
    }
    & .ant-card-body {
      padding: 2rem;
      border-radius: ${(props) =>
        `0 0 ${props.theme.token.borderRadiusLG}px ${props.theme.token.borderRadiusLG}px`};
      overflow: hidden;
    }
    & .ant-card-head-wrapper {
      text-align: center;
    }
    & .ant-card-head-title {
      width: fit-content;
      font-size: ${(props) =>
        props?.style?.fontSize ? props?.style?.fontSize : "1.3rem"};
      font-style: normal;
      font-weight: 600;
      color: ${(props) =>
        props?.$headerColor
          ? props.theme.token.colorBgBase
          : props.theme.token.colorTextBase};
    }
  `,
};

const ThemeCard = (props) => {
  return (
    <StyledThemeCard.Card
      title={props?.headerComponent || props?.title}
      $headerComponent={props?.headerComponent}
      $headerColor={props?.headerColor}
      style={props?.style}
    >
      {props?.children}
    </StyledThemeCard.Card>
  );
};

export default ThemeCard;
