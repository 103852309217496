import axios from "axios";
import { Emitter } from "../Helper/constant";
import { resetState } from "../store/slice/authSlice";
import store from "../store/store";

const CancelToken = axios.CancelToken;
let MAX_RETRIES = 2;
let MS = 5 * 1000;
export let cancelApiCall;
// Store requests
let sourceRequest = {};
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}api/v1`,
  // timeout: 10000,
  headers: {
    Accept: "*",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    let Token = localStorage.getItem("token");
    if (Token) {
      config.headers.Authorization = "Bearer " + Token;
      config.cancelToken = new CancelToken(function executor(c) {
        cancelApiCall = c;
      });
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.request.use(
  (request) => {
    // If the application exists cancel
    if (sourceRequest[request.url]) {
      sourceRequest[request.url].cancel("Automatic cancellation");
    }

    // Store or update application token
    const axiosSource = axios.CancelToken.source();
    sourceRequest[request.url] = { cancel: axiosSource.cancel };
    request.cancelToken = axiosSource.token;

    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response?.status === 429 ||
      // error.response?.status === 500 ||
      error.response?.status === 503 ||
      error.response?.status === 404 ||
      error.message === "Network Error"
    ) {
      const retryCount = (error.config.retryCount || 0) + 1;
      error.config.retryCount = retryCount;
      if (retryCount <= MAX_RETRIES) {
        return Promise.reject(error);
        // return axios(error.config);
        // return new Promise((resolve, _) => {
        //   setTimeout(() => {
        //     resolve(axios(error.config));
        //   }, 10);
        // });
      } else {
        if (
          process.env.REACT_APP_MODE === "development" ||
          process.env.REACT_APP_MODE === "staging"
        ) {
          console.log("reason ==>>", error.response?.status);
        }
        window.location.href = "/login";
      }
    } else if (
      error.response?.status === 415 ||
      error.response?.status === 401
    ) {
      store.dispatch(resetState());
      console.log("reset auth");
    }
    return Promise.reject(error);
  }
);

const APICall = async (
  method = "post",
  endPoint = null,
  body = undefined,
  conf
) => {
  let config = {
    method: method.toLowerCase(),
  };
  if (endPoint) {
    config.url = endPoint;
  }
  if (body) {
    // const formData = new FormData();
    // formData.append(key, body[key]);

    config.data = JSON.stringify(body);
  }
  if (conf) {
    config = { ...config, ...conf };
  }

  return new Promise((resolve, reject) => {
    axiosInstance(config)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        Emitter.emit("errorStatus", error);
        if (error && error.response) {
          if (process.env.REACT_APP_MODE === "development") {
            console.log("resolve====>", error.response);
            // return <CheckError />;
          }
          // let newInitStandardProviders = makeFunctionCalledOnce(() =>
          if (error?.response.status === 401) {
            // updateToken(error);
          } else {
          }
          resolve(error.response);
          // )();
        } else {
          console.log("reject====>", error.message);
          reject(error);
        }
        if (error && error.message === "canceled") {
          // Handle the cancellation error silently
          return;
        }

        throw new Error(error);
      });
  });
};

export default APICall;
