import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";
import { HEADER_HEIGHT, NAVBAR } from "../../Helper/constant";
import { Layout } from "antd";

const activeStyles = css`
  &::after {
    content: "";
    position: absolute;
    bottom: 1px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: white;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }
`;
const activeStylesMobile = css`
  border: 1px solid black;
  color: white;
  border-radius: 10px;
  background-color: #565656;
`;
const activeStylesSubMenu = css`
  /* background-color: #e6f4ff; */
  color: #1677ff;
  border-radius: 10px;
`;
const NavbarStyle = {
  Wrapper: styled.nav`
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    background-color: ${NAVBAR.BACKGROUND_COLOR};
    align-self: flex-start;
    padding-inline: 2rem;
    height: ${NAVBAR.HEIGHT};
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  Logo: styled.h1`
    color: white;
    font-weight: normal;
    font-size: 2rem;
  `,
  Items: styled.div`
    height: 100%;
    display: flex;
    gap: 2rem;
    align-items: center;
  `,
  Item: styled(NavLink)`
    color: white;
    font-size: 1.4rem;
    font-weight: bold;
    height: ${NAVBAR.HEIGHT};
    cursor: pointer;
    text-decoration: none;
    padding-inline: 1rem;
    display: flex;
    gap: 1rem;
    align-items: center;
    position: relative;
    ${(props) => props.$active && !props.$isMobile && activeStyles}
    ${(props) =>
      props.$active && props.$isMobile && !props.$subMenu && activeStylesMobile}
    ${(props) => props.$active && props.$subMenu && activeStylesSubMenu}
  `,
  //dropdown from antd

  IconButton: styled.button`
    color: white;
    font-size: 1.4rem;
    padding: 0 1rem;
    cursor: pointer;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;

    &:focus {
      outline: none;
    }

    &:hover {
      opacity: 0.8;
    }
  `,
};
export const StyleHeader = styled(Layout.Header)`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${(props) => props.theme.token.colorTextLightSolid};
  height: ${HEADER_HEIGHT};
  background-color: ${(props) => props.theme.token.colorPrimary1};
  font-family: ${(props) => props.theme.token.fontFamily};
  padding-inline: 2rem;
  line-height: normal;

  @media (max-width: 500px) {
    padding-inline: 1rem;
  }
`;
export const DigitalClockContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    display: none;
  }

  /* border-radius: 1rem; */
`;
export const ClockBorder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid black;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid white;
`;
export const FlipDigitContainer = styled.div`
  position: relative;
  height: 30px;
  width: 20px;
  margin: 0 2px;
  /* overflow: hidden; */
`;
export const DigitSpan = styled.span`
  display: block;
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  transition: all 0.5s ease;
`;
export const TopDigit = styled(DigitSpan)`
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: bottom center;
  ${({ flip }) => flip && `transform: rotateX(-90deg);`}
`;
export const BottomDigit = styled(DigitSpan)`
  position: absolute;
  bottom: 0;
  left: 0;
  transform-origin: top center;
  ${({ flip }) => flip && `transform: rotateX(90deg);`}
`;
export const CurrentDigit = styled(DigitSpan)`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
`;
export const Colon = styled.div`
  margin: 0 4px;
`;
export default NavbarStyle;
