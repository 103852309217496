import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import ThemeCard from "../../components/ThemeCard";
import ThemeDatePicker from "../../components/ThemeDatePicker";
import { Col, Modal, Popconfirm, Row, notification } from "antd";
import ThemeDropDown from "../../components/ThemeDropDown";
import ThemeCardTable from "../../components/ThemeCardTable";
import { useDispatch } from "react-redux";
import { setSummaryDataFilter } from "../../store/slice/globleSlice";
import ActionButton from "../../components/ActionButton";
import ThemeInput from "../../components/ThemeInput";
import { formatToINR } from "../../components/FormateToINR/formatToINR";
import ModalCellView from "../../components/ModalCellView/ModalCellView";
import { valanBillDate } from "../../components/CommanFuction/CommanFunction";
import { UserLedgerButton } from "../../styles/commonStyle";
import { DeleteIcon, EditIcon } from "../Dashboard/style";
import { PdfDiv } from "../SummaryReport/style";
import { FilePdfOutlined } from "@ant-design/icons";

const EntryTypeOption = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Cash Entry",
    value: "cashEntry",
  },
  {
    label: "Bill Entry",
    value: "billEntry",
  },
];

const BillMarketOptions = [
  {
    label: "Total",
    value: "total",
  },
  {
    label: "Market Wise",
    value: "marketWise",
  },
];

const UserLedger = () => {
  const userId = useParams()?.id;
  // state
  const [ledgerData, setLedgerData] = useState([]);
  const [value, setValue] = useState({
    tradeAfter: "",
    entryType: "",
    marketType: "",
  });
  const [ledgerLoading, setLedgerLoading] = useState(false);
  const [showModal, setShowModal] = useState({ flag: false, record: null });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // life cycle
  useEffect(() => {
    getledgerData();
  }, []);

  // api call
  const getledgerData = async () => {
    setLedgerLoading(true);
    try {
      let response = await APICall(
        "get",
        `${EndPoints.getLedger}?clientId=${userId}`
      );
      if (response.status === 200) {
        setLedgerData(response?.data?.data?.entries);
      } else {
        notification.error({ message: response?.data?.message });
      }
    } catch (error) {
      notification.error({ message: error?.message });
    } finally {
      setLedgerLoading(false);
    }
  };
  const handleSubmit = () => {
    // console.log("value", value);
  };
  const deleteLedger = async (id) => {
    try {
      let response = await APICall(
        "delete",
        `${EndPoints.updateLedger}delete-ledger?ledgerId=${id}&recalculateLedgerBalance=false`
      );
      if (response.status === 200) {
        notification.success({ message: response?.data?.message });
        getledgerData();
      } else {
        notification.error({ message: response?.data?.message });
      }
    } catch (error) {
      notification.error({ message: error?.message });
    }
  };

  //function
  const handleDate = (date, string, name) => {
    setValue((pre) => ({ ...pre, [name]: date }));
  };
  const onChangeDropdown = (name, value, option) => {
    setValue((pre) => ({ ...pre, [name]: value[0] }));
  };
  const handleEdit = (record) => {
    setShowModal({ flag: true, record: record });
  };
  const handleCancel = () => {
    setShowModal({ flag: false, record: null });
  };

  // console.log("ledgerData", ledgerData);
  // column
  const columns = [
    ledgerLoading
      ? { title: "Sr No.", dataIndex: "date", key: "date", width: "5rem" }
      : {
          title: "Sr No.",
          dataIndex: "date",
          key: "date",
          width: "5rem",
          align: "center",
          render: (text, record, index) => {
            return <span>{index + 1}</span>;
          },
        },
    {
      title: "CLIENT INFO",
      dataIndex: "client",
      key: "client",
      align: "left",
      render: (text, record) => {
        return (
          !ledgerLoading && (
            <div style={{ textAlign: "left" }}>
              {`${record?.userId} (${record?.accountName})`}
            </div>
          )
        );
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "left",
      render: (text, record) => {
        const finalDate = valanBillDate(record?.date, true);
        return !ledgerLoading && <span>{finalDate}</span>;
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      align: "left",
    },
    {
      title: "Debit",
      dataIndex: "debit",
      key: "debit",
      align: "center",
      render: (text, record) => {
        if (record?.entryType === "debit") {
          return !ledgerLoading && <span>{formatToINR(record?.amount)}</span>;
        } else {
          return !ledgerLoading && "-";
        }
      },
    },
    {
      title: "Credit",
      dataIndex: "credit",
      key: "credit",
      align: "center",
      render: (text, record) => {
        if (record?.entryType === "credit") {
          return !ledgerLoading && <span>{formatToINR(record?.amount)}</span>;
        } else {
          return !ledgerLoading && "-";
        }
      },
    },
    {
      title: "Balance",
      dataIndex: "ledgerBalance",
      key: "ledgerBalance",
      align: "center",
      render: (text, record) => {
        return (
          !ledgerLoading && <div>{formatToINR(record?.ledgerBalance)}</div>
        );
      },
    },
    {
      title: "Remark",
      dataIndex: "remark",
      key: "remark",
      align: "left",
      render: (text, record) => {
        return !ledgerLoading && <div>{record?.remark}</div>;
      },
    },
    {
      title: "Bill",
      dataIndex: "bill",
      key: "bill",
      align: "center",
      render: (text, record) => {
        const showPDF = record?.type === "valanBill";
        return (
          !ledgerLoading && (
            <Row justify={"space-evenly"}>
              <PdfDiv>
                {showPDF ? (
                  <FilePdfOutlined
                    onClick={() => {
                      navigate(`/valanBill/${record?.userId}`);
                      dispatch(
                        setSummaryDataFilter({
                          withoutBrokerage: false,
                          valan: record?.valanId,
                        })
                      );
                    }}
                  />
                ) : (
                  "-"
                )}
              </PdfDiv>
              <EditIcon onClick={() => handleEdit(record)} />
              <Popconfirm
                title="delete the ledger"
                description="Are you sure to delete this ledger?"
                onConfirm={() => deleteLedger(record?._id)}
                placement="topRight"
                okText="Yes"
                cancelText="No"
              >
                <DeleteIcon />
              </Popconfirm>
            </Row>
          )
        );
      },
    },
  ];

  return (
    <>
      <ThemeCard>
        <Row gutter={[20, 20]} justify="left" align="bottom">
          <Col xs={24} sm={12} md={8} lg={6} xl={4}>
            <ThemeDatePicker
              title="Trade After"
              name="tradeAfter"
              placeholder="Select"
              onChange={(date, string) =>
                handleDate(date, string, "tradeAfter")
              }
              value={value?.tradeAfter}
            />
          </Col>

          <Col xs={24} sm={12} md={8} lg={6} xl={4}>
            <ThemeDropDown
              title="Entry Type"
              name="entryType"
              placeholder="Select"
              width={"100%"}
              options={EntryTypeOption}
              $onChange={onChangeDropdown}
              value={value.entryType}
            />
          </Col>

          <Col xs={24} sm={12} md={8} lg={6} xl={4}>
            <ThemeDropDown
              title="Bill Market Type"
              name="marketType"
              placeholder="Select"
              width={"100%"}
              options={BillMarketOptions}
              $onChange={onChangeDropdown}
              value={value?.marketType}
            />
          </Col>

          <Col xs={24} sm={12} md={0} lg={0} xl={8}>
            {/* Placeholder col to maintain spacing */}
          </Col>

          <Col xs={24} sm={12} md={5} lg={3} xl={3}>
            <UserLedgerButton
              type="primary"
              onClick={handleSubmit}
              style={{ width: "100%" }}
            >
              Find
            </UserLedgerButton>
          </Col>
        </Row>
      </ThemeCard>

      <ThemeCardTable
        buttonType={false}
        data={ledgerData}
        column={columns}
        headingStyle={false}
        isFilterTrue={true}
        isLoading={ledgerLoading}
      />
      {showModal.flag && (
        <EditLedger
          handleCancel={handleCancel}
          record={showModal?.record}
          getledgerData={getledgerData}
        />
      )}
    </>
  );
};

export default UserLedger;

export const EditLedger = ({ handleCancel, record, getledgerData }) => {
  // state
  const [data, setData] = useState({
    amount: record?.amount,
    remark: null,
  });
  const [loading, setLoading] = useState(false);

  // api call
  const handleSubmit = async () => {
    if (!data?.amount) {
      notification.error({ message: "Please fill amount before submit" });
      return;
    }
    setLoading(true);
    try {
      let response = await APICall(
        "PUT",
        `${EndPoints.updateLedger}edit-ledger/${record?._id}`,
        data
      );
      // console.log("response", response);
      if (response.status === 200) {
        notification.success({ message: response?.data?.message });
        getledgerData();
      } else {
        notification.error({ message: response?.data?.message });
      }
      handleCancel();
    } catch (error) {
      notification.error({ message: error?.message });
    } finally {
      setLoading(false);
    }
  };

  // function
  const onChangeValue = ({ target: { name, value } }) => {
    setData({ ...data, [name]: value });
  };

  return (
    <Modal
      title={
        <Row justify={"left"}>
          <Col span={12}>{`Edit Amount`}</Col>
          <Col span={10}>{`${record?.accountName} (${record?.userId})`}</Col>
        </Row>
      }
      open={true}
      onOk={handleCancel}
      onCancel={handleCancel}
      footer={false}
    >
      <ThemeCard bordered={false}>
        <Row
          justify={"space-around"}
          gutter={[10, 10]}
          // align={"middle"}
        >
          <Col span={8}>
            <ThemeInput
              marginBottom={"0rem"}
              width={"100%"}
              title="Amount"
              className="ThemeInput"
              extraProps={{
                input_type: "number",
              }}
              inputProps={{
                placeholder: "amount",
                name: "amount",
                value: data?.amount,
                onChange: onChangeValue,
              }}
            />
          </Col>
          <Col span={8}>
            <ThemeInput
              marginBottom={"0rem"}
              width={"100%"}
              title={`${record?.entryType === "credit" ? "Credit" : "Debit"}`}
              extraProps={{
                input_type: "number",
              }}
              inputProps={{
                // placeholder: "LOT",
                name: "lot",
                value: record?.amount,
                // onChange: onChangeValue,
                readOnly: "true",
                // onBlur: onChangeValue,
                // disabled: record?.marketName === "NSE EQU" ? true : false,
              }}
            />
          </Col>
          <Col span={8}>
            <ThemeInput
              marginBottom={"0rem"}
              width={"100%"}
              title="Balance"
              className="ThemeInput"
              extraProps={{
                input_type: "number",
              }}
              inputProps={{
                placeholder: "Balance",
                name: "balance",
                value: record?.ledgerBalance,
                // onChange: onChangeValue,
                // onBlur: onChangeValue,
                // disabled: record?.marketName !== "NSE FUT" ? true : false,
                readOnly: "true",
              }}
            />
          </Col>
        </Row>
        <Row justify={"center"} style={{ marginTop: "1rem" }}>
          <Col span={22}>
            <ThemeInput
              marginBottom={"0rem"}
              width={"100%"}
              title="Remark"
              className="ThemeInput"
              inputProps={{
                placeholder: "Remark",
                name: "remark",
                value: data?.remark,
                onChange: onChangeValue,
              }}
            />
          </Col>
        </Row>
        <Row
          justify={"center"}
          gutter={[20, 20]}
          style={{ marginTop: "2rem", gap: "2rem" }}
        >
          <ActionButton
            title={"Submit"}
            onClick={handleSubmit}
            loading={loading}
          />
          <ActionButton
            title={"Cancel"}
            buttonType="cancel"
            onClick={handleCancel}
          />
        </Row>
      </ThemeCard>
    </Modal>
  );
};
