import { Space, Typography, Button } from "antd";
import { useState } from "react";
import { styled } from "styled-components";

const StyledThemeInputButtonBase = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    & .ant-form-item-explain-error {
      width: ${(props) => props?.width};
      font-family: ${(props) => props.theme.token.fontFamily};
      font-size: 0.8rem;
      font-style: normal;
      font-weight: 400;
    }
  `,
  Button: styled(Button)`
    &.ant-btn {
      box-shadow: none;
      border-radius: ${(props) => props.theme.token.borderRadiusLG + "px"};
      font-family: ${(props) => props.theme.token.fontFamily};
      background-color: ${(props) =>
        props?.$active
          ? props.theme.token.colorPrimary
          : props.theme.token.colorBgBase};
      border: ${(props) =>
        `1px solid ${props.theme.token.colorTextPlaceholder}`};
      color: ${(props) =>
        props?.$active
          ? props.theme.token.colorBgBase
          : props.theme.token.colorTextLabel};
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      height: 3rem;
    }
  `,
  Title: styled(Typography.Title)`
    &.ant-typography {
      color: ${(props) => props.theme.token.colorTextLabel};
      font-family: ${(props) => props.theme.token.fontFamily};
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      margin-bottom: 0.5rem;
      line-height: 1.125rem;
      & .styled-drop-down-star {
        color: ${(props) => props.theme.token.red4};
        font-size: 1rem;
      }
    }
    &.sub-title {
      color: ${(props) => props.theme.token.colorTextLabel};
      font-family: ${(props) => props.theme.token.fontFamily};
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      margin-bottom: 0.5rem;
      line-height: 1.125rem;
    }
  `,
};

const ThemeInputButtonBase = (props) => {
  const [value, setValue] = useState(null);

  //functions
  const handleMenuClick = (value) => {
    setValue(value);
  };

  return (
    <StyledThemeInputButtonBase.Wrapper width={props?.width}>
      {props?.title || props?.subTitle ? (
        <Space
          style={{
            justifyContent: "space-between",
          }}
        >
          <StyledThemeInputButtonBase.Title>
            {props?.title}
            {props?.required ? (
              <span className="styled-drop-down-star">*</span>
            ) : null}
          </StyledThemeInputButtonBase.Title>
          <StyledThemeInputButtonBase.Title className="sub-title">
            {props?.subTitle}
          </StyledThemeInputButtonBase.Title>
        </Space>
      ) : null}
      <Space wrap>
        {props?.options?.map((item) => {
          return (
            <StyledThemeInputButtonBase.Button
              {...props}
              loading={props.loading && item.value === value}
              onClick={() => {
                props?.onClick(item);
                handleMenuClick(item.value);
              }}
              type={props?.type}
              key={item?.value}
              $active={props?.active === item?.value}
            >
              {item?.label}
            </StyledThemeInputButtonBase.Button>
          );
        })}
      </Space>
    </StyledThemeInputButtonBase.Wrapper>
  );
};

export default ThemeInputButtonBase;
