import { Form, InputNumber, Space, Typography } from "antd";
import { styled } from "styled-components";
import { amountToWordsINR } from "../../Helper/data";
import { useState } from "react";
import { SyncOutlined } from "@ant-design/icons";

const StyledThemeInputNumber = {
  Wrapper: styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    position: relative;
    & .ant-form-item-explain-error {
      width: ${(props) => props?.width};
      font-size: 0.8rem;
      font-style: normal;
      font-weight: 400;
    }
    .ant-input-group-addon {
      background-color: ${(props) => {
        return props?.$active
          ? props?.$activeColor || props.theme.token.colorPrimary
          : props?.$showSuffixBg
          ? props.theme.token.colorBgLayout
          : props.theme.token.colorBgBase;
      }};
      font-size: 1.25rem;
      padding-inline: 0.4rem;
      width: 2.65rem;
      overflow: hidden;
    }
    .ant-input-affix-wrapper {
      padding-block: 0rem;
    }
    .ant-input-prefix {
      font-size: 1.3rem;
    }
    .ant-form-item {
      margin-bottom: ${(props) =>
        props?.$marginBottom ? props?.$marginBottom : "1.5rem"};
    }
  `,
  Title: styled(Typography.Title)`
    &.ant-typography {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      color: ${(props) => props.theme.token.colorTextLabel};
      font-size: 1rem;
      font-style: normal;
      font-weight: ${(props) =>
        props?.$titleFontWeight ? props?.$titleFontWeight : 600};
      margin-bottom: 0.5rem;
      line-height: 1.125rem;
      & .styled-drop-down-star {
        color: ${(props) => props.theme.token.red4};
        font-size: 1rem;
      }
    }
    &.sub-title {
      color: ${(props) => props.theme.token.colorTextLabel};
      font-size: 0.9rem;
      font-style: normal;
      font-weight: 400;
      margin-bottom: 0.5rem;
      line-height: 1.125rem;
    }
    &.sub-title-2 {
      font-weight: 600;
    }
  `,
  InputNumber: styled(InputNumber)`
    width: 100%;
    border-radius: ${(props) => props.theme.token.borderRadiusLG + "px"};
    &.ant-input-number {
      font-size: 1rem !important;
      font-style: normal;
      font-weight: 400;
      height: 3rem;
    }
    & .ant-input-number-input {
      font-size: 1rem !important;
      font-style: normal;
      font-weight: 400;
      height: 3rem;
      @media (max-width: 1079px) {
        font-size: 0.8rem !important;
        height: 2.5rem;
      }
    }
  `,
};

export const converterFunction = (value) => {
  if (value || value === 0) {
    const [wholePart, decimalPart] = value?.toString().split(".");
    let formattedWholePart = wholePart
      .replace(/(\d)(?=(\d\d)+\d$)/g, "$1,")
      .replace(/^/, "");
    return decimalPart
      ? `${formattedWholePart}.${decimalPart}`
      : formattedWholePart;
  } else {
    return "";
  }
};

const ThemeInputNumber = (props) => {
  const [isCoin, setIsCoin] = useState(true);
  let showCoin = props?.extraProps?.hasOwnProperty("isCoin")
    ? props?.extraProps?.isCoin
    : isCoin;

  const form = Form.useFormInstance();
  const getValue = Form.useWatch(props?.formProps?.name, form);
  const onChangeValue = (value) => {
    props?.inputProps?.onChange &&
      props?.inputProps?.onChange({
        target: {
          name: props?.inputProps?.name,
          value: value,
          type: !showCoin ? "percentage" : "br",
          parent_key: props?.extraProps?.parent_key,
          nested_key: props?.extraProps?.nested_key,
        },
      });
  };

  const onClickAddonAfter = (e) => {
    if (props?.extraProps?.hasOwnProperty("onChangeType")) {
      props?.extraProps?.onChangeType(
        `${props?.inputProps?.name}_coin`,
        !showCoin
      );
    } else {
      setIsCoin(!showCoin);
    }
  };

  return (
    <StyledThemeInputNumber.Wrapper
      width={props?.width}
      $active={props?.active || undefined}
      $marginBottom={props?.marginBottom}
      $showSuffixBg={props?.showSuffixBg}
      $activeColor={props?.activeColor}
    >
      <Space
        style={{
          justifyContent: "space-between",
          ...props?.titleWrapperStyle,
        }}
      >
        {props?.title ? (
          <StyledThemeInputNumber.Title
            $titleFontWeight={props?.titleFontWeight}
          >
            {props?.title}
            {props?.required ? (
              <span className="styled-drop-down-star">*</span>
            ) : null}
            {props.isShowReset ? (
              <SyncOutlined
                onClick={props.handleResetField}
                style={{ marginLeft: "1rem" }}
              />
            ) : null}
          </StyledThemeInputNumber.Title>
        ) : null}
        {props?.subTitle ? (
          <StyledThemeInputNumber.Title className="sub-title">
            {props?.subTitle}
          </StyledThemeInputNumber.Title>
        ) : null}
      </Space>
      <Form.Item {...props.formProps}>
        <StyledThemeInputNumber.InputNumber
          width={"15rem"}
          min={0.0}
          // max={0.99}
          formatter={(value) => converterFunction(value)}
          step={0.01}
          value={props.value}
          {...props?.inputProps}
          onChange={onChangeValue}
          {...props?.extraProps}
          changeonwheel="true"
        />
      </Form.Item>
      <Space
        style={{
          justifyContent: "end",
        }}
      >
        <StyledThemeInputNumber.Title
          className="sub-title sub-title-2"
          title={
            props?.extraProps?.showConversion
              ? amountToWordsINR(
                  props?.extraProps?.conversionValue || getValue,
                  100000000000
                )
              : props?.subTitle2 || ""
          }
        >
          {props?.extraProps?.showConversion
            ? amountToWordsINR(
                props?.extraProps?.conversionValue || getValue,
                100000000000
              )
            : props?.subTitle2 || ""}
        </StyledThemeInputNumber.Title>
      </Space>
    </StyledThemeInputNumber.Wrapper>
  );
};

export default ThemeInputNumber;
