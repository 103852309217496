import { Row, Spin, notification } from "antd";
import ActionButton from "../../../../components/ActionButton";
import APICall from "../../../../APICall";
import {
  resetExpirySetting,
  setExprirySetting,
  setTabChange,
} from "../../../../store/slice/expirySettingSlice";
import {
  Container,
  SpinnerOverlay,
} from "../../../MarketSetting/MarketSetting";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyledTabs } from "../style";
import { SEGMENTS } from "../../../../Helper/constant";
import EndPoints from "../../../../APICall/EndPoints";
import { items } from "../../../ExpirySetting";
import { tabView } from "../../../ExpirySetting/style";

const ExpirySettingModal = ({ userId }) => {
  // status
  const [getLoading, setGetLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const dispatch = useDispatch();

  // redux
  const tab = useSelector((state) => state.expirySettingSlice?.tab);
  const expiryValue = useSelector((state) => state.expirySettingSlice?.[tab]);
  const view = tabView(tab);

  // life cycle
  useEffect(() => {
    return () => {
      dispatch(resetExpirySetting());
    };
  }, []);

  useEffect(() => {
    if (view && userId) {
      getExpirySetting();
    }
  }, [tab, userId]);

  useEffect(() => {
    if (!view && expiryValue?.scriptName && userId) {
      getExpirySetting();
    }
  }, [userId, expiryValue?.scriptName]);

  // api call
  const getExpirySetting = async () => {
    setGetLoading(true);
    let URL = view
      ? `${EndPoints.getExpirySetting}?userId=${userId}&&marketName=${SEGMENTS[tab]}`
      : `${EndPoints.getExpirySetting}?userId=${userId}&&marketName=${
          SEGMENTS[tab]
        }&&scriptName=${encodeURIComponent(expiryValue?.scriptName)}`;
    try {
      let response = await APICall("get", URL);
      if (response.status === 200) {
        // console.log("respnose", response);
        const info = response?.data?.data?.[0];
        dispatch(
          setExprirySetting({
            obj: { ...info, userId: userId },
            tab: info?.marketName,
          })
        );
      } else {
        notification.error({ message: response?.data?.message });
      }
    } catch (error) {
      notification.error({ message: error?.message });
    } finally {
      setGetLoading(false);
    }
  };

  const handleSubmit = async () => {
    let obj = { ...expiryValue, userId };

    if (view && !userId) {
      return notification.error({
        message: "Please select master admin before submit",
      });
    }
    if (!view && (!expiryValue.scriptName || !userId)) {
      return notification.error({
        message: `Please fill details of ${
          !userId ? "MasterAdmin" : "ScriptName"
        } before submit`,
      });
    }

    setSubmitLoading(true);
    try {
      let response = await APICall("post", EndPoints.addExpirySetting, obj);
      if (response.status === 200) {
        // console.log("expiryResponse", response);
        notification.success({ message: response?.data?.message });
        // dispatch(resetExpirySetting());
        // setValue("");
      } else {
        notification.error({ message: response?.data?.message });
      }
    } catch (error) {
      notification.error({ message: error?.message });
    } finally {
      setSubmitLoading(false);
    }
  };

  // function
  const onChange = (key) => {
    // console.log(key);
    dispatch(setTabChange(key));
  };

  return (
    <>
      <Container isloading={getLoading.toString()}>
        {getLoading && (
          <SpinnerOverlay>
            <Spin size="large" />
          </SpinnerOverlay>
        )}

        <StyledTabs
          defaultActiveKey="NSE FUT"
          items={items}
          onChange={onChange}
        />
        <Row align={"center"}>
          <ActionButton
            title={"Submit"}
            loading={submitLoading}
            onClick={handleSubmit}
          />
        </Row>
      </Container>
    </>
  );
};

export default React.memo(ExpirySettingModal);
