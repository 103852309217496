import { Space, Typography } from "antd";
import { styled } from "styled-components";

const StyledTheme = {
  Space: styled(Space)`
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0 !important;
    & .ant-form-item-explain-error {
      width: ${(props) => props?.width};
      font-size: 0.8rem;
      font-style: normal;
      font-weight: 400;
    }
  `,
  Title: styled(Typography.Title)`
    &.ant-typography {
      color: ${(props) => props?.color || props.theme.token.colorTextLabel};
      font-style: normal;
      font-size: ${(props) => (props?.$fontSize ? props?.$fontSize : "1rem")};
      font-weight: ${(props) =>
        props?.$titleFontWeight ? props?.$titleFontWeight : 600};
      line-height: 1.125rem;
      margin-bottom: 0;
      font-weight: 500;
      & .styled-drop-down-star {
        color: ${(props) => props.theme.token.red4};
        font-size: 1rem;
      }
    }
    &.secondary-title {
      color: ${(props) => props.theme.token.colorTextLabel};
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      /* margin-bottom: 0; */
      line-height: 1.125rem;
    }
  `,
};

const ThemeTextLabel = (props) => {
  return (
    <StyledTheme.Space
      style={{
        ...props?.titleWrapperStyle,
      }}
    >
      {props?.title ? (
        <StyledTheme.Title
          $fontSize={props?.fontSize}
          $titleFontWeight={props?.titleFontWeight}
          color={props?.color}
        >
          {props?.title}
          {props?.required ? (
            <span className="styled-drop-down-star">*</span>
          ) : null}
        </StyledTheme.Title>
      ) : null}
      {props?.secondaryTitle ? (
        <StyledTheme.Title className="secondary-title">
          {props?.secondaryTitle}
        </StyledTheme.Title>
      ) : null}
    </StyledTheme.Space>
  );
};

export default ThemeTextLabel;
