import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import store, { persistor } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ConfigProvider, theme } from "antd";
import StyledThemeProvider from "./components/StyledThemeProvider";
// import { makeServer } from "./server";
import { NotificationProvider } from "./components/Notification";
import { App as ContextApp } from "antd";
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
      cacheTime: 1000 * 60 * 60 * 1, // 24 hours
      refetchOnWindowFocus: false, // default: true
      refetchOnMount: false, // default: true
      // notifyOnChangeProps: [
      //   "isFetching",
      //   "isLoading",
      //   "isSuccess",
      //   "data",
      //   "error",
      // ],
      // staleTime: 60000,
      // refetchOnReconnect: false, // default: true
      // logger: {
      //   log: (...args) => {
      //     // Log debugging information
      //   },
      //   warn: (...args) => {
      //     // Log warning
      //   },
      //   error: (...args) => {
      //     // Log error
      //   },
      // },
    },
  },
});
export const themeConfig = {
  token: {
    ...theme.defaultAlgorithm,
    colorPrimary1: "#343e47",
    colorPrimary: "#465f7b",
    colorPrimaryLight: "rgba(255, 255, 255, 0.07)",
    // colorPrimaryBgHover: "#798378b0",
    colorPrimaryBgHover: "#7393B3",
    //text
    colorPrimaryText: "#1C2536",
    colorPrimaryTextActive: "#1C2536",
    colorPrimaryTextHover: "#1C2536",
    colorTextPlaceholder: "#C6C8CB",
    colorTextLabel: "#727880",
    colorBorder: "#EEE",
    fontFamily: "Poppins, sans-serif",

    //color
    colorFillContent: "#F6F6F6",
    colorSelectedTab: "#7982784d",

    //border
    borderRadiusLG: 10,

    //Button
    // colorBgActionButton: "#A5CB1C",
    buttonColor: "#465f7b",
    boxShadowNone: "none",
    buttonBackgroundColor: "#465f7b",

    //Table cell background color
    // marketWatchDown: "#bf2144",
    // marketWatchDown: "#ed4a29",
    marketWatchDown: "#ed4a29",
    marketWatchUp: "#34b622",
    tradeModalBuyColor: "#27611f",
    // marketWatchUp: "blue",
    // marketWatchUp: "#1dba93 ",
  },
  components: {
    Radio: {
      // colorPrimary: "#A5CB1C",
    },
    Checkbox: {
      colorPrimary: "#A5CB1C",
      colorPrimaryHover: "#A5CB1C",
      colorBgLightGrey: "#F5F5F5",
    },
  },
};
// xs	screen < 576px
// sm	screen ≥ 576px,
// md	screen ≥ 768px,
// lg	screen ≥ 992px,
// xl	screen ≥ 1200px,
// xxl	screen ≥ 1600px,

// if (
//   process.env.REACT_APP_MODE === "development" ||
//   process.env.REACT_APP_MODE === "staging"
// ) {
//   makeServer();
// }
ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <PersistGate persistor={persistor} loading={null}>
      <QueryClientProvider client={queryClient}>
        {process.env.REACT_APP_MODE === "development" && (
          <ReactQueryDevtools initialIsOpen={false} position={"bottom-left"} />
        )}
        <ConfigProvider theme={themeConfig}>
          <ContextApp
            notification={{
              placement: "top",
              duration: 3,
            }}
          >
            <StyledThemeProvider>
              <NotificationProvider>
                <BrowserRouter basename={"/"}>
                  <App />
                </BrowserRouter>
              </NotificationProvider>
            </StyledThemeProvider>
          </ContextApp>
        </ConfigProvider>
      </QueryClientProvider>
    </PersistGate>
  </Provider>
);
// const Latest_Transactions_All = useQuery({
//   queryKey: [
//     "transactions",
//     {
//       page: 1,
//       page_size: 10,
//       apiType: "Latest_Transactions_All",
//     },
//   ],
//   queryFn: ({ signal }) => {
//     let params = {
//       config: {
//         signal: signal,
//       },
//       page: 1,
//       page_size: 10,
//       order_by: "-due_date",
//       is_reconciled: false,
//     };

//     const result = getTransactionByParams(params);
//     if (result) {
//       return result;
//     }
//   },
//   cacheTime: 60000,
//   backgroundFetch: true,
//   enabled: true,
//   priority: 3,
// });
