import { Button } from "antd";
import Table from "rc-table";
import styled from "styled-components";

export const TextLeftDiv = styled.div`
  text-align: left;
`;
export const StyledButton = styled(Button)`
  border-radius: ${(props) => props.theme.token?.borderRadiusLG};
  font-size: 1rem;
  background-color: ${(props) => props.theme.token?.colorBgActionButton};
  box-shadow: ${(props) => props.theme.token?.boxShadowNone};
  margin-right: 2rem;
  height: 3rem;
  width: 100%;
  @media (max-width: 1079px) {
    height: 2.5rem;
    font-size: 0.8rem;
  }
`;
export const UserLedgerButton = styled(Button)`
  border-radius: ${(props) => props.theme.token.borderRadiusLG + "px"};
  font-size: 1.1rem;
  height: 3rem;
  /* background-color: ${(props) => props.theme.token}; */
  box-shadow: ${(props) => props.theme.token.boxShadowNone};
  width: 98%;
  color: white;
  @media (max-width: 1079px) {
    font-size: 0.8rem;
    height: 2.5rem;
  }
`;
export const UserListButtonexport = styled(Button)`
  background-color: ${(props) => props.theme.token.buttonBackgroundColor};
  color: white !important;
`;
export const StyTable = styled(Table)`
  transform: none !important;
  & .ant-table-thead tr .ant-table-cell {
    background-color: ${(props) => props.theme.token.colorPrimary};
    color: ${(props) => props.theme.token.colorTextLightSolid};
    font-size: 0.9rem;
    font-weight: 500;
  }
  @media screen and (max-width: 1079px) {
    .ant-table-thead tr .ant-table-cell {
      font-size: 0.75rem;
    }
  }
  .ant-table-thead tr .ant-table-cell::before {
    display: none;
  }

  .ant-table-thead tr .ant-table-cell .ant-table-column-has-sorters {
    border-top: ${(props) =>
      `1px solid ${props.theme.token.colorBorder} !important`};
  }
  .ant-table-body .ant-table-cell {
    border-bottom: 0px;
  }

  .ant-table-wrapper .ant-table-tbody > tr > td {
    padding: 0.5rem !important;
  }
  .ant-table-thead tr th {
    height: 2rem !important;
    padding: 0rem 0.5rem !important;
  }
  .collapseTable .ant-collapse-expand-icon span svg {
    fill: ${(props) => props.theme.token.colorTextLightSolid} !important;
  }
  .collapseTable .ant-collapse-header-text {
    color: ${(props) => props.theme.token.colorTextLightSolid};
  }

  .ant-switch-checked .ant-switch-inner {
    background-color: ${(props) =>
      props.theme.token.colorBgActionButton} !important;
  }

  .ant-table-thead {
    height: 3.18rem;
  }

  .ant-table-thead .ant-table-cell {
    &:hover {
      background: ${(props) =>
        props.theme.token.colorPrimaryBgHover} !important;
    }
  }
  .ant-table-body .ant-table-cell {
    padding: 0.5rem !important;
  }

  .ant-table-tbody .ant-table-row:hover {
    background: #f5f5f5;
  }
`;
