import { Input, Typography } from "antd";
import styled from "styled-components";

export const StyledThemeInput = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    width: ${(props) => (props?.wrapperwidth ? props?.wrapperwidth : "auto")};
    position: relative;
    & .ant-form-item-explain-error {
      width: ${(props) => props?.width};
      font-size: ${(props) => (props?.errortext ? props?.errortext : "0.8rem")};
      font-style: normal;
      font-weight: 400;
      /* line-height: 1; */
      margin-bottom: ${(props) =>
        props?.errorheight ? props?.errorheight : "0rem"};
    }
    .ant-input-group-addon {
      background-color: ${(props) => {
        return props?.$active
          ? props?.$activeColor || props.theme.token.colorPrimary
          : props?.$showSuffixBg
          ? props.theme.token.colorBgLayout
          : props.theme.token.colorBgBase;
      }};
      font-size: 1.25rem;
      padding-inline: 0.4rem;
      width: 2.65rem;
      overflow: hidden;
    }
    .ant-input-affix-wrapper {
      padding-block: 0rem;
    }
    .ant-input-prefix {
      font-size: 1.3rem;
    }
    .ant-form-item {
      margin-bottom: ${(props) =>
        props?.$marginBottom ? props?.$marginBottom : "1.5rem"};
    }
  `,
  Title: styled(Typography.Title)`
    &.ant-typography {
      color: ${(props) => props.theme.token.colorTextLabel};
      font-size: 1rem;
      font-style: normal;
      font-weight: ${(props) =>
        props?.$titleFontWeight ? props?.$titleFontWeight : 600};
      margin-bottom: 0.5rem;
      line-height: 1.125rem;
      & .styled-drop-down-star {
        color: ${(props) => props.theme.token.red4};
        font-size: 1rem;
      }
    }
    &.sub-title {
      color: ${(props) => props.theme.token.colorTextLabel};
      font-size: 0.9rem;
      font-style: normal;
      font-weight: 400;
      margin-bottom: 0.5rem;
      line-height: 1.125rem;
    }
    &.sub-title-2 {
      font-weight: 600;
    }
  `,
  Input: styled(Input)`
    width: ${(props) => props.width};
    border-radius: ${(props) => props.theme.token.borderRadiusLG + "px"};
    &.ant-input {
      font-size: 1rem !important;
      font-style: normal;
      font-weight: 400;
      height: 3rem;
    }
    & .ant-input {
      font-size: 1rem !important;
      font-style: normaimport default from "../../hooks/HOC/WithMemo";
      font-weight: 400;
      height: 3rem;
      @media (max-width: 1079px) {
        font-size: 0.8rem !important;
        height: 2.5rem;
      }
    }
  `,
};

export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
`;

export const AnimatedContent = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: transform 0.3s ease-in-out;
  transform: translateX(${(props) => (props?.$showImage ? "-115%" : "22%")});
`;

export const ToggleImage = styled.img`
  width: 2rem;
  height: 2rem;
  margin-left: 1rem;
`;

export const TogglePercentage = styled.span`
  font-size: 1.25rem;
`;
