import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  brokerList: [],
  brokersids: [],
  brokerInitialIds: [],
  brokerInitiaData: [],
};

export const brokaerForUsersFormSlice = createSlice({
  name: "brokaerForUsersFormSlice",
  initialState,
  reducers: {
    setBrokerList: (state, action) => {
      // console.log("action", action.payload);
      state.brokerList = action.payload;
      state.brokersids = action.payload.map((item) => item.value);
    },
    setBrokerInitialIds: (state, action) => {
      state.brokerInitialIds = action.payload.map((item) => item.value);
    },
    setBrokerInitislData: (state, action) => {
      state.brokerInitiaData = action.payload;
    },
  },
});

export const { setBrokerList, setBrokerInitialIds, setBrokerInitislData } =
  brokaerForUsersFormSlice.actions;

export default brokaerForUsersFormSlice.reducer;
