import { Col, Row, Spin, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setExprirySetting } from "../../store/slice/expirySettingSlice";
import {
  ExpirySettingRow,
  ExpirySquareUpRow,
  Label,
  NewPositionRow,
} from "./style";
import { SEGMENTS, optionDays, options } from "../../Helper/constant";
import ThemeDropDown from "../../components/ThemeDropDown";
import ThemeCard from "../../components/ThemeCard";
import { useEffect, useState } from "react";
import EndPoints from "../../APICall/EndPoints";
import APICall from "../../APICall";

const NseOptionRender = ({ tab }) => {
  // state
  const [getLoading, setGetLoading] = useState(false);
  const [script, setScript] = useState([]);
  const dispatch = useDispatch();

  // redux
  const expiryValue = useSelector((state) => state.expirySettingSlice[tab]);
  const userId = expiryValue?.userId;
  const ScriptName = expiryValue?.scriptName;

  // life cycle
  useEffect(() => {
    if (tab === "COMEX" || tab === "MCX" || tab === "MCX OPT") {
      getScript(SEGMENTS[tab]);
    }
  }, [tab]);

  const getScript = async (name) => {
    setGetLoading(true);
    try {
      let response = await APICall("get", `${EndPoints.getScriptList}${name}`);
      // console.log("response", response.data.data);
      if (response.status === 200 && response.data) {
        const scriptNameOptions = response.data?.data?.map((item, index) => {
          return {
            value: item.symbol,
            label: item.symbol,
            key: index,
          };
        });
        setScript(scriptNameOptions);
      } else {
        notification.error({ message: response?.data?.message });
      }
    } catch (error) {
      notification.error({ message: error?.message });
    } finally {
      setGetLoading(false);
    }
  };

  const getExpirySetting = async (script) => {
    setGetLoading(true);
    try {
      let response = await APICall(
        "get",
        `${EndPoints.getExpirySetting}?userId=${userId}&&marketName=${SEGMENTS[tab]}&&scriptName=${script}`
      );
      if (response.status === 200) {
        // console.log("respnose", response);
        const info = response?.data?.data?.[0];
        dispatch(
          setExprirySetting({
            obj: { ...info, userId: userId },
            tab: info?.marketName,
          })
        );
      } else {
        notification.error({ message: response?.data?.message });
      }
    } catch (error) {
      notification.error({ message: error?.message });
    } finally {
      setGetLoading(false);
    }
  };

  // function
  const handleChange = (name, selectedValue, selectedOptions) => {
    if (name === "squareUpRate") {
      dispatch(
        setExprirySetting({
          key: "squareUpRate",
          value: selectedValue[0],
          tab: tab,
          parent_key: "intraday",
        })
      );
    } else if (name === "rate" || name === "dayBefore") {
      dispatch(
        setExprirySetting({
          key: [name],
          value: selectedValue[0],
          tab: tab,
          parent_key: "position",
          nested_key: "expirySquareUpRateAndDayBefore",
        })
      );
    } else if (
      name === "valanEndingSquareUpRate" ||
      name === "newPositionStartBeforeDay"
    ) {
      dispatch(
        setExprirySetting({
          key: [name],
          value: selectedValue[0],
          tab: tab,
          parent_key: "position",
        })
      );
    } else if (name === "scriptName") {
      // console.log("name", name, selectedValue[0]);
      dispatch(
        setExprirySetting({
          key: [name],
          value: selectedValue[0],
          tab: tab,
        })
      );
    }
  };

  return (
    <>
      <Row>
        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
          <ThemeDropDown
            title="Script Name"
            placeholder="Select"
            name="scriptName"
            width={"98%"}
            showSearch={true}
            options={
              tab !== "NSE OPT" ? script : options.user.Expiry.NSE_OPTIONS
            }
            $onChange={handleChange}
            $value={expiryValue?.scriptName}
            suffixIcon={<Spin size="small" spinning={getLoading} />}
          />
        </Col>
      </Row>
      <ThemeCard
        title="INTRADAY"
        bordered={false}
        style={{ marginTop: "1rem" }}
      >
        <ExpirySettingRow>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Label>Intraday Square-Up Rate</Label>
          </Col>
          <Col xs={24} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Quotation Rate"
              name="squareUpRate"
              width={"98%"}
              options={options.user.Expiry.MCX_OPTION}
              $onChange={handleChange}
              $value={expiryValue.intraday?.squareUpRate}
            />
          </Col>
        </ExpirySettingRow>
      </ThemeCard>
      <ThemeCard
        title="POSITION"
        bordered={false}
        style={{ marginTop: "2rem" }}
      >
        <ExpirySquareUpRow gutter={[10, 10]}>
          <Col xs={24} sm={24} md={12} lg={10} xl={6}>
            <Label>Expiry Square-Up Rate And Day Before</Label>
          </Col>
          <Col xs={12} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Quotation Rate"
              name="rate"
              width={"98%"}
              options={options.user.Expiry.MCX_OPTION}
              $onChange={handleChange}
              $value={
                expiryValue.position?.expirySquareUpRateAndDayBefore?.rate
              }
            />
          </Col>
          <Col xs={12} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Select Days"
              name="dayBefore"
              width={"98%"}
              options={optionDays.slice(0, 31)}
              $onChange={handleChange}
              $value={
                expiryValue.position?.expirySquareUpRateAndDayBefore?.dayBefore
              }
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={10} xl={5}>
            <Label>Valan Ending Square-Up Rate </Label>
          </Col>
          <Col xs={24} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Closing Rate"
              name="valanEndingSquareUpRate"
              width={"98%"}
              options={options.user.Expiry.MCX_OPTION}
              $onChange={handleChange}
              $value={expiryValue.position?.valanEndingSquareUpRate}
            />
          </Col>
        </ExpirySquareUpRow>
        <NewPositionRow>
          <Col xs={24} sm={24} md={12} lg={10} xl={6}>
            <Label>New Position Start Before Day</Label>
          </Col>
          <Col xs={24} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Select Days"
              name="newPositionStartBeforeDay"
              width={"98%"}
              options={optionDays.slice(0, 31)}
              $onChange={handleChange}
              $value={expiryValue.position?.newPositionStartBeforeDay}
            />
          </Col>
        </NewPositionRow>
        {/* <Row style={{ justifyContent: "center", marginTop: "2rem" }}>
            <Button type="primary" style={style.submitBtn} onClick={handleSubmit}>
              SAVE
            </Button>
          </Row> */}
      </ThemeCard>
    </>
  );
};

export default NseOptionRender;
