import React, { useEffect, useRef, useState } from "react";
import UserFilterComponant from "../../components/UserFilterComponant/UserFilterComponant";
import ThemeCard from "../../components/ThemeCard";
import { Button, Col, Modal, Pagination, Row, Table, notification } from "antd";
import styled from "styled-components";
import ThemeCardTable from "../../components/ThemeCardTable";
import { StyledButton } from "../../styles/commonStyle";
import { useQuery } from "@tanstack/react-query";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import { valanBillDate } from "../../components/CommanFuction/CommanFunction";
import useGetQueryParams from "../../hooks/useGetQueryParams";
import { useDispatch, useSelector } from "react-redux";
import { setUserClearFilter } from "../../store/slice/globleSlice";

const StyTable = styled(Table)`
  transform: none !important;
  & .ant-table-thead tr .ant-table-cell {
    background-color: ${(props) => props.theme.token.colorPrimary};
    color: ${(props) => props.theme.token.colorTextLightSolid};
    font-size: 0.9rem;
    font-weight: 500;
  }
  @media screen and (max-width: 1079px) {
    .ant-table-thead tr .ant-table-cell {
      font-size: 0.75rem;
    }
  }
  .ant-table-thead tr .ant-table-cell::before {
    display: none;
  }

  .ant-table-thead tr .ant-table-cell .ant-table-column-has-sorters {
    border-top: ${(props) =>
      `1px solid ${props.theme.token.colorBorder} !important`};
  }
  .ant-table-body .ant-table-cell {
    border-bottom: 0px;
  }

  .ant-table-wrapper .ant-table-tbody > tr > td {
    padding: 0.5rem !important;
  }
  .ant-table-thead tr th {
    height: 2rem !important;
    padding: 0rem 0.5rem !important;
  }
  .collapseTable .ant-collapse-expand-icon span svg {
    fill: ${(props) => props.theme.token.colorTextLightSolid} !important;
  }
  .collapseTable .ant-collapse-header-text {
    color: ${(props) => props.theme.token.colorTextLightSolid};
  }

  .ant-switch-checked .ant-switch-inner {
    background-color: ${(props) =>
      props.theme.token.colorBgActionButton} !important;
  }

  .ant-table-thead {
    height: 3.18rem;
  }

  .ant-table-thead .ant-table-cell {
    &:hover {
      background: ${(props) =>
        props.theme.token.colorPrimaryBgHover} !important;
    }
  }
  .ant-table-body .ant-table-cell {
    padding: 0.5rem !important;
  }

  .ant-table-tbody .ant-table-row:hover {
    background: #f5f5f5;
  }

  .ant-table-tbody .highlighted-row {
    background-color: #edecfa;
  }
`;

const EditButton = styled(Button)`
  border-radius: ${(props) => props.theme.token?.borderRadiusLG};
  font-size: 1rem;
  background-color: ${(props) => props.theme.token?.colorPrimary};
  box-shadow: ${(props) => props.theme.token?.boxShadowNone};
  color: white;

  /* margin-right: 2rem; */
  /* height: 3rem; */
  /* width: 100%; */
`;

let converterObj = {
  basicInfo: "Basic Info",
  accountDetails: "Account Details",
  additionalInfo: "Additional Info",
  brokerageDetails: "Brokerage Details",
  margin: "Margin",
  scriptWiseInfo: "Script Wise Info",
  additionalSharing: "Additional Sharing",
};

let ItemsArray = [
  "basicInfo",
  "accountDetails",
  "additionalInfo",
  "brokerageDetails",
  "margin",
  "scriptWiseInfo",
  "additionalSharing",
];

const UserEditLog = () => {
  const valueRef = useRef({});
  const [showModal, setShowModal] = useState({
    flag: false,
    record: null,
    name: null,
  });
  const [totalCounts, setTotalCounts] = useState(0);
  const dispatch = useDispatch();
  const { params, setSearchParams } = useGetQueryParams();

  // redux
  const loginId = useSelector((state) => state.authSlice?.userDetail?.userId);

  // life cycle
  useEffect(() => {
    if (!params?.userId) {
      setSearchParams({ userId: loginId });
    }
  }, [params]);

  // api call
  const getUserEditLog = async () => {
    let response = await APICall(
      "get",
      `${EndPoints.getUserEditLog}?userId=${params?.userId || loginId}&page=${
        params?.page || 1
      }&&limit=${params?.limit || 10}`
    );
    setTotalCounts(response?.data?.data?.totalItems);
    return response?.data?.data;
  };

  const { isLoading, error, data } = useQuery({
    queryKey: ["userEditLog", params],
    queryFn: getUserEditLog,
    enabled: Object.keys(params)?.length > 0,
  });

  if (error) {
    notification.error({ message: error?.message });
  }

  // function
  const handleEdit = (value, name) => {
    let valueData = value?.map((el) =>
      el?.key === "Expiry Date"
        ? {
            ...el,
            new: valanBillDate(el?.new),
            old: valanBillDate(el?.old),
          }
        : el
    );
    setShowModal({ flag: true, record: valueData, name: name });
  };
  const handleCancel = () => {
    setShowModal({ flag: false, record: null, name: null });
  };

  const handleSearch = () => {
    let userId = valueRef.current?.userId;
    if (userId) {
      setSearchParams({ userId, page: 1 });
    }
  };

  const onChangePage = (page) => {
    setSearchParams({ ...params, page: page });
  };

  const handleReset = () => {
    valueRef.current = {};
    dispatch(setUserClearFilter(true));
    setSearchParams({ userId: loginId });
  };

  // column
  const columns = [
    {
      title: "CLIENT",
      dataIndex: "client",
      key: "client",
      align: "left",
      width: "5rem",
      render: (text, record) => {
        return <span>{`${record?.accountName} (${record?.userId})`}</span>;
      },
    },
    {
      title: "CHANGE LOGS",
      dataIndex: "logs",
      key: "logs",
      align: "left",
      width: "20rem",
      render: (text, record) => {
        return (
          <div style={{ display: "flex", gap: "1rem" }}>
            {ItemsArray?.filter((item) => item in record)?.map((item) => {
              return (
                <EditButton
                  key={item}
                  type="primary"
                  onClick={() => handleEdit(record[item] || [], item)}
                >
                  {converterObj[item]}
                </EditButton>
              );
            })}
          </div>
        );
      },
    },
    {
      title: "TIME",
      dataIndex: "time",
      key: "time",
      width: "5rem",
      align: "left",
      render: (text, record) => {
        const time = valanBillDate(record?.updateTime, true);
        return <div>{time}</div>;
      },
    },
    {
      title: "Updated By",
      dataIndex: "updatedBy",
      key: "updatedBy",
      width: "5rem",
      align: "left",
      render: (text, record) => {
        return (
          <div>{`${record?.updatedBy?.accountName}(${record?.updatedBy?.userId})`}</div>
        );
      },
    },
    {
      title: "IP",
      dataIndex: "ipAddress",
      key: "ip",
      width: "5rem",
      align: "left",
    },
  ];

  const generateRecordColumns = (record) => {
    let baseColumns = [
      {
        title: "Key",
        dataIndex: "key",
        key: "client",
        align: "left",
      },
      {
        title: "Old Value",
        dataIndex: "old",
        key: "oldValue",
        align: "left",
      },
      {
        title: "New Value",
        dataIndex: "new",
        key: "newValue",
        align: "left",
      },
    ];

    if (record && record[0]?.marketName) {
      baseColumns.unshift({
        title: "Market Name",
        dataIndex: "marketName",
        key: "marketName",
        align: "left",
      });
    }

    return baseColumns;
  };

  return (
    <>
      <ThemeCard>
        <Row gutter={[20, 20]}>
          <UserFilterComponant valueRef={valueRef} />
        </Row>
        <Row style={{ marginTop: "3rem" }} gutter={[10, 10]}>
          <Col xs={24} sm={12} md={8} lg={3} xl={2}>
            <StyledButton type="primary" onClick={handleSearch}>
              Search
            </StyledButton>
          </Col>
          <Col xs={24} sm={12} md={8} lg={3} xl={2}>
            <StyledButton type="primary" onClick={handleReset}>
              Reset
            </StyledButton>
          </Col>
        </Row>
      </ThemeCard>

      <ThemeCardTable
        column={columns}
        buttonType={false}
        data={data?.data}
        isLoading={isLoading}
        filtertype="editLogs"
      />
      <Row align={"center"}>
        <Pagination
          size="large"
          showSizeChanger={false}
          current={params?.page || 1}
          pageSize={params?.limit || 10}
          total={totalCounts}
          onChange={onChangePage}
        />
      </Row>
      <Modal
        title={converterObj[showModal?.name]}
        open={showModal?.flag}
        onCancel={handleCancel}
        footer={false}
        width={1200}
      >
        <StyTable
          className="userTable"
          bordered
          columns={generateRecordColumns(showModal?.record)}
          dataSource={showModal?.record}
          pagination={false}
          rowKey="_id"
          scroll={{
            x: 600,
          }}
          rowClassName={(record) =>
            record.key === "Script Name" || record.key === "Broker Id"
              ? "highlighted-row"
              : ""
          }
        />
      </Modal>
    </>
  );
};

export default UserEditLog;
