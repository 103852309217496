import { createSlice } from "@reduxjs/toolkit";

const initalAdditionalSharing = {
  tvChannel: true,
  ipAddressReport: true,
  acceptRejectedTrade: true,
  restoreAutoSquarUp: true,
  editTrade: true,
  newClientAdd: true,
  clientDelete: true,
  offlineTrade: true,
  tradeDelete: true,
  bfCfEdit: true,
  ledgerDelete: true,
  scriptBlocksList: true,
  additionalSharing: true,
  scriptWiseSettings: true,
  broadcastMsg: true,
  marginExamtion: true,
  valanDelete: true,
  accountPointEntry: true,
  limitBetweenHighAndLow: true,
};

const initialState = {
  accessToken: null,
  user: null,
  userDetail: null,
  MarketAccessDetails: null,
  additionalSharing: {},
};

export const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    resetState: () => ({ ...initialState }),
    setAuth(state, action) {
      state.accessToken = action.payload;
    },
    setUserDetail(state, action) {
      state.userDetail = action.payload;
    },
    setMarketAccess(state, action) {
      state.MarketAccessDetails = action.payload;
    },
    setUserIP(state, action) {
      state.user = { userIP: action.payload };
    },
    setAdditionalSharing(state, action) {
      const value = action.payload;
      const AccountType = value.accountType;
      if (AccountType !== "owner") {
        for (let key in value) {
          if (value[key] !== "allow") {
            state.additionalSharing[key] = false;
          } else {
            state.additionalSharing[key] = true;
          }
        }
      } else {
        state.additionalSharing = initalAdditionalSharing;
      }
    },
  },
});

export const {
  resetState,
  setAuth,
  setUserDetail,
  setMarketAccess,
  setUserIP,
  setAdditionalSharing,
} = authSlice.actions;
export default authSlice.reducer;
