import dayjs from "dayjs";
import { setMarketValidationErrors } from "../store/slice/globleSlice";
const _ = require("lodash");

export const filterOption = (input, option) =>
  (option?.name ?? "").toLowerCase().includes(input.toLowerCase());

export const makeFunctionCalledOnce = function (fn) {
  let called = false;
  return function (...args) {
    if (!called) {
      called = true;
      fn.call(this, ...args);
    }
  };
};

export const removeCache = () => {
  if ("caches" in window) {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  }
};

export const isScriptLive = ({ Exchange, _lastUpdatedDate, timer = 30000 }) => {
  const now = new Date();
  let isActive = false;
  let isBetweenTimes = false;

  if (Exchange === "MCX") {
    const startTime = new Date();
    startTime.setHours(9, 1, 0, 0);

    const endTime = new Date();
    endTime.setHours(23, 30, 0, 0);

    isBetweenTimes = now >= startTime && now <= endTime;
  } else {
    const startTime = new Date();
    startTime.setHours(9, 15, 0, 0);

    const endTime = new Date();
    endTime.setHours(15, 15, 0, 0);

    isBetweenTimes = now >= startTime && now <= endTime;
  }
  if (isBetweenTimes) {
    const timestamp1 = dayjs(_lastUpdatedDate);
    const timestamp2 = dayjs(Date.now());
    const diff = timestamp2.diff(timestamp1, "millisecond");
    isActive = diff <= timer;
  }
  // return true;
  return isActive && isBetweenTimes;
};

export const getInstrumentIdentifier = ({
  market,
  instrument_type,
  script_name,
  expiry_date,
  option_type,
  strike_price,
}) => {
  let text = "";
  if (instrument_type) {
    text = text + `${instrument_type}_`;
  }
  if (script_name) {
    text = text + `${script_name}`;
  }
  if (expiry_date) {
    text = text + `_${expiry_date}`;
  }
  if (
    script_name &&
    (market === "NSE FUT" ||
      market === "NSE OPT" ||
      market === "MCX OPT" ||
      market === "FOREX")
  ) {
    text = text + `_${!!option_type ? option_type : "XX"}_`;
    text = text + `${!!strike_price ? strike_price : "0"}`;
  }
  if (script_name && market === "MCX") {
    text = text + "__0";
  }

  return text;
};

export const getInstrumentIdentifierTrueData = ({
  market,
  instrument_type,
  instrument_type_alias,
  script_name,
  expiry_date,
  option_type,
  strike_price,
}) => {
  let text = "";
  if (script_name) {
    text = text + `${script_name}`;
  }
  if (expiry_date) {
    text =
      text +
      `${expiry_date ? dayjs(expiry_date).format("YYMMM").toUpperCase() : ""}`;
  }
  if (instrument_type) {
    text = text + `${instrument_type.slice(0, 3)}`;
  }

  // if (
  //   script_name &&
  //   (market === "NSE FUT" ||
  //     market === "NSE OPT" ||
  //     market === "MCX OPT" ||
  //     market === "FOREX")
  // ) {
  //   text = text + `_${!!option_type ? option_type : "XX"}_`;
  //   text = text + `${!!strike_price ? strike_price : "0"}`;
  // }
  // if (script_name && market === "MCX") {
  //   text = text + "__0";
  // }

  return text;
};

export const handleMarketValidation = _.debounce((form, dispatch) => {
  let selectedMarket = {
    "NSE FUT": false,
    "NSE OPT": false,
    "NSE EQU": false,
    MCX: false,
    "MCX OPT": false,
    FOREX: false,
    COMEX: false,
    GLOBEX: false,
  };
  let fieldsError = form.getFieldsError();
  for (let i = 0; i < fieldsError.length; i++) {
    let errors = fieldsError[i];
    let marketName = errors.name[0].split("$");

    if (
      marketName[1] &&
      !selectedMarket[marketName[1]] &&
      errors.errors.length > 0
    ) {
      selectedMarket[marketName[1]] = true;
    }
  }
  dispatch(setMarketValidationErrors(selectedMarket));
}, 1000);
