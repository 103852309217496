import { useSelector } from "react-redux";
import { LeftRow, ProfilCol, RightRow, RowDiv, StyleDiv } from "../style";
import { Col, Form, InputNumber } from "antd";
import AccountDetailsRadioView from "../AccountDetailsRadioView";
import ThemeTextLabel from "../../../../components/ThemeTextLabel";
import ThemeInputNumber from "../../../../components/ThemeInputNumber/ThemeInputNumber";
import dayjs from "dayjs";
import ThemeInput from "../../../../components/ThemeInput";
import ThemeTimePicker from "../../../../components/ThemeTimePicker";
import { compareObj } from "../../../../Helper/constant";
import React from "react";

const CustomerView = React.memo(({ is_edits, parentForm }) => {
  //redux
  const parentSetting = useSelector(
    (state) => state.authSlice?.MarketAccessDetails?.additional
  );
  const m2m = Form.useWatch("isM2MAlert", parentForm);
  const autoStop = Form.useWatch("isAutoStopNewPosition", parentForm);
  const applayAuto = Form.useWatch("isApplyAutoSquareup", parentForm);
  const squareUP = Form.useWatch("isNewPositionSquareupTime", parentForm);
  const freshLimit = Form.useWatch("isFreshLimitAllow", parentForm);
  const time = Form.useWatch("newPositionSquareupTime", parentForm);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (!value) {
      compareObj[name]?.forEach((el) => {
        // console.log("el", el);
        // parentForm.setFieldValue(el, 0);
        parentForm.setFields([
          {
            name: el,
            value: null,
            touched: true,
          },
        ]);
      });
    }
  };
  return (
    <>
      <StyleDiv>
        {/* rightSide Row */}
        <LeftRow gutter={[0, 20]}>
          <Col>
            <AccountDetailsRadioView
              disabled={parentSetting?.limitBetweenHighAndLow === "block"}
              title={"Order between High & Low"}
              name="orderBetweenHighLow"
              formProps={{
                name: "orderBetweenHighLow",
                initialValue: false,
              }}
            />
          </Col>
          <Col>
            <AccountDetailsRadioView
              title={"Show Detail Bill "}
              name="showDetailBill"
              formProps={{
                name: "showDetailBill",
                initialValue: false,
              }}
            />
          </Col>
          <Col>
            <AccountDetailsRadioView
              title={"Allow Banned Script"}
              name="allowBannedScript"
              formProps={{
                name: "allowBannedScript",
                initialValue: false,
              }}
            />
          </Col>
          <Col>
            <AccountDetailsRadioView
              title={"Only Position Squareup"}
              name="isOnlyPositionSquareup"
              formProps={{
                name: "isOnlyPositionSquareup",
                initialValue: false,
              }}
            />
          </Col>
        </LeftRow>

        {/* rightSide Row */}
        <>
          <RightRow gutter={[16, 6]}>
            <RowDiv gutter={[5, 10]}>
              <Col lg={14} md={14} sm={0}></Col>
              <ProfilCol span={5}>
                <ThemeTextLabel title="Profit" />
              </ProfilCol>
              <ProfilCol span={5}>
                <ThemeTextLabel title="Loss" />
              </ProfilCol>
            </RowDiv>
            <RowDiv gutter={[5, 10]}>
              <Col xs={24} sm={24} md={14}>
                <AccountDetailsRadioView
                  title={"M2M Alert"}
                  name="isM2MAlert"
                  onChange={handleChange}
                  formProps={{
                    name: "isM2MAlert",
                    initialValue: false,
                  }}
                />
              </Col>

              <Col xs={24} sm={12} md={5} lg={5} xl={5}>
                <ThemeInputNumber
                  title={window.innerWidth < 769 ? "Profit" : ""}
                  extraProps={{
                    input_type: "number",
                    parent_key: "accountDetails",
                    nested_key: "M2MAlert",
                  }}
                  inputProps={{
                    width: "50%",
                    suffix: "%",
                    placeholder: "0",
                    name: "M2MAlert$profit",
                    disabled: !m2m,
                  }}
                  marginBottom={"0px !important"}
                  titleWrapperStyle={{ justifyContent: "center" }}
                  formProps={{
                    name: `M2MAlert$profit`,
                    dependencies: [`isM2MAlert`, "M2MAlert$loss"],
                    rules: [
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          let lossValue = getFieldValue("M2MAlert$loss");
                          if (
                            (getFieldValue("isM2MAlert") &&
                              value >= 0 &&
                              value <= 100 &&
                              (value || value === 0)) ||
                            (!value &&
                              lossValue >= 0 &&
                              lossValue <= 100 &&
                              (lossValue || lossValue === 0)) ||
                            !getFieldValue("isM2MAlert")
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(`Only allow between 0 to 100`)
                          );
                        },
                      }),
                    ],
                  }}
                />
              </Col>
              <Col xs={24} sm={12} md={5} lg={5} xl={5}>
                <ThemeInputNumber
                  title={window.innerWidth < 769 ? "Loss" : ""}
                  extraProps={{
                    input_type: "number",
                    parent_key: "accountDetails",
                    nested_key: "M2MAlert",
                  }}
                  inputProps={{
                    width: "50%",
                    suffix: "%",
                    placeholder: "0",
                    name: "M2MAlert$loss",
                    disabled: !m2m,
                  }}
                  marginBottom={"0px !important"}
                  titleWrapperStyle={{ justifyContent: "center" }}
                  formProps={{
                    name: `M2MAlert$loss`,
                    dependencies: [`isM2MAlert`, "M2MAlert$profit"],
                    rules: [
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          let profiltValue = getFieldValue("M2MAlert$profit");

                          if (
                            (getFieldValue("isM2MAlert") &&
                              value >= 0 &&
                              value <= 100 &&
                              (value || value == 0)) ||
                            (!value &&
                              profiltValue >= 0 &&
                              profiltValue <= 100 &&
                              (profiltValue || profiltValue == 0)) ||
                            !getFieldValue("isM2MAlert")
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(`Only allow between 0 to 100`)
                          );
                        },
                      }),
                    ],
                  }}
                />
              </Col>
            </RowDiv>
            <RowDiv gutter={[5, 10]}>
              <Col xs={24} sm={24} md={14}>
                <AccountDetailsRadioView
                  title={"Auto Stop New Position"}
                  name="isAutoStopNewPosition"
                  onChange={handleChange}
                  formProps={{
                    name: "isAutoStopNewPosition",
                    initialValue: false,
                  }}
                />
              </Col>
              <Col xs={24} sm={12} md={5} lg={5} xl={5}>
                <ThemeInputNumber
                  title={window.innerWidth < 769 ? "Profit" : ""}
                  inputProps={{
                    suffix: "%",
                    placeholder: "0",
                    name: "autoStopNewPosition$profit",
                    disabled: !autoStop,
                  }}
                  extraProps={{
                    input_type: "number",
                    parent_key: "accountDetails",
                    nested_key: "autoStopNewPosition",
                  }}
                  marginBottom={"0px !important"}
                  titleWrapperStyle={{ justifyContent: "center" }}
                  formProps={{
                    name: `autoStopNewPosition$profit`,
                    dependencies: [
                      `isAutoStopNewPosition`,
                      `autoStopNewPosition$loss`,
                    ],
                    rules: [
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          let lossValue = getFieldValue(
                            "autoStopNewPosition$loss"
                          );
                          if (
                            (getFieldValue("isAutoStopNewPosition") &&
                              value >= 0 &&
                              value <= 100 &&
                              (value || value === 0)) ||
                            (!value &&
                              lossValue >= 0 &&
                              lossValue <= 100 &&
                              (lossValue || lossValue === 0)) ||
                            !getFieldValue("isAutoStopNewPosition")
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(`Only allow between 0 to 100`)
                          );
                        },
                      }),
                    ],
                  }}
                />
              </Col>
              <Col xs={24} sm={12} md={5} lg={5} xl={5}>
                <ThemeInputNumber
                  title={window.innerWidth < 769 ? "Loss" : ""}
                  extraProps={{
                    input_type: "number",
                    parent_key: "accountDetails",
                    nested_key: "autoStopNewPosition",
                  }}
                  inputProps={{
                    suffix: "%",
                    placeholder: "0",
                    width: "95%",
                    name: "autoStopNewPosition$loss",
                    disabled: !autoStop,
                  }}
                  marginBottom={"0px !important"}
                  titleWrapperStyle={{ justifyContent: "center" }}
                  formProps={{
                    name: `autoStopNewPosition$loss`,
                    dependencies: [
                      `isAutoStopNewPosition`,
                      `autoStopNewPosition$profit`,
                    ],
                    rules: [
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          let profitValue = getFieldValue(
                            "autoStopNewPosition$profit"
                          );
                          if (
                            (getFieldValue("isAutoStopNewPosition") &&
                              value >= 0 &&
                              value <= 100 &&
                              (value || value === 0)) ||
                            (!value &&
                              profitValue >= 0 &&
                              profitValue <= 100 &&
                              (profitValue || profitValue === 0)) ||
                            !getFieldValue("isAutoStopNewPosition")
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(`Only allow between 0 to 100`)
                          );
                        },
                      }),
                    ],
                  }}
                />
              </Col>
            </RowDiv>
            <RowDiv gutter={[5, 10]}>
              <Col xs={24} sm={24} md={14}>
                <AccountDetailsRadioView
                  title={"Apply Auto Squareup"}
                  name="isApplyAutoSquareup"
                  onChange={handleChange}
                  formProps={{
                    name: "isApplyAutoSquareup",
                    initialValue: false,
                  }}
                />
              </Col>
              <Col xs={24} sm={12} md={5} lg={5} xl={5}>
                <ThemeInputNumber
                  title={window.innerWidth < 769 ? "Profit" : ""}
                  extraProps={{
                    input_type: "number",
                    parent_key: "accountDetails",
                    nested_key: "applyAutoSquareup",
                  }}
                  inputProps={{
                    suffix: "%",
                    placeholder: "0",
                    width: "95%",
                    name: "applyAutoSquareup$profit",
                    disabled: !applayAuto,
                  }}
                  marginBottom={"0px !important"}
                  titleWrapperStyle={{ justifyContent: "center" }}
                  formProps={{
                    name: `applyAutoSquareup$profit`,
                    dependencies: [
                      `isApplyAutoSquareup`,
                      `applyAutoSquareup$loss`,
                    ],
                    rules: [
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          let lossValue = getFieldValue(
                            "applyAutoSquareup$loss"
                          );
                          console.log("lossvalue", lossValue);
                          if (
                            (getFieldValue("isApplyAutoSquareup") &&
                              value >= 0 &&
                              value <= 100 &&
                              (value || value === 0)) ||
                            (!value &&
                              lossValue >= 0 &&
                              lossValue <= 100 &&
                              (lossValue || lossValue === 0)) ||
                            !getFieldValue("isApplyAutoSquareup")
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(`Only allow between 0 to 100`)
                          );
                        },
                      }),
                    ],
                  }}
                />
              </Col>
              <Col xs={24} sm={12} md={5} lg={5} xl={5}>
                <ThemeInputNumber
                  title={window.innerWidth < 769 ? "Loss" : ""}
                  extraProps={{
                    input_type: "number",
                    parent_key: "accountDetails",
                    nested_key: "applyAutoSquareup",
                  }}
                  inputProps={{
                    suffix: "%",
                    placeholder: "0",
                    width: "95%",
                    name: "applyAutoSquareup$loss",
                    disabled: !applayAuto,
                  }}
                  marginBottom={"0px !important"}
                  titleWrapperStyle={{ justifyContent: "center" }}
                  formProps={{
                    name: `applyAutoSquareup$loss`,
                    dependencies: [
                      `isApplyAutoSquareup`,
                      `applyAutoSquareup$profit`,
                    ],
                    rules: [
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          let profiltValue = getFieldValue(
                            "applyAutoSquareup$profit"
                          );
                          console.log({ profiltValue });
                          if (
                            (getFieldValue("isApplyAutoSquareup") &&
                              value >= 0 &&
                              value <= 100 &&
                              (value || value === 0)) ||
                            (!value &&
                              profiltValue >= 0 &&
                              profiltValue <= 100 &&
                              (profiltValue || profiltValue === 0)) ||
                            !getFieldValue("isApplyAutoSquareup")
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(`Only allow between 0 to 100`)
                          );
                        },
                      }),
                    ],
                  }}
                />
              </Col>
            </RowDiv>
            <RowDiv gutter={[5, 10]}>
              <Col xs={24} sm={24} md={14}>
                <AccountDetailsRadioView
                  title={"New Position Squareup Time"}
                  name="isNewPositionSquareupTime"
                  onChange={(e) => {
                    let isChecked = e.target.value;
                    if (!isChecked) {
                      parentForm.setFieldValue("newPositionSquareupTime", null);
                    }
                  }}
                  formProps={{
                    name: "isNewPositionSquareupTime",
                    initialValue: false,
                  }}
                />
              </Col>
              <Col xs={24} sm={24} md={10}>
                <ThemeTimePicker
                  marginBottom={"0rem"}
                  width="100%"
                  defaultOpenValue={dayjs("00:00:00", "hh:mm:ss")}
                  inputProps={{
                    placeholder: "Select time hh:mm:ss format",
                    name: "newPositionSquareupTime",
                    format: "HH:mm:ss",
                    use12Hours: false,
                    width: "100%",
                    disabled: !squareUP,
                  }}
                  formProps={{
                    name: "newPositionSquareupTime",
                  }}
                />
              </Col>
            </RowDiv>
            <RowDiv gutter={[5, 10]}>
              <Col xs={24} sm={24} md={14}>
                <AccountDetailsRadioView
                  title={"Fresh Limit Allowed"}
                  name="isFreshLimitAllow"
                  onChange={handleChange}
                  formProps={{
                    name: "isFreshLimitAllow",
                    initialValue: true,
                  }}
                />
              </Col>
              <Col xs={24} sm={24} md={10}>
                <Form.Item
                  name={`freshLimitAllow`}
                  dependencies={[`isFreshLimitAllow`]}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          (getFieldValue("isFreshLimitAllow") &&
                            // accountDetails?.FreshLimitAllowed?.gap &&
                            value >= 0 &&
                            value <= 100) ||
                          !value ||
                          !getFieldValue("isFreshLimitAllow")
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(`Only allow between 0 to 100`)
                        );
                      },
                    }),
                  ]}
                >
                  <InputNumber
                    className="w-100"
                    suffix="%"
                    name="freshLimitAllow"
                    placeholder="FRESH LIMIT GAP FROM HIGH/LOW IN %"
                    disabled={!freshLimit}
                  />
                </Form.Item>
                {/* <ThemeInputNumber
                  extraProps={{
                    input_type: "number",
                    parent_key: "accountDetails",
                    nested_key: "freshLimitAllow",
                  }}
                  inputProps={{
                    suffix: "%",
                    placeholder: "FRESH LIMIT GAP FROM HIGH/LOW IN %",
                    name: "freshLimitAllow",
                    disabled: !freshLimit,
                  }}
                  marginBottom={"0px !important"}
                  titleWrapperStyle={{ justifyContent: "center" }}
                  formProps={{
                    dependencies: [`isFreshLimitAllow`],
                    name: `freshLimitAllow`,
                    rules: [
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            (getFieldValue("isFreshLimitAllow") &&
                              // accountDetails?.FreshLimitAllowed?.gap &&
                              value >= 0 &&
                              value <= 100) ||
                            !getFieldValue("isFreshLimitAllow")
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(`Only allow between 0 to 100`)
                          );
                        },
                      }),
                    ],
                  }}
                /> */}
              </Col>
            </RowDiv>
          </RightRow>
        </>
        {/* ------------------------ */}
      </StyleDiv>
      <Col span={24} align="center" style={{ marginTop: "1rem" }}>
        <ThemeInput
          title={"Remark"}
          inputProps={{
            placeholder: "",
            name: "remark",
          }}
          formProps={{
            name: "remark",
          }}
        />
      </Col>
    </>
  );
});
export default CustomerView;
