import coin from "../Helper/Images/coin.svg";
import notification from "../Helper/Images/notification.svg";
import login_page from "../Helper/Images/login_page.png";
import coin0_5 from "../Helper/Images/Coin@0.5x.png";
import coin0_75 from "../Helper/Images/Coin@0.75x.png";
import coin1 from "../Helper/Images/Coin@1x.png";
import coin2 from "../Helper/Images/Coin@2x.png";
const Images = {
  svg: {
    coin,
    notification,
  },
  login_page,
  coin0_5,
  coin0_75,
  coin1,
  coin2,
};
export default Images;
