import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  letestTrade: [],
  pendingTrade: [],
  rejectionLog: [],
  m2mAlert: [],
  isLoading: false,
};

const apiDataSlice = createSlice({
  name: "apiDataSlice",
  initialState,
  reducers: {
    setLetestTrade: (state, action) => {
      state.letestTrade = action.payload;
    },
    setPendingTrade: (state, action) => {
      state.pendingTrade = action.payload;
    },
    setRejectionLog: (state, action) => {
      state.rejectionLog = action.payload;
    },
    setM2mAlert: (state, action) => {
      state.m2mAlert = action.payload;
    },
    setAPILoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export default apiDataSlice.reducer;

export const {
  setLetestTrade,
  setPendingTrade,
  setRejectionLog,
  setM2mAlert,
  setAPILoading,
} = apiDataSlice.actions;
