import { notification } from "antd";
import { createContext } from "react";
export const NotificationContext = createContext(null);
notification.config({
  placement: "top",
  duration: 3,
});

export const NotificationProvider = ({ children }) => {
  const [api, contextHolder] = notification.useNotification({});

  const showNotification = ({ type = "info", config }) => {
    api[type](config);
  };

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
};
