import styled, { keyframes } from "styled-components";
import { Carousel } from "antd";
import React from "react";
import { useSelector } from "react-redux";

const scrollLeft = keyframes`
  from { transform: translateX(100%); }
  to { transform: translateX(-100%); }
`;

const ScrollMessageContainer = styled.div`
  width: 100%; /* Adjust width as needed */
  background-color: rgb(240, 242, 245);
`;

const ScrollingMessage = styled.p`
  white-space: nowrap; /* Prevent text wrapping */
  animation: ${scrollLeft} 20s linear infinite;
  color: red;
  font-weight: 500;
  font-size: 1.5rem;

  /* border: 1px solid red; */
`;

const ScrollBar = () => {
  const information = useSelector(
    (state) => state?.globleSlice?.broadCastExpiry
  );

  return (
    <ScrollMessageContainer>
      <Carousel autoplay autoplaySpeed={3000} dots={false}>
        <div>
          <ScrollingMessage>{information}</ScrollingMessage>
        </div>
      </Carousel>
    </ScrollMessageContainer>
  );
};

export default ScrollBar;
