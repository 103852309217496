import { useEffect, useState } from "react";
import store from "../../../store/store";
import {
  formatNumber,
  formatToINR,
} from "../../../components/FormateToINR/formatToINR";

export const ScriptName = (record) => {
  const opt = record?.marketName?.endsWith("OPT");
  const name = opt
    ? `${record?.symbolName}  ${
        record?.strikePrice ? record?.strikePrice : ""
      } ${record?.series ? record?.series : ""}`
    : record?.symbolName;

  return name;
};

export const TotalPNL = ({ data }) => {
  const [total, setTotal] = useState(0);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const current = data?.reduce((acc, curr) => {
  //       let price =
  //         store?.getState()?.positionSocketSlice?.data?.[curr?._id]
  //           ?.currentPNL || 0;
  //       acc = acc + Number(price);
  //       return acc;
  //     }, 0);

  //     setTotal(current);
  //   }, 1000);

  //   return () => clearInterval(interval);
  // }, [data]);

  useEffect(() => {
    const worker = new Worker(new URL("../totalPNLWorker.js", import.meta.url));
    worker.onmessage = (e) => {
      const { total } = e.data;
      // console.log("total", total);
      setTotal(total); // Update state with the result
    };

    const interval = setInterval(() => {
      const prices = store?.getState()?.positionSocketSlice?.data; // Create an object to hold prices by _id
      worker.postMessage({ data, prices }); // Send both data and prices to the worker
    }, 1000);

    return () => {
      clearInterval(interval); // Clear the interval on cleanup
      worker.terminate(); // Terminate the worker on component unmount
    };
  }, [data]);

  return (
    <span style={{ color: total > 0 ? "green" : "red" }}>
      {total > 0 ? `+${formatToINR(total)}` : `${formatToINR(total)}`}
    </span>
  );
};

export const TotalOutstanding = ({ data, value, type }) => {
  let total = 0;
  let lot = 0;
  const noLot = value?.radioClient === 1 && data?.[0]?.marketName === "NSE EQU";
  data?.forEach((el) => {
    if (el?.positionType === type) {
      total += el?.totalQuantity;
      if (el?.marketName !== "NSE EQU") {
        lot += el?.lot;
      }
    }
  });

  return (
    <span style={{ color: total > 0 ? "green" : "red" }}>
      {total > 0 ? `+${formatToINR(total)}` : formatToINR(total)}

      {!noLot && `(${formatNumber(lot)})`}
    </span>
  );
};

export const TotalMTM = ({ data }) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    const calculateTotalMTM = () => {
      const totalMTM =
        typeof data !== "number" &&
        data?.reduce((acc, curr) => {
          let price =
            store?.getState()?.positionSocketSlice?.data?.[curr?._id]
              ?.currentPNL || 0;
          acc = acc + Number(price);
          return acc;
        }, 0);

      setValue(totalMTM);
    };

    const interval = setInterval(calculateTotalMTM, 1000);
    return () => clearInterval(interval);
  }, [data]);

  // return value;
  return formatToINR(value);
};
