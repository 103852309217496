import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import DashboardCommonTable from "../../components/DashboardCommonTable";
import { Col, notification } from "antd";
import EditTrade from "../../components/EditTrade/EditTrade";
import VerificationModal from "../../components/VerificationModal/VerificationModal";
import { options } from "../../Helper/constant";
import { DeleteIcon, EditIcon, onlyTime } from "./style";
import {
  findMarket,
  formatNumber,
} from "../../components/FormateToINR/formatToINR";
import { limitNumberLength } from "../../components/SocketLiveData";

const LetestTradeTable = React.memo(() => {
  const queryClient = useQueryClient();

  //state
  const [showModal, setShowModal] = useState({
    name: null,
    flag: false,
    record: null,
  });
  const [filter, setFilter] = useState(null);

  //redux
  const LoginDetails = useSelector((state) => state?.authSlice?.userDetail);
  const addition = useSelector((state) => state?.authSlice?.additionalSharing);
  const loginUserId = LoginDetails?.userId;
  const loginAccountType = LoginDetails?.accountType;
  const isOwner = loginAccountType === "owner";
  const actionAllow =
    loginAccountType !== "customer" && loginAccountType !== "broker";
  const isStale = queryClient
    .getQueryCache()
    .find(["allTrade", null])
    ?.isStale();

  // life cycle
  useEffect(() => {
    if (isStale) {
      queryClient.resetQueries(["allTrade", filter]);
    }
  }, []);

  // api call
  const fetchInitialData = async ({ pageParam = 1 }) => {
    let obj = { userId: loginUserId, tradeStatus: "executed", title: filter };

    const response = await APICall(
      "post",
      `${EndPoints.getTrade}?page=${pageParam}&limit=10&isLatest=true`,
      obj
    );

    return response?.data?.data;
  };

  const tradeQuery = useInfiniteQuery({
    queryKey: ["allTrade", filter],
    queryFn: fetchInitialData,
    getNextPageParam: (lastPage) => lastPage?.nextPage,
    enabled: true,
  });

  if (tradeQuery?.error) {
    notification.error({ message: tradeQuery?.error?.message });
  }

  const newPageData =
    tradeQuery?.data?.pages?.flatMap((page) => page && page?.trades) || [];

  const deleteTrade = async (record, index, key) => {
    const id = record?._id;
    const customerId = record?.customerId?._id;
    let obj = {
      deviceType: "web",
      tradeIpAddress: record?.tradeIpAddress,
    };

    try {
      let response = await APICall(
        "delete",
        `${EndPoints.deleteTrade}${id}`,
        obj
      );
      // console.log("🚀 ~ deleteTrade ~ response:", response);
      if (response.status === 200) {
        notification.success({ message: response.data.message });
        queryClient.refetchQueries({ queryKey: key, type: "active" });
        queryClient.resetQueries(["getWatchList"]);
      } else {
        notification.error({ message: response.data.message });
      }
    } catch (error) {
      console.log("error");
    }
  };

  // function`
  const handleCancel = () => {
    // setShowModal(false);
    setShowModal({ name: null, flag: false, record: null });
  };

  const handleEdit = (record, key) => {
    setShowModal({ name: "edit", flag: true, record: { ...record, key } });
  };

  const filterOption = options.trading.orderType?.map((el) => ({
    ...el,
    text: el?.label,
  }));

  // column
  const latestTradeColumns = useMemo(
    () => [
      tradeQuery?.isLoading
        ? {
            title: "TIME",
            dataIndex: "tradeTime",
            key: "tradeTime",
            width: "5rem",
            align: "left",
          }
        : {
            title: "TIME",
            dataIndex: "tradeTime",
            key: "tradeTime",
            width: "5rem",
            align: "left",
            render: (text, record) => {
              const time = onlyTime(record?.tradeTime);
              let executeTime = "";
              if (record?.title === "limit") {
                const excute = onlyTime(record?.executeTime);
                executeTime = excute;
              }
              return (
                <>
                  {executeTime ? (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div>{executeTime}</div>
                      <div> {`(${time})`}</div>
                    </div>
                  ) : (
                    <div> {time}</div>
                  )}
                </>
              );
            },
          },
      {
        title: "CLIENT",
        dataIndex: "customerName",
        key: "customerName",
        width: "2rem",
        align: "left",
        ellipsis: true,
        render: (text, record) => {
          let id = record?.customer[0]?.userId;
          return <>{`${id} (${record?.customer[0]?.accountName})`}</>;
        },
      },
      {
        title: "SCRIPT",
        dataIndex: "scriptName",
        key: "scriptName",
        width: "8rem",
        align: "left",
        render: (text, record) => {
          const date = record?.expiryDate ? record?.expiryDate : "";
          // const date = valanBillDate(record?.expiryDate, false);
          const opt = record?.marketName?.endsWith("OPT");
          const name = opt
            ? `${record?.symbolName} ${record?.series ? record?.series : ""} ${
                record?.strikePrice ? record?.strikePrice : ""
              }`
            : record?.symbolName;
          return (
            <div>
              {name} {date}
            </div>
          );
        },
      },
      {
        title: "TYPE",
        dataIndex: "ordertype",
        key: "ordertype",
        width: "4rem",
        align: "left",
        filters: filterOption,
        filterMultiple: false,
        className: "table-filter",
        render: (text, record) => {
          return <div>{record?.title}</div>;
        },
      },
      {
        title: "B/S",
        dataIndex: "tradePattern",
        key: "buy/sell",
        width: "3rem",
        align: "left",
      },
      {
        title: "QTY (LOT)",
        dataIndex: "lot",
        key: "lot",
        width: "7rem",
        align: "left",
        render: (text, record) => {
          const lot = formatNumber(record?.lot);
          const quantity = formatNumber(record?.quantity);
          const data =
            record?.marketName === "NSE EQU"
              ? quantity
              : `${quantity} (${lot})`;
          return <div>{data}</div>;
        },
      },
      {
        title: "RATE",
        dataIndex: "tradePrice",
        key: "rate",
        width: "5rem",
        align: "center",
        render: (text, record) => {
          const tradePrice = findMarket(record?.marketName)
            ? limitNumberLength(record?.tradePrice)
            : record?.tradePrice?.toFixed(2);
          return <div>{tradePrice}</div>;
        },
      },
      actionAllow && (addition?.editTrade || addition?.tradeDelete)
        ? {
            title: "ACTION",
            dataIndex: "edit",
            key: "edit",
            width: "5rem",
            align: "center",
            render: (text, record, index) => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "0.6rem",
                  }}
                >
                  {addition?.editTrade &&
                    (record?.deviceType !== "system" || isOwner ? (
                      <EditIcon
                        onClick={() => handleEdit(record, "allTrade")}
                      />
                    ) : (
                      "-"
                    ))}

                  {addition?.tradeDelete &&
                    (record?.deviceType !== "system" || isOwner ? (
                      <DeleteIcon
                        onClick={() => {
                          setShowModal({
                            name: "delete",
                            flag: true,
                            record: record,
                          });
                        }}
                      />
                    ) : (
                      "-"
                    ))}
                </div>
              );
            },
          }
        : {
            width: "0px",
          },
    ],
    [handleEdit, newPageData]
  );

  const handleChange = (pagination, filters, sorter) => {
    if (filters?.ordertype?.[0]) {
      setFilter(filters?.ordertype?.[0]);
    } else {
      setFilter(null);
    }
  };

  return (
    <Col xxl={12} xl={12} lg={24}>
      <DashboardCommonTable
        columns={latestTradeColumns}
        tableTitle="LATEST TRADES"
        viewMore={true}
        allowBtn={false}
        dataSource={newPageData?.[0] ? newPageData : []}
        isLoading={tradeQuery?.isLoading}
        queryKey="allTrade"
        tradeQuery={tradeQuery}
        onChange={handleChange}
        emptyPage={tradeQuery?.data?.pages?.[0]?.trades?.length === 0}
      />

      {showModal?.flag && showModal?.name === "edit" && (
        <EditTrade handleCancel={handleCancel} record={showModal.record} />
      )}

      {showModal?.flag && showModal?.name === "delete" && (
        <VerificationModal
          handleCancel={handleCancel}
          record={showModal.record}
          query={"allTrade"}
          type="delete"
          title={"Delete Trade"}
        />
      )}
    </Col>
  );
});

export default LetestTradeTable;
