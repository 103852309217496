import { Form, Space } from "antd";
import { amountToWordsINR } from "../../Helper/data";
import Images from "../../Helper/Image";
import { useState } from "react";
import {
  AnimatedContent,
  StyledThemeInput,
  ToggleContainer,
  ToggleImage,
  TogglePercentage,
} from "./style";

const ThemeInput = (props) => {
  const [isCoin, setIsCoin] = useState(true);
  let showCoin = props?.extraProps?.hasOwnProperty("isCoin")
    ? props?.extraProps?.isCoin
    : isCoin;

  //functions
  const onChangeValue = (e) => {
    if (props?.extraProps?.input_type === "number") {
      const reg = /^-?\d*(\.\d*)?$/;
      let inputValue = e.target.value;
      if (reg.test(inputValue) || inputValue === "" || inputValue === "-") {
        props?.inputProps?.onChange &&
          props?.inputProps?.onChange({
            ...e,
            target: {
              name: e.target.name,
              value: inputValue,
              type: !showCoin ? "percentage" : "br",
              parent_key: props?.extraProps?.parent_key,
              nested_key: props?.extraProps?.nested_key,
            },
          });
      }
    } else {
      props?.inputProps?.onChange &&
        props?.inputProps?.onChange({
          ...e,
          target: {
            name: e.target.name,
            value: e.target.value,
            type: !showCoin ? "percentage" : "br",
            parent_key: props?.extraProps?.parent_key,
            nested_key: props?.extraProps?.nested_key,
          },
        });
    }
  };

  const onBlurValue = (e) => {
    if (props?.extraProps?.input_type === "number") {
      let valueTemp = e.target.value;
      if (valueTemp.charAt(valueTemp.length - 1) === "." || valueTemp === "-") {
        valueTemp = e.target.value?.slice(0, -1);
      }
      props?.inputProps?.onBlur &&
        props?.inputProps?.onBlur({
          ...e,
          target: {
            name: e.target.name,
            value: valueTemp.replace(/0*(\d+)/, "$1"),
            type: !showCoin ? "percentage" : "br",
          },
        });
    } else {
      props?.inputProps?.onBlur && props?.inputProps?.onBlur(e);
    }
  };

  const onClickAddonAfter = (e) => {
    if (props?.extraProps?.hasOwnProperty("onChangeType")) {
      props?.extraProps?.onChangeType(
        `${props?.inputProps?.name}_coin`,
        !showCoin
      );
    } else {
      setIsCoin(!showCoin);
    }
  };

  return (
    <StyledThemeInput.Wrapper
      width={props?.width}
      wrapperwidth={props?.wrapperwidth}
      $active={props?.active || undefined}
      $marginBottom={props?.marginBottom}
      $showSuffixBg={props?.showSuffixBg}
      $activeColor={props?.activeColor}
      errorheight={props?.errorheight}
      errortext={props?.errortext}
    >
      <Space
        style={{
          justifyContent: "space-between",
          ...props?.titleWrapperStyle,
        }}
      >
        {props?.title ? (
          <StyledThemeInput.Title $titleFontWeight={props?.titleFontWeight}>
            {props?.title}
            {props?.required ? (
              <span className="styled-drop-down-star">*</span>
            ) : null}
          </StyledThemeInput.Title>
        ) : null}
        {props?.subTitle ? (
          <StyledThemeInput.Title className="sub-title">
            {props?.subTitle}
          </StyledThemeInput.Title>
        ) : null}
      </Space>
      <Form.Item {...props.formProps}>
        {/* <div style={{ width: props?.width }}> */}
        <StyledThemeInput.Input
          placeholder="Type here"
          {...props?.inputProps}
          // onBlur={onBlurValue}
          // onChange={onChangeValue}
          addonAfter={
            props?.extraProps?.defaultAddonAfter ? (
              <ToggleContainer onClick={onClickAddonAfter}>
                <AnimatedContent $showImage={showCoin}>
                  <TogglePercentage>%</TogglePercentage>
                  <ToggleImage src={Images.coin0_5} alt="coin" />
                </AnimatedContent>
              </ToggleContainer>
            ) : (
              props?.extraProps?.addonAfter
            )
          }
        />
        {/* </div> */}
      </Form.Item>
      <Space
        style={{
          justifyContent: "end",
          // marginTop: "1rem",
        }}
      >
        <StyledThemeInput.Title className="sub-title sub-title-2">
          {props?.extraProps?.showConversion
            ? amountToWordsINR(props?.inputProps?.value, 100000000000)
            : props?.subTitle2 || ""}
        </StyledThemeInput.Title>
      </Space>
    </StyledThemeInput.Wrapper>
  );
};

export default ThemeInput;
