import React, { forwardRef, useImperativeHandle, useState } from "react";
import { DatePicker, Form, Space, Typography } from "antd";
import { styled } from "styled-components";
import "./style.css";

const StyledThemeDatePicker = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Title: styled(Typography.Title)`
    &.ant-typography {
      color: ${(props) => props.theme.token.colorTextLabel};
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      margin-bottom: 0.5rem;
      line-height: 1.125rem;
      text-align: left;
      & .styled-drop-down-star {
        color: ${(props) => props.theme.token.red4};
        font-size: 1rem;
      }
      @media (max-width: 1079px) {
        font-size: 0.8rem;
      }
    }
  `,
  DatePicker: styled(DatePicker)`
    width: ${(props) => props.width};
    height: 3rem; /* Set the desired height here */
    border-radius: ${(props) =>
      props.theme.token.borderRadiusLG + "px"}; /* Match the border-radius */

    /* Add DatePicker-specific styling here */
    .ant-picker-input > input {
      font-size: 1rem;
      @media (max-width: 1079px) {
        font-size: 0.8rem;
      }
    }
    @media (max-width: 1079px) {
      height: 2.5rem;
    }
  `,
};

const ThemeDatePicker = forwardRef((props, _ref) => {
  const [selectedDate, setSelectedDate] = useState(null); // State to store the selected date

  // Function to handle date change
  const handleDateChange = (date, dateString) => {
    setSelectedDate(date); // Update the selectedDate state
    if (props?.onChange && date !== null) {
      props?.onChange(date, dateString);
    }
  };

  useImperativeHandle(
    _ref,
    () => ({
      value: selectedDate || props?.value || null, // Use selectedDate as the value if available
    }),
    [selectedDate, props?.value]
  );

  return (
    <div style={{ margin: "0px", padding: "0px" }}>
      <StyledThemeDatePicker.Wrapper ref={_ref}>
        {props?.title || props?.subTitle ? (
          <Space
            style={{
              justifyContent: "space-between",
            }}
          >
            <StyledThemeDatePicker.Title>
              {props?.title}
              {props?.required ? (
                <span className="styled-drop-down-star">*</span>
              ) : null}
            </StyledThemeDatePicker.Title>
          </Space>
        ) : null}
        <Form.Item {...props?.formProps}>
          <StyledThemeDatePicker.DatePicker
            // dropdownClassName="date-picker"
            popupClassName="date-picker-popup"
            name={props?.name}
            value={selectedDate}
            onChange={handleDateChange}
            $width={props?.width}
            placeholder={props?.placeholder}
            {...props}
          />
        </Form.Item>
      </StyledThemeDatePicker.Wrapper>
    </div>
  );
});

export default ThemeDatePicker;
