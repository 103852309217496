import { formatToINR, formatToINRForSummay } from "../FormateToINR/formatToINR";

export const showValue = (data) => {
  if (data !== undefined && data !== null) {
    const intValue = Number(data) || 0;
    return (
      <span style={{ color: intValue >= 0 ? "green" : "red" }}>
        {intValue > 0
          ? `+${formatToINRForSummay(intValue)}`
          : `${formatToINRForSummay(intValue)}`}
      </span>
    );
  } else {
    return 0;
  }
};

export const CurrentPrice = (record) => {
  let key = record?.totalQuantity > 0 ? "Bid" : "Ask";
  return key;
};

export const valanBillDate = (data, flage) => {
  if (!data) {
    return null;
  }

  // Convert the timestamp
  const date = new Date(data);

  const formattedTime = date.toLocaleTimeString("en-IN", {
    timeZone: "Asia/Kolkata",
    hour12: false,
  });
  const formattedDate = `${formattedTime}`;

  // Format date using UTC methods
  const day = date.getDate();
  const month = date.toLocaleString("default", {
    month: "long",
    timeZone: "UTC",
  });
  const year = date.getUTCFullYear();

  // Mapping of full month names to abbreviated month names
  const monthAbbreviations = {
    January: "JAN",
    February: "FEB",
    March: "MAR",
    April: "APR",
    May: "MAY",
    June: "JUN",
    July: "JUL",
    August: "AUG",
    September: "SEP",
    October: "OCT",
    November: "NOV",
    December: "DEC",
  };

  const abbreviatedMonth = monthAbbreviations[month];

  if (flage) {
    return `${formattedDate} ${day}${abbreviatedMonth}${year}`;
  } else {
    return `${day}${abbreviatedMonth}${year}`;
  }
};

export function formatDate(dateString) {
  const date = new Date(dateString);

  // Extract day, month, and year
  const day = date.getUTCDate(); // Using getUTCDate() to extract day in UTC timezone
  const month = date.getUTCMonth() + 1; // Using getUTCMonth() to extract month in UTC timezone, adding 1 because January is 0
  const year = date.getUTCFullYear(); // Using getUTCFullYear() to extract year in UTC timezone

  // Format the date as "DD-MM-YYYY"
  const formattedDate = `${day < 10 ? "0" : ""}${day}-${
    month < 10 ? "0" : ""
  }${month}-${year}`;

  return formattedDate;
}

// export const dateConvert = (dateString) => {
//   if (!dateString) return ""; // Handle empty or undefined input
//   const parts = dateString.split("-");

//   if (parts.length !== 3) return dateString; // Handle unexpected input format

//   const day = parts[0];
//   const monthIndex = parseInt(parts[1], 10); // Parse as integer
//   const year = parts[2];

//   if (isNaN(monthIndex) || monthIndex < 1 || monthIndex > 12) return dateString; // Handle invalid month

//   const monthAbbreviations = [
//     "JAN",
//     "FEB",
//     "MAR",
//     "APR",
//     "MAY",
//     "JUN",
//     "JUL",
//     "AUG",
//     "SEP",
//     "OCT",
//     "NOV",
//     "DEC",
//   ];
//   const abbreviatedMonth = monthAbbreviations[monthIndex - 1]; // Adjust for zero-based index

//   return `${day}${abbreviatedMonth}${year}`;
// };
