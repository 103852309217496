import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

const useGetQueryParams = () => {
  let [searchParams, setSearchParams] = useSearchParams();

  let params = useMemo(() => {
    return Object.fromEntries([...searchParams]);
  }, [searchParams]);
  const handleDeleteOne = (key) => {
    let temp = params;
    delete temp[key];
    setSearchParams({ ...temp });
  };
  const handleDeleteWithAdd = (keyToDelete, objToAdd) => {
    let temp = { ...params };
    if (Array.isArray(keyToDelete)) {
      keyToDelete.forEach((item) => {
        delete temp[item];
      });
    } else {
      delete temp[keyToDelete];
    }
    if (objToAdd) {
      temp = { ...temp, ...objToAdd };
    }
    setSearchParams({ ...temp });
  };

  return {
    params,
    setSearchParams,
    handleDeleteWithAdd,
  };
};

export default useGetQueryParams;
