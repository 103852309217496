// import pLimit from "p-limit";

// import i18n from "../i18n";

// import { alpha } from "@mui/material";

import { Color } from "./Color";
import APICall from "../../src/APICall";
import EndPoints from "../../src/APICall/EndPoints";
// import "../styles.css";

const units = [
  "Zero",
  "One",
  "Two",
  "Three",
  "Four",
  "Five",
  "Six",
  "Seven",
  "Eight",
  "Nine",
];
const teens = [
  "Ten",
  "Eleven",
  "Twelve",
  "Thirteen",
  "Fourteen",
  "Fifteen",
  "Sixteen",
  "Seventeen",
  "Eighteen",
  "Nineteen",
];
const tens = [
  "",
  "",
  "Twenty",
  "Thirty",
  "Forty",
  "Fifty",
  "Sixty",
  "Seventy",
  "Eighty",
  "Ninety",
];

const ones = [
  "",
  "one",
  "two",
  "three",
  "four",
  "five",
  "six",
  "seven",
  "eight",
  "nine",
];

const convertToWords = (number) => {
  let words = [];
  if (number < 10) {
    words.push(units[number]);
  } else if (number < 20) {
    words.push(teens[number - 10]);
  } else if (number < 100) {
    words.push(tens[Math.floor(number / 10)]);
    if (number % 10) {
      words.push(units[number % 10]);
    }
  }
  return words.join(" ");
};

const convertIntegerToWords = (integer) => {
  const denominations = [
    { value: 10000000000, word: "Thousand Crore" },
    { value: 1000000000, word: "Hundred Crore" },
    { value: 10000000, word: "Crore" },
    { value: 100000, word: "Lakh" },
    { value: 1000, word: "Thousand" },
    { value: 100, word: "Hundred" },
  ];

  let words = [];

  for (const denomination of denominations) {
    if (integer >= denomination.value) {
      const count = Math.floor(integer / denomination.value);
      // if (denomination.word === "Crore" && count === 1) {
      //   words.push(denomination.word);
      // } else {
      words.push(convertToWords(count), denomination.word);
      // }
      integer %= denomination.value;
    }
  }

  if (integer > 0) {
    words.push(convertToWords(integer));
  }

  return words.join(" ");
};

const convertDecimalToWords = (decimal) => {
  let words = [];
  const paisa = Math.floor(decimal * 100);

  if (paisa) {
    words.push(convertToWords(paisa), "Paisa");
  }

  return words.join(" ");
};

export const amountToWordsINR = (value, threshold) => {
  let amount = value?.toString();
  const isNegative = amount ? amount?.startsWith("-") : "";
  const absoluteAmount = Math.abs(parseFloat(amount));

  const integerPart = parseInt(absoluteAmount.toString().replace(/,/g, ""), 10);
  const decimalPart = absoluteAmount - integerPart;

  let words = [];

  if (integerPart > threshold) {
    words.push("Amount Exceeded");
    return words.join(" ");
  } else {
    if (isNegative) {
      words.push("Minus");
    }
    words.push(convertIntegerToWords(integerPart));
  }

  if (decimalPart) {
    words.push("", convertDecimalToWords(decimalPart));
  } else {
    words.push("");
  }

  return words.join(" ");
};

export const getTailwindColor = (colorValue, number = 600) => {
  let color = colorValue || "slate";
  if (color && Color["tailwind"][color] && !color?.includes("#")) {
    color = Color["tailwind"][color][number];
  } else if (color && color?.includes("#")) {
    // color = alpha(color, number / 1000);
  }

  return color;
};

export const isMonthDate = (dateFrom, dateTo) => {
  let date1 = new Date(dateFrom);
  let date2 = new Date(dateTo);
  let month1 = date1.getMonth();
  let month2 = date2.getMonth();
  let year1 = date1.getYear();
  let year2 = date2.getYear();
  if (month1 === month2) {
    return false;
  }
  if (month1 === month2 + 1 && year1 === year2) {
    return true;
  }
  if (month1 === 0 && month2 === 11 && year1 === year2 + 1) {
    return true;
  }
  return false;
};

export const getShare = (Total, amount) => {
  let share = (amount / Total) * 100;
  return share?.toString() === "Infinity"
    ? 100
    : isNaN(share)
    ? 0
    : share?.toFixed(2) ?? 0;
};

export const getPercentageChange = (oldNumber, newNumber, type = "Income") => {
  var decreaseValue = parseFloat(newNumber) - parseFloat(oldNumber);
  let divide = 1;
  if (type === "Income") {
    divide =
      oldNumber === 0
        ? parseFloat(newNumber) < 0
          ? -1
          : 1
        : parseFloat(oldNumber);
  } else {
    // divide =
    //   decreaseValue >= 0
    //     ? oldNumber === 0.0
    //       ? parseFloat(newNumber) < 0
    //         ? -1
    //         : 1
    //       : parseFloat(oldNumber)
    //     : newNumber === 0.0
    //     ? parseFloat(oldNumber) < 0
    //       ? -1
    //       : 1
    //     : parseFloat(newNumber);
    divide =
      newNumber === 0.0
        ? parseFloat(oldNumber) < 0
          ? -1
          : 1
        : parseFloat(newNumber);
  }

  return ((decreaseValue / divide) * 100.0).toFixed(1);
};

export const randomColor = () => {
  return (
    "#" +
    Math.floor(Math.random() * 16777215)
      .toString(16)
      .padStart(6, "0")
      .toUpperCase()
  );
};

export const truncate = (source, size = 12) => {
  return source?.length > size
    ? source?.slice(0, size - 1)?.trim("") + ".."
    : source;
};

export const updateLanguage = (lan = null) => {
  let locale = Intl.DateTimeFormat().resolvedOptions().locale;
  if (lan) {
    locale = lan;
  }
  // let lang = locale.split("_")[0];
  // i18n.changeLanguage(lang === "de" ? lang : "en");
};

export const updateURLParams = (url, params) => {
  // Parse the URL
  var parser = document.createElement("a");
  parser.href = url;

  // Extract the existing query parameters
  var query = parser.search.substr(1);
  var queryParams = new URLSearchParams(query);

  // Update the query parameters with the new values
  for (var key in params) {
    if (params.hasOwnProperty(key)) {
      queryParams.set(key, params[key]);
    }
  }

  // Rebuild the URL with the updated query parameters
  parser.search = "?" + queryParams.toString();

  // Return the modified URL
  return parser.href;
};

export const Highlighted = ({
  text = "",
  highlight = "",
  doNotHighlite = "",
}) => {
  if (highlight) {
    const regex = new RegExp(`(${highlight})`, "gi");
    const parts = text.split(regex);

    return (
      <span>
        {parts.filter(String).map((part, i) => {
          return regex.test(part) ? (
            <b key={i}>{part}</b>
          ) : (
            <span key={i}>{part}</span>
          );
        })}
      </span>
    );
  }
  if (doNotHighlite) {
    const regex = new RegExp(`(${doNotHighlite})`, "gi");
    const parts = text.split(regex);

    return (
      <span>
        {parts.filter(String).map((part, i) => {
          return regex.test(part) ? (
            <span key={i}>{part}</span>
          ) : (
            <b key={i}>{part}</b>
          );
        })}
      </span>
    );
  }
};

export function removeAllCookies() {
  var cookies = document.cookie.split("; ");
  for (var c = 0; c < cookies.length; c++) {
    var d = window.location.hostname.split(".");
    while (d.length > 0) {
      var cookieBase =
        encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
        "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
        d.join(".") +
        " ;path=";
      var p = window.location.pathname.split("/");
      document.cookie = cookieBase + "/";
      while (p.length > 0) {
        document.cookie = cookieBase + p.join("/");
        p.pop();
      }
      d.shift();
    }
  }
}

export const remToPx = (currentWidth, number) => {
  let px = 16;
  if (currentWidth === "s1280" || currentWidth === "lg") {
    px = 10;
  }
  if (currentWidth === "s1366") {
    px = 11;
  }
  if (currentWidth === "s1440") {
    px = 11;
  }
  if (currentWidth === "s1536" || currentWidth === "mid") {
    px = 12;
  }
  if (currentWidth === "s1745") {
    px = 13;
  }
  if (currentWidth === "xl" || currentWidth === "s100") {
    px = 13;
  }
  if (
    currentWidth === "s90" ||
    currentWidth === "s2133" ||
    currentWidth === "dxl"
  ) {
    px = 14;
  }
  if (currentWidth === "s80" || currentWidth === "s2400") {
    px = 15;
  }
  return number * px;
};

export const isFireFox = () => {
  return typeof InstallTrigger !== "undefined";
};

export const isSafari = () => {
  return (
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === "[object SafariRemoteNotification]";
    })(
      !window["safari"] ||
        (typeof safari !== "undefined" && window["safari"].pushNotification)
    )
  );
};

export const isOpera = () => {
  return (
    (!!window.opr && !!window.opr.addons) ||
    !!window.opera ||
    navigator.userAgent.indexOf(" OPR/") >= 0
  );
};

export const isEdge = () => {
  var isIE = /*@cc_on!@*/ false || !!document.documentMode;

  // Edge 20+
  var isEdge = !isIE && !!window.StyleMedia;
  return isEdge;
};

export const yieldToMain = () => {
  return new Promise((resolve) => {
    setTimeout(resolve, 0);
  });
};

//api................................................................................................
const pageBatchNumber = 200;

export const buildTruDataApi = ({ eventType, options }) => {
  const params = {
    user: process.env.REACT_APP_TRUE_DATA_USERNAME,
    password: process.env.REACT_APP_TRUE_DATA_PASSWORD,
    // port: process.env.REACT_APP_TRUE_DATA_PORT,
    csv: true,
    ...options,
  };
  return buildUrlFromParams({
    default_url: `${process.env.REACT_APP_TRUE_DATA_URL}/${eventType}`,
    params,
  });
};

export const buildUrlFromParams = ({ default_url, params }) => {
  const queryParams = Object.entries(params)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");
  if (default_url) {
    const url = `${default_url}?${queryParams}`;
    return url;
  } else {
    const url = `/?${queryParams}`;
    return url;
  }
};

export const getTransactionByParams = async (params) => {
  let url = `?missing_date=false`;
  if (params?.dataset) {
    url = url + `&dataset=${params?.dataset}`;
  }

  if (params?.page) {
    url = url + `&page=${params?.page}`;
  }
  if (params?.page_size) {
    url = url + `&page_size=${params?.page_size}`;
  }
  if (params?.url) {
    url = url + params?.url;
  }

  let data = null;
  await APICall(
    "get",
    params?.endpoint
      ? EndPoints[params?.endpoint] + url
      : EndPoints.profile + url,
    null,
    params?.config
  ).then((response) => {
    if (response.status === 200 && response.data) {
      data = response.data;
    }
  });
  return data;
};

export const getAllTransactionsByParams = async (params) => {
  let data = [];
  let pageSize = pageBatchNumber;
  let count = 0;
  let page = 1;
  // const LIMIT = pLimit(5);

  const getURL = () => {
    let url = `?missing_date=false`;
    if (params?.dataset) {
      url = url + `&dataset=${params?.dataset}`;
    }

    if (params?.page) {
      page = params?.page;
      url = url + `&page=${params?.page}`;
    } else {
      url = url + `&page=${page}`;
    }
    if (params?.page_size) {
      pageSize = params?.page_size;
      url = url + `&page_size=${params?.page_size}`;
    } else {
      pageSize = pageBatchNumber;
      url = url + `&page_size=${pageBatchNumber}`;
    }
    if (params?.url) {
      url = url + params?.url;
    }
    return url;
  };
  await APICall(
    "get",
    params?.endpoint
      ? EndPoints[params?.endpoint] + getURL()
      : EndPoints.profile + getURL(),
    null,
    params?.config
  ).then((response) => {
    if (response.status === 200 && response.data) {
      count = response.data.count;
      data = response.data.results;
    }
  });
  if (count > data?.length) {
    let number = Math.ceil(count / pageSize) - 1;
    let promises = Array.from(new Array(number)).map(() => {
      page++;

      let url = getURL();
      // return LIMIT(() =>
      //   APICall(
      //     "get",
      //     params?.endpoint
      //       ? EndPoints[params?.endpoint] + url
      //       : EndPoints.profile + url,
      //     null,
      //     params?.config
      //   )
      // );
    });
    try {
      const responses = await Promise.allSettled(promises);
      for (const response of responses) {
        if (response?.status === "fulfilled") {
          let results = response?.value?.data?.results;
          data = [...data, ...results];
        }
      }
    } catch (err) {
      console.log(`error:allSettled `, err);
    }
  }

  return data;
};
