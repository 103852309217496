import { Col, Row } from "antd";
import React from "react";
import DashboardCommonTable from "../../components/DashboardCommonTable";
import { useSelector } from "react-redux";
import "./style.css";
import LetestTradeTable from "./LetestTradeTable";
import PendingTradeTable from "./PendingTradeTable";
import RejectionLogsTable from "./RejectionLogsTable";
import M2MAlertTable from "./M2MAlertTable";
import AutoSqureTable from "./AutoSqureTable";

function DashboardScreen() {
  // redux
  const accountType = useSelector(
    (state) => state.authSlice?.userDetail?.accountType
  );
  const isCustomer = accountType === "customer";
  const isBroker = accountType === "broker";

  return (
    <div>
      <Row gutter={[10, 10]}>
        <LetestTradeTable />
        <PendingTradeTable />
      </Row>
      <Row>
        <RejectionLogsTable />
      </Row>

      {!isBroker && !isCustomer && (
        <>
          <Row gutter={[10, 10]}>
            <M2MAlertTable />
            <AutoSqureTable />
          </Row>
          <Row>
            <AlertTable />
          </Row>
        </>
      )}
    </div>
  );
}

export default DashboardScreen;

const AlertTable = () => {
  const m2mSummaryColumns = [
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      align: "center",
      width: "5rem",
    },
    {
      title: "CLIENT",
      dataIndex: "client",
      key: "client",
      align: "center",
      width: "10rem",
    },
    {
      title: "MESSAGE",
      dataIndex: "message",
      key: "message",
      align: "center",
      width: "20rem",
    },
  ];

  return (
    <Col span={24}>
      <DashboardCommonTable
        columns={m2mSummaryColumns}
        tableTitle="ALERT"
        viewMore={true}
        // allowBtn={true}
      />
    </Col>
  );
};
