import React, { useEffect, useState } from "react";
import ThemeCard from "../../components/ThemeCard";
import { Col, Form, Radio, Row, notification } from "antd";
import ThemeInput from "../../components/ThemeInput";
import ThemeDatePicker from "../../components/ThemeDatePicker";
import ThemeDropDown from "../../components/ThemeDropDown";
import { useSelector } from "react-redux";
import { allOption } from "../../Helper/constant";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import { useQuery } from "@tanstack/react-query";
import { ThemeForm, SubmitButton } from "./style";
import { useWatch } from "antd/es/form/Form";
import ThemeInputNumber from "../../components/ThemeInputNumber/ThemeInputNumber";

const initalState = {
  accountType: "",
  accountId: "",
  entryType: "deposit",
  type: "cash",
  date: "",
  point: "",
  remark: "",
};
function AccountPointEntry() {
  // state
  const [value, setValue] = useState(initalState);
  const [childOption, setChildOption] = useState({
    all: [],
    masterAdmin: [],
    superAdmin: [],
    admin: [],
    master: [],
    dealer: [],
    customer: [],
    broker: [],
  });
  const [submitLoading, setSubmitLoading] = useState(false);
  const [form] = Form.useForm();
  //redux
  const userDetail = useSelector((state) => state.authSlice.userDetail);
  const formAccountType = useWatch("accountType", form);

  //api call
  const handleSubmit = async (payload) => {
    let obj = {
      ...payload,
      accountType: payload?.accountType?.[0],
      accountId: payload?.accountId?.[0],
      remark: payload?.remark || null,
      type: "cash",
    };
    setSubmitLoading(true);
    try {
      let response = await APICall("post", EndPoints.createLedger, obj);
      if (response.status === 200) {
        notification.success({ message: response.data?.message });
        form.resetFields();
        setValue(initalState);
      } else {
        notification.error({ message: response.data?.message });
      }
    } catch (error) {
      notification.error({ message: error?.message });
    } finally {
      setSubmitLoading(false);
    }
  };
  const getChildUser = async () => {
    let response = await APICall(
      "get",
      `${EndPoints.getChildUsers}${userDetail?.userId}&type=onlychild`
    );
    return response?.data?.data?.childUsers;
  };

  const {
    isLoading,
    error: childUserError,
    data: childData,
  } = useQuery({ queryKey: ["getChildUser"], queryFn: getChildUser });

  if (childUserError) {
    notification.error({ message: childUserError?.message });
  }

  // life cycle
  useEffect(() => {
    let obj = {
      all: [],
      masterAdmin: [],
      superAdmin: [],
      admin: [],
      master: [],
      dealer: [],
      customer: [],
      broker: [],
    };
    // console.log("child", childData);
    if (childData) {
      childData.map((el) => {
        obj[el?.accountType].push({
          label: `${el?.accountName} (${el?.userId})`,
          value: el?.userId,
        });
        obj.all.push({
          label: `${el?.accountName} (${el?.userId})`,
          value: el?.userId,
        });
      });
      setChildOption(obj);
      // console.log("obj", obj);
    }
  }, [childData]);

  // function
  const handleChange = (name, value, option) => {
    setValue((pre) => ({ ...pre, [name]: value?.[0] ? value[0] : null }));
  };

  const onChangeRadio = (e) => {
    const { value, name } = e.target;
    setValue((pre) => ({ ...pre, entryType: value }));
  };

  const handleDateChange = (date) => {
    setValue((pre) => ({ ...pre, date: date }));
  };

  const onChangeInput = ({ target: { name, value } }) => {
    setValue((pre) => ({ ...pre, [name]: value }));
  };

  const handleCancel = () => {
    // setValue(initalState);
    form.resetFields();
  };

  const handleFaild = (error) => {
    // console.log("faild", error);
    notification.error({ message: error?.errorFields?.[0]?.errors?.[0] });
  };

  const handleFinish = (payload) => {
    // console.log("finish", payload);
    handleSubmit(payload);
  };

  return (
    <>
      <ThemeCard title={"Account Point Entry"}>
        <ThemeForm
          name="basic"
          form={form}
          onFinishFailed={handleFaild}
          onFinish={handleFinish}
          autoComplete="off"
          labelCol={{ xs: 24, sm: 8, md: 10, lg: 10, xl: 10 }}
          wrapperCol={{ xs: 24, sm: 12, md: 10, lg: 8, xl: 6 }}
          style={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}
        >
          <ThemeDropDown
            name={"accountType"}
            placeholder="Select"
            // $value={value.accountType}
            // $onChange={handleChange}
            onChange={form.setFieldValue("accountId", null)}
            showSearch={true}
            options={allOption?.filter(
              (item) => item.authorityLevel > userDetail.authorityLevel
            )}
            disabled={isLoading}
            form_props={{
              name: "accountType",
              label: "Account Type",
              rules: [{ required: true }],
            }}
          />

          <ThemeDropDown
            name={"accountId"}
            placeholder="Select"
            // $value={value.accountId}
            // value={value.accountId}
            // $onChange={handleChange}
            showSearch={true}
            options={childOption[formAccountType?.[0]]}
            required
            form_props={{
              name: "accountId",
              label: "Account Id",
              rules: [{ required: true }],
            }}
          />

          <Form.Item
            label="Type"
            name="entryType"
            initialValue={"deposit"}
            rules={[{ required: true }]}
          >
            <Radio.Group
              // value={value?.entryType}
              // onChange={onChangeRadio}
              name="entryType"
              style={{ marginLeft: "1rem" }}
            >
              <Radio value={"deposit"}>deposit</Radio>
              <Radio value={"withdrow"}>Withdraw</Radio>
            </Radio.Group>
          </Form.Item>

          <ThemeDatePicker
            name="date"
            width={"100%"}
            // value={value.date}
            // onChange={handleDateChange}
            formProps={{
              name: "date",
              label: "Date",
              rules: [{ required: true }],
            }}
          />
          <ThemeInputNumber
            marginBottom={"0rem"}
            inputProps={{
              name: "point",
              step: 1,
              // value: value.point,
              // onChange: onChangeInput,
            }}
            // errorheight="1rem"
            formProps={{
              name: "point",
              label: "Point",
              rules: [{ required: true }],
            }}
          />
          <ThemeInput
            // marginBottom={"0rem"}
            inputProps={{
              name: "remark",
              // value: value.remark,
              // onChange: onChangeInput,
            }}
            formProps={{
              name: "remark",
              label: "Remark",
            }}
          />

          <Row
            justify={"center"}
            style={{ marginTop: "2rem" }}
            gutter={[10, 20]}
          >
            <Col xs={24} sm={8} md={4} lg={4} xl={3}>
              <SubmitButton
                // onClick={handleSubmit}
                // style={style.submitBtn}
                loading={submitLoading}
                type="primary"
                htmlType="submit"
              >
                Submit
              </SubmitButton>
            </Col>
            <Col xs={24} sm={8} md={4} lg={4} xl={3}>
              <SubmitButton remove={"true"} onClick={handleCancel}>
                Cancel
              </SubmitButton>
            </Col>
          </Row>
        </ThemeForm>
      </ThemeCard>
    </>
  );
}

export default AccountPointEntry;
