import { Divider } from "antd";
import styled from "styled-components";

export const StyleSpan = styled.div`
  width: inherit;
  @media (max-width: 720px) {
    display: flex;
  }
`;
export const StyleDiv = styled.div`
  width: auto;
  white-space: nowrap;
  text-align: left;
`;
export const MainComponant = styled.div`
  font-weight: 600;
  display: flex;
  gap: 0.5rem;
  /* border: 1px solid red; */
  /* flex-direction: column; */
  font-size: 1.1rem;

  @media (max-width: 1079px) {
    font-size: 1rem;
  }
  @media (max-width: 500px) {
    font-size: 0.9rem;
  }
  @media (max-width: 720px) {
    flex-direction: column;
    gap: 0rem;
    font-size: 0.6rem;
  }
`;
export const LiveDataDiv = styled.div`
  text-align: left;
  white-space: nowrap;
  margin-left: 8px;
  @media (max-width: 500px) {
    /* width: 3.5rem; */
  }
`;
export const ThemeSpan = styled.span`
  width: 5rem;
  text-align: left;
  @media (max-width: 1000px) {
    width: 4rem;
  }
  @media (max-width: 720px) {
    width: 3rem;
  }
`;
export const WrapDiv = styled.div`
  display: flex;
`;
export const ImageDiv = styled.div`
  margin-right: 5px;

  @media (max-width: 720px) {
    margin-left: 5px;
  }
`;
export const ScriptDiv = styled.div`
  align-content: center;
  display: flex;
  justify-content: center;
  width: 12rem;
  @media (max-width: 720px) {
    width: 15rem;
    justify-content: start;
  }
  /* border: 1px solid red; */
`;
export const StyleDivider = styled(Divider)`
  border-color: white;
  height: inherit;
`;
