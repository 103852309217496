import React, { useState } from "react";
import { Form, Input, Button, Row, Col, notification } from "antd";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import { useDispatch } from "react-redux";
import { setAuth } from "../../store/slice/authSlice";
import { useNavigate } from "react-router-dom";
import { clearLocalStorage } from "../../store/store";

const ResetPassword = () => {
  const [form] = Form.useForm();

  // state
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // api call
  const onFinish = async (values) => {
    let obj = { ...values, userId: null };
    setLoading(true);
    try {
      let response = await APICall("post", EndPoints.resetPassward, obj);
      if (response?.status === 200 && response?.data) {
        notification.success({ message: response?.data?.message });
        dispatch(setAuth(null));
        localStorage.removeItem("token");
        clearLocalStorage();
        setTimeout(() => {
          localStorage.removeItem("persist:root");
        }, 1000);
        navigate("/login");
      } else {
        notification.error({ message: response?.data?.message });
      }
    } catch (error) {
      notification.error({ message: error?.message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row justify="center" align="middle" style={{ minHeight: "70vh" }}>
      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
        <Form
          form={form}
          onFinish={onFinish}
          labelCol={{ xl: 8, lg: 8, md: 24, sm: 24, xs: 24 }}
          wrapperCol={{ xl: 16, lg: 16, md: 24, sm: 24, xs: 24 }}
        >
          <Form.Item
            label="Current Password"
            name="currentPassword"
            rules={[
              { required: true, message: "Please input your current passward" },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="New Password"
            name="newPassword"
            rules={[
              { required: true, message: "Please input your new password!" },
              {
                min: 4,
                message: "Password must be at least 4 characters long!",
              },
            ]}
            style={{ margin: "1rem 0rem" }}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            dependencies={["newPassword"]}
            rules={[
              { required: true, message: "Please confirm your password!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("The two passwords do not match!");
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          {/* labelCol={{ xl: 8, lg: 8, md: 24, sm: 24, xs: 24 }}
          wrapperCol={{ xl: 16, lg: 16, md: 24, sm: 24, xs: 24 }} */}
          <Form.Item
            wrapperCol={{
              xl: { offset: 8, span: 16 },
              lg: { offset: 8, span: 16 },
              md: { offset: 0, span: 24 },
              sm: { offset: 0, span: 24 },
              xs: { offset: 0, span: 24 },
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
            }}
          >
            <Button type="primary" htmlType="submit" loading={loading}>
              Reset Password
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default ResetPassword;
