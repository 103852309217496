import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Col, Row, notification } from "antd";
import ThemeDropDown from "../ThemeDropDown";
import {
  OptionMarket,
  ThemeDropDowns,
  UserFilterCompareObj,
} from "../../Helper/constant";
import ValanComponant from "../ValanComponant/ValanComponant";
import {
  setFilterUsers,
  setPositionFromSummay,
  setUserClearFilter,
} from "../../store/slice/globleSlice";
import useGetQueryParams from "../../hooks/useGetQueryParams";

const UserFilterComponant = React.memo(
  ({ valueRef, valan, userType, role, deducted, multipleValueRef }) => {
    // state
    const [userOptions, setUserOptions] = useState({
      masterAdmin: [],
      superAdmin: [],
      admin: [],
      master: [],
      dealer: [],
      broker: [],
      customer: [],
    });
    const [optionValue, setOptionValue] = useState({
      customer: null,
      broker: null,
      dealer: null,
      master: null,
      admin: null,
      superAdmin: null,
      masterAdmin: null,
    });
    const [childLoading, setChildLoading] = useState(false);
    const { params } = useGetQueryParams();
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    // redux
    const authorityLevel = useSelector(
      (state) => state.authSlice.userDetail.authorityLevel
    );
    const clearUserList = useSelector(
      (state) => state.globleSlice?.userClearFilter
    );
    const summaryPosition = useSelector(
      (state) => state?.globleSlice?.positionFromSummay
    );
    const loginUserId = useSelector(
      (state) => state.authSlice?.userDetail?.userId
    );
    const filterUser = useSelector((state) => state?.globleSlice?.filterUsers);

    // api
    const getUsers = async () => {
      let url = role
        ? `${EndPoints.getAllUser}?role=${role}`
        : EndPoints.getAllUser;

      let res = await APICall("get", url);
      return res.data.data.userData;
    };

    const {
      isLoading: userLoading,
      data: userData,
      error: usersfilterError,
    } = useQuery({
      queryKey: ["usersFilter", role],
      queryFn: getUsers,
      enabled: true,
    });

    if (usersfilterError) {
      notification.error({ message: usersfilterError?.message });
    }

    // life cycle
    // useEffect(() => {
    //   queryClient.refetchQueries(["usersDetails", role]);
    // }, []);

    useEffect(() => {
      if (summaryPosition?.userId && userData) {
        let setUser = userData?.find(
          (el) => el.userId == summaryPosition?.userId
        );
        if (setUser) {
          setOptionValue((pre) => ({
            ...pre,
            [setUser?.accountType]: setUser?.userId,
          }));
          valueRef.current = {
            ...valueRef.current,
            userId: setUser?.userId,
          };
        }
        dispatch(setPositionFromSummay({}));
      }
    }, [summaryPosition, userData]);

    useEffect(() => {
      if (params.userId && userData) {
        let setUser = userData?.find((el) => el.userId == params?.userId);
        if (setUser) {
          setOptionValue((pre) => ({
            ...pre,
            [setUser?.accountType]: setUser?.userId,
          }));
          valueRef.current = {
            ...valueRef.current,
            userId: setUser?.userId,
          };
        }
        if (multipleValueRef?.current && params?.brokerId) {
          let brokerSet = userData?.find((el) => el.userId == params?.brokerId);
          if (brokerSet) {
            setOptionValue((pre) => ({
              ...pre,
              [brokerSet?.accountType]: brokerSet?.userId,
            }));
          }
        }
      }
      const filterUsersId = userData?.map((el) => el?.userId);
      if (filterUsersId?.length > 0) {
        dispatch(setFilterUsers([...filterUsersId, loginUserId]));
      } else {
        if (filterUser?.length === 0) {
          dispatch(setFilterUsers([loginUserId]));
        }
      }

      if (userData) {
        const accountTypeOptions = userData.reduce(
          (acc, el) => {
            if (acc[el?.accountType]) {
              acc[el.accountType].push({
                label: `${el?.accountName} (${el?.userId})`,
                value: el?.userId,
                id: el?._id,
              });
            }
            return acc;
          },
          {
            masterAdmin: [],
            superAdmin: [],
            admin: [],
            master: [],
            dealer: [],
            broker: [],
            customer: [],
          }
        );

        setUserOptions(accountTypeOptions);
      }
    }, [userData]);

    useEffect(() => {
      if (clearUserList) {
        setOptionValue({
          customer: null,
          broker: null,
          dealer: null,
          master: null,
          admin: null,
          superAdmin: null,
          masterAdmin: null,
        });
        valueRef.current = {
          ...valueRef.current,
          userId: null,
        };
        dispatch(setUserClearFilter(false));
        // queryClient.resetQueries(["usersDetails"]);
      }
    }, [clearUserList]);

    useEffect(() => {
      if (multipleValueRef?.current) {
        multipleValueRef.current = optionValue;
      }
    }, [optionValue]);

    // function
    const filteredDropDowns = useMemo(() => {
      return ThemeDropDowns.filter(
        (item) => item.authorityLevel > authorityLevel
      );
    }, [authorityLevel]);

    const getChildTrades = async (id, selectedId) => {
      setChildLoading(true);
      try {
        let response = await APICall("get", `${EndPoints.getChildUsers}${id}`);
        if (response.status == 200) {
          // console.log("childResponse", response?.data);
          valueRef.current = { ...valueRef.current, userId: id };
          const { userDetail: selectedUser, childUsers: child } =
            response?.data?.data || {};

          const obj = child?.reduce((acc, child) => {
            const childInfo = {
              label: `${child.accountName} (${child.userId})`,
              value: child.userId,
              id: child._id,
            };
            acc[child.accountType] = [
              ...(acc[child.accountType] || []),
              childInfo,
            ];
            return acc;
          }, {});

          // console.log("child", obj);

          let newOption = { ...userOptions };
          let flage = false;
          for (let key in newOption) {
            if (flage) {
              if (obj[key]) {
                newOption[key] = [...obj[key]];
              } else {
                obj[key] = [];
                newOption[key] = [...obj[key]];
              }
            }
            if (key === selectedUser.accountType) {
              flage = true;
            }
          }
          // console.log("userOptions", userOptions);
          // console.log("newOption", newOption);
          setUserOptions(newOption);

          let newValue = { ...optionValue };

          for (let key in newValue) {
            if (key === selectedUser.accountType) {
              newValue[key] = id;
              break;
            }
            newValue[key] = null;
          }
          setOptionValue({ ...newValue });
        } else {
          notification.error({ message: response?.data?.message });
        }
      } catch (error) {
        notification.error({ message: error?.message });
      } finally {
        setChildLoading(false);
      }
    };

    const onChangeDropDown = (name, selectedValue, selectedOptions) => {
      if (selectedValue === null) {
        setOptionValue((pre) => {
          let obj = {
            customer: null,
            broker: null,
            dealer: null,
            master: null,
            admin: null,
            superAdmin: null,
            masterAdmin: null,
          };

          for (let key in pre) {
            if (UserFilterCompareObj[name] === key) {
              obj[key] = null;
            } else {
              obj[key] = pre[key];
            }
          }
          let flage = true;
          for (let key in obj) {
            if (obj[key]) {
              valueRef.current = { ...valueRef.current, userId: obj[key] };
              getChildTrades(obj[key]);
              flage = false;
              break;
            }
          }
          if (flage) {
            valueRef.current = { ...valueRef.current, userId: loginUserId };
            getChildTrades(loginUserId);
          }

          // console.log("filterObj", obj);
          return obj;
        });
      }
      const selectedId = selectedOptions?.[0].id;
      if (selectedValue?.[0]) {
        // console.log("filterObj", selectedValue);
        getChildTrades(selectedValue?.[0], selectedId);
      }
    };

    return (
      <>
        {valan && <ValanComponant valueRef={valueRef} />}
        {!userType &&
          filteredDropDowns.map((dropDown, index) => {
            if (dropDown?.title !== deducted) {
              return (
                <Col xs={24} sm={12} md={8} lg={6} xl={4} key={index}>
                  <ThemeDropDown
                    title={dropDown.title}
                    placeholder={dropDown.placeholder}
                    name={dropDown.title}
                    options={userOptions[OptionMarket[dropDown?.title]]}
                    width={dropDown.width}
                    showSearch={true}
                    $onChange={onChangeDropDown}
                    $value={optionValue[OptionMarket[dropDown?.title]]}
                    disabled={childLoading}
                    loadings={childLoading || userLoading ? "true" : "false"}
                    // suffixIcon={
                    //   <Spin size="small" spinning={childLoading || userLoading} />
                    // }
                  />
                </Col>
              );
            }
          })}

        {userType &&
          ThemeDropDowns?.filter((el) => userType?.includes(el?.title))?.map(
            (dropDown, index) => (
              <Col xs={24} sm={12} md={8} lg={6} xl={4} key={index}>
                <ThemeDropDown
                  title={dropDown.title}
                  placeholder={dropDown.placeholder}
                  name={dropDown.title}
                  options={userOptions[OptionMarket[dropDown?.title]]}
                  width={dropDown.width}
                  showSearch={true}
                  $onChange={onChangeDropDown}
                  $value={optionValue[OptionMarket[dropDown?.title]]}
                  disabled={childLoading}
                  loadings={childLoading || userLoading ? "true" : "false"}
                  // suffixIcon={
                  //   <Spin size="small" spinning={childLoading || userLoading} />
                  // }
                />
              </Col>
            )
          )}
      </>
    );
  }

  // (prevProps, nextProps) => {
  //   return (
  //     prevProps.role === nextProps.role &&
  //     prevProps.userType === nextProps.userType &&
  //     false
  //   );
  // }
);

export default UserFilterComponant;
